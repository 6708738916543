/* eslint-disable import/prefer-default-export */
export const DAYS_OF_WEEK = [
  {
    id: 0,
    value: 'sunday'
  },
  {
    id: 1,
    value: 'monday'
  },
  {
    id: 2,
    value: 'tuesday'
  },
  {
    id: 3,
    value: 'wednesday'
  },
  {
    id: 4,
    value: 'thursday'
  },
  {
    id: 5,
    value: 'friday'
  },
  {
    id: 6,
    value: 'saturday'
  }
];

export const FB_MACRO_CATEGORIES = {
  FOOD: 'food',
  DRINKS: 'drink'
};

export const ORDER_TYPES = {
  TAKE_AWAY: 'TAKE_AWAY',
  DELIVERY: 'DELIVERY',
  SERVICE: 'SERVICE'
};

export const SECTION_TYPES = {
  RESERVATIONS: 'reservations',
  COMPLAINTS: 'complaints',
  TICKETS: 'tickets'
};

export const PRIORITY = [
  {
    name: 'low',
    value: 'low'
  },
  {
    name: 'medium',
    value: 'medium'
  },
  {
    name: 'high',
    value: 'high'
  },
  {
    name: 'very-high',
    value: 'very_high'
  }
];

export const STATUS = [
  {
    name: 'open',
    value: 'OPEN'
  },
  {
    name: 'working',
    value: 'WORKING'
  },
  {
    name: 'closed',
    value: 'CLOSED'
  }
];
