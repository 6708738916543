import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Button from './Button';
import { translate } from '../../translator';
import { LanguageContext } from '../../contexts/LanguageContext';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: ${({ theme: { colors } }) => `1px solid ${colors.borderColor}`};
  border-bottom: ${({ theme: { colors } }) => `1px solid ${colors.borderColor}`};

  .ui.button {
    margin: 0px;
    width: 100%;
    text-transform: capitalize !important;
    border-left: ${({ theme: { colors } }) => `1px solid ${colors.borderColor}`};
    border-right: ${({ theme: { colors } }) => `1px solid ${colors.borderColor}`};
    border-radius: 0px;
  }

  .active-button {
    background-color: ${({ theme: { colors } }) => `${colors.tertiaryColor} !important`};
    color: white !important;
  }

  .not-active-button {
    background-color: white !important;
    color: ${({ theme: { colors } }) => `${colors.primaryColor} !important`};
  }
`;

const ButtonsSelector = ({ buttons, activeValues }) => {
  const { language } = useContext(LanguageContext);

  return (
    <Container>
      {buttons.map((button, index) => (
        <Button
          key={index}
          className={activeValues.includes(button.value) ? 'active-button' : 'not-active-button'}
          type="button"
          style={button.style}
          onClick={() => {
            if (activeValues.includes(button.value)) button.onClick(null);
            else button.onClick(button.value);
          }}
        >
          {translate(button.label, language)}
        </Button>
      ))}
    </Container>
  );
};

ButtonsSelector.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      style: PropTypes.shape({}),
      onClick: PropTypes.func.isRequired
    })
  ).isRequired,
  activeValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
};

ButtonsSelector.defaultProps = {
  activeValues: []
};

export default ButtonsSelector;
