import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import orderBy from 'lodash/orderBy';
import useCustomQuery from '../../hooks/useCustomQuery';
import { GET_GUEST_PHYSICAL_CATEGORIES } from '../../graphql/outlets/queries';
import { TranslateTextComponent } from '../../translator';
import { ReactComponent as AdultIcon } from '../../assets/images/physical-categories/adults.svg';
import { ReactComponent as KidIcon } from '../../assets/images/physical-categories/kids.svg';
import { ReactComponent as InfantIcon } from '../../assets/images/physical-categories/infants.svg';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }

  span {
    font-family: optima-medium;
    font-size: 16px;
  }
`;

const AgeRangeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CounterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  span {
    font-size: 20px;
    font-family: optima-medium;
  }
`;

const Button = styled.button`
  cursor: pointer;
  font-size: 2rem;
  background: none;
  border: none;
  color: ${({ theme: { colors } }) => colors.primaryColor};

  @media (min-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    font-size: 3rem;
  }
`;

const GuestsPhysicalCategoriesCounter = ({ onChange, guests }) => {
  // Queries
  const getGuestPhysicalCategories = useCustomQuery(GET_GUEST_PHYSICAL_CATEGORIES);

  const getPhysicalCategory = (categoryName) => {
    switch (categoryName) {
      case 'adults':
        return (
          <>
            <AdultIcon />
            <TranslateTextComponent capitalize>adults</TranslateTextComponent>
          </>
        );
      case 'kids':
        return (
          <>
            <KidIcon />
            <TranslateTextComponent capitalize>kids</TranslateTextComponent>
          </>
        );
      case 'enfants':
        return (
          <>
            <InfantIcon />
            <TranslateTextComponent capitalize>infants</TranslateTextComponent>
          </>
        );
      default:
        return (
          <>
            <AdultIcon />
            <TranslateTextComponent capitalize>adults</TranslateTextComponent>
          </>
        );
    }
  };

  const onCounterChange = ({ guest, add }) => {
    if (add) {
      onChange({ ...guests, [guest.name]: [...guests[guest.name], guest.id] });
    } else {
      if (guests[guest.name].length - 1 < 0) return;
      onChange({ ...guests, [guest.name]: [...guests[guest.name]].splice(1) });
    }
  };

  return (
    <Container>
      {orderBy(getGuestPhysicalCategories.data?.getGuestPhysicalCategories?.data, ['ageMin'], ['desc'])?.map((gpc) => (
        <div key={gpc.uuid} style={{ display: 'flex', alignItems: 'center', flexFlow: 'column' }}>
          <TitleContainer>{getPhysicalCategory(gpc.name)}</TitleContainer>
          <AgeRangeContainer>
            <span style={{ marginRight: 5 }}>{gpc.ageRange}</span>
            <TranslateTextComponent>ages</TranslateTextComponent>
          </AgeRangeContainer>
          <CounterContainer>
            <Button onClick={() => onCounterChange({ guest: gpc, add: false })}>-</Button>
            <span>{guests[gpc.name]?.length}</span>
            <Button onClick={() => onCounterChange({ guest: gpc, add: true })}>+</Button>
          </CounterContainer>
        </div>
      ))}
    </Container>
  );
};

GuestsPhysicalCategoriesCounter.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default GuestsPhysicalCategoriesCounter;
