import React from 'react';
import { useParams } from 'react-router-dom';
import useCustomQuery from '../hooks/useCustomQuery';
import { GET_SMART_OUTLET } from '../graphql/outlets/queries';
import OutletContent from '../components/outlets/OutletContent';
import Banner from '../components/shared/Banner';
import FixedContainer from '../components/shared/FixedContainer';
import Spinner from '../components/shared/Spinner';
import { getPrimaryImagePath } from '../utils/outlet';

const OutletContainer = () => {
  const { uuid } = useParams();

  const getSmartOutlet = useCustomQuery(GET_SMART_OUTLET, {
    variables: { outletUUID: uuid },
    fetchPolicy: 'network-only'
  });

  return (
    <div>
      {getSmartOutlet?.loading ? (
        <Spinner containerStyle={{ paddingTop: 100 }} />
      ) : (
        <>
          <FixedContainer>
            <Banner
              text={getSmartOutlet?.data?.getSmartOutlet?.name ?? ''}
              background={`${process.env.REACT_APP_SERVER_URL}${getPrimaryImagePath(getSmartOutlet?.data?.getSmartOutlet?.images)}`}
            />
          </FixedContainer>
          <OutletContent outlet={getSmartOutlet?.data?.getSmartOutlet} />
        </>
      )}
    </div>
  );
};

export default OutletContainer;
