import React, { useContext } from 'react';
import { LanguageContext, languageConfig } from '../../contexts/LanguageContext';
import BoldLabel from '../../components/shared/BoldLabel';
import Container from '../../components/shared/Container';
import RadioCard from '../../components/shared/RadioCard';

const LanguagesContainer = () => {
  const { setLanguage, language } = useContext(LanguageContext);
  return (
    <Container style={{ paddingTop: 20 }}>
      <BoldLabel label="choose-application-language" />
      {Object.keys(languageConfig).map((languageKey, index) => {
        return (
          <RadioCard
            key={index}
            title={languageConfig[languageKey].languageLabel}
            onClick={() => setLanguage(languageKey)}
            isSelected={languageConfig[languageKey].languageLabel === language}
          />
        );
      })}
    </Container>
  );
};

export default LanguagesContainer;
