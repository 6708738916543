import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Input } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { LanguageContext } from '../../contexts/LanguageContext';
import { AuthContext } from '../../contexts/AuthContext';
import { ModalContext } from '../../contexts/ModalContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import InputWithLabel from '../../components/shared/InputWithLabel';
import Container from '../../components/shared/Container';
import BoldLabel from '../../components/shared/BoldLabel';
import FooterButton from '../../components/shared/FooterButton';
import { capitalizeFirstLetter, translate, TranslateTextComponent } from '../../translator';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import { EDIT_PROFILE_INFO } from '../../graphql/auth/mutations';
import { ROUTE_SETTINGS_CHANGE_PASSWORD } from '../../router/routes';
import { theme } from '../../styles/theme';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const AccountDetailsContainer = () => {
  const history = useHistory();
  const { language } = useContext(LanguageContext);
  const authContext = useContext(AuthContext);
  const modalContext = useContext(ModalContext);
  const { showNotification } = useContext(NotificationContext);

  const [editProfileInfo] = useMutation(EDIT_PROFILE_INFO);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(capitalizeFirstLetter(translate('required', language))),
    lastName: Yup.string().required(capitalizeFirstLetter(translate('required', language))),
    phone: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required(capitalizeFirstLetter(translate('required', language)))
  });

  return (
    <Formik
      initialValues={{
        firstName: authContext?.user?.firstName ?? '',
        lastName: authContext?.user?.lastName ?? '',
        phone: authContext?.user?.phoneNumber ?? ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        editProfileInfo({
          variables: {
            editProfileData: values
          }
        })
          .then((res) => {
            const user = { ...JSON.parse(localStorage.getItem('user')) };
            user.firstName = res?.data?.editProfileInfo.firstName;
            user.lastName = res?.data?.editProfileInfo.lastName;
            user.phoneNumber = res?.data?.editProfileInfo.phone;

            localStorage.setItem('user', JSON.stringify(user));
            authContext.onSetUser(user);
            showNotification({ message: 'profile-edited-successfully' });
          })
          .catch((err) => {
            console.log('err', err);
            modalContext.openModal({
              title: 'error',
              text: getApolloErrors(err)?.[0]?.toLowerCase().replace(/\s/g, '-') ?? '',
              buttons: [{ label: 'close-modal', onClick: () => modalContext.closeModal() }]
            });
          });
        setSubmitting(false);
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, touched, isSubmitting }) => (
        <>
          <Container style={{ paddingTop: 20 }} onSubmit={handleSubmit}>
            <BoldLabel label="account-details" />
            <InputWithLabel
              label="first-name"
              error={touched.firstName && errors.firstName}
              labelStyle={{ fontWeight: 'bold', fontFamily: 'Optima', marginTop: 15 }}
            >
              <Input
                fluid
                name="firstName"
                value={values.firstName}
                error={touched.firstName && !!errors.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={capitalizeFirstLetter(translate('first-name', language))}
              />
            </InputWithLabel>

            <InputWithLabel
              label="last-name"
              error={touched.lastName && errors.lastName}
              labelStyle={{ fontWeight: 'bold', fontFamily: 'Optima', marginTop: 15 }}
            >
              <Input
                fluid
                name="lastName"
                value={values.lastName}
                error={touched.lastName && !!errors.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={capitalizeFirstLetter(translate('last-name', language))}
              />
            </InputWithLabel>

            <InputWithLabel
              label="phone-number"
              error={touched.phone && errors.phone}
              labelStyle={{ fontWeight: 'bold', fontFamily: 'Optima', marginTop: 15 }}
            >
              <Input
                fluid
                name="phone"
                value={values.phone}
                error={touched.phone && !!errors.phone}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={capitalizeFirstLetter(translate('phone-number', language))}
              />
            </InputWithLabel>

            <ChangePasswordButton onClick={() => history.push(ROUTE_SETTINGS_CHANGE_PASSWORD)} />
          </Container>
          <FooterButton text="save-changes" disabled={isSubmitting} onClick={handleSubmit} />
        </>
      )}
    </Formik>
  );
};

export default AccountDetailsContainer;

const ChangePasswordButton = ({ onClick }) => {
  return (
    <div onClick={onClick} style={{ alignSelf: 'flex-start', marginTop: 15 }}>
      <TranslateTextComponent
        style={{ fontFamily: 'optima-medium', fontSize: 12, textTransform: 'capitalize', borderBottom: `1px solid ${theme.colors.primaryColor}` }}
      >
        change-password
      </TranslateTextComponent>
    </div>
  );
};
