import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import {
  ROUTE_COMPLAINTS,
  ROUTE_HOME,
  ROUTE_OUTLET,
  ROUTE_OUTLETS,
  ROUTE_RESERVATIONS,
  ROUTE_RESERVATIONS_SUMMARY,
  ROUTE_RESERVATIONS_LIST,
  ROUTE_SERVICES_LIST,
  ROUTE_FB_OUTLET,
  ROUTE_BOOK_FB_RESERVATION,
  ROUTE_BOOK_SERVICES,
  ROUTE_BOOK_CHECKOUT_FB_RESERVATION,
  ROUTE_BOOK_CHECKOUT_SERVICES,
  ROUTE_LOGIN,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_SETTINGS_CHANGE_PASSWORD,
  ROUTE_SERVICE_DETAIL,
  ROUTE_TICKETS,
  ROUTE_TICKET,
  ROUTE_RESERVATION_DETAIL,
  ROUTE_CART_INFO,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_LANGUAGES,
  ROUTE_SETTINGS_ACCOUNT,
  ROUTE_SETTINGS_LEGAL,
  ROUTE_NEW_COMPLAINT
} from './routes';

import Layout from '../components/layout/Layout';
import ReservationsListContainer from '../containers/ReservationsListContainer';
import ReservationsContainer from '../containers/ReservationsContainer';
import HomeContainer from '../containers/HomeContainer';
import OutletsContainer from '../containers/OutletsContainer';
import ReservationsSummaryContainer from '../containers/ReservationsSummaryContainer';
import OutletContainer from '../containers/OutletContainer';
import ServicesListContainer from '../containers/ServicesListContainer';
import BookContainer from '../containers/BookContainer';
import BookCheckOutContainer from '../containers/BookCheckOutContainer';
import ComplaintsListContainer from '../containers/ComplaintsListContainer';
import TicketsListContainer from '../containers/TicketsListContainer';
import LoginContainer from '../containers/LoginContainer';
import ForgotPasswordContainer from '../containers/ForgotPasswordContainer';
import ComplaintContainer from '../containers/ComplaintContainer';
import TicketContainer from '../containers/TicketContainer';
import ServiceDetailContainer from '../containers/ServiceDetailContainer';
import ReservationDetailContainer from '../containers/ReservationDetailContainer';
import SettingsContainer from '../containers/SettingsContainer';
import LanguagesContainer from '../containers/settings/LanguagesContainer';
import AccountDetailsContainer from '../containers/settings/AccountDetailsContainer';
import LegalAreaContainer from '../containers/settings/LegalAreaContainer';
import ChangePasswordContainer from '../containers/settings/ChangePasswordContainer';
import CartInfoContainer from '../containers/CartInfoContainer';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useContext(AuthContext);

  if (isLoggedIn()) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  return <Redirect to={ROUTE_LOGIN} />;
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useContext(AuthContext);
  if (!isLoggedIn()) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  return <Redirect to={ROUTE_HOME} />;
};

export default () => {
  return (
    <Router>
      <Layout>
        <Switch>
          <PrivateRoute exact path={ROUTE_HOME} component={HomeContainer} />
          <PrivateRoute exact path={ROUTE_RESERVATIONS} component={ReservationsContainer} />
          <PrivateRoute exact path={ROUTE_RESERVATIONS_LIST} component={ReservationsListContainer} />
          <PrivateRoute exact path={ROUTE_RESERVATION_DETAIL} component={ReservationDetailContainer} />
          <PrivateRoute exact path={ROUTE_SERVICES_LIST} component={ServicesListContainer} />
          <PrivateRoute exact path={ROUTE_OUTLETS} component={OutletsContainer} />
          <PrivateRoute exact path={ROUTE_RESERVATIONS_SUMMARY} component={ReservationsSummaryContainer} />
          <PrivateRoute exact path={[ROUTE_FB_OUTLET, ROUTE_OUTLET]} component={OutletContainer} />
          <PrivateRoute exact path={[ROUTE_BOOK_FB_RESERVATION, ROUTE_BOOK_SERVICES]} component={BookContainer} />
          <PrivateRoute exact path={[ROUTE_BOOK_CHECKOUT_FB_RESERVATION, ROUTE_BOOK_CHECKOUT_SERVICES]} component={BookCheckOutContainer} />
          <PrivateRoute exact path={ROUTE_CART_INFO} component={CartInfoContainer} />
          <PrivateRoute exact path={ROUTE_COMPLAINTS} component={ComplaintsListContainer} />
          <PrivateRoute exact path={ROUTE_TICKETS} component={TicketsListContainer} />
          <PrivateRoute exact path={ROUTE_TICKET} component={TicketContainer} />
          <PrivateRoute exact path={ROUTE_SERVICE_DETAIL} component={ServiceDetailContainer} />
          <PrivateRoute exact path={ROUTE_OUTLETS} component={OutletsContainer} />
          <PrivateRoute exact path={ROUTE_OUTLET} component={OutletContainer} />
          <PrivateRoute exact path={ROUTE_SETTINGS} component={SettingsContainer} />
          <PrivateRoute exact path={ROUTE_SETTINGS_LANGUAGES} component={LanguagesContainer} />
          <PrivateRoute exact path={ROUTE_SETTINGS_ACCOUNT} component={AccountDetailsContainer} />
          <PrivateRoute exact path={ROUTE_SETTINGS_LEGAL} component={LegalAreaContainer} />
          <PrivateRoute exact path={ROUTE_SETTINGS_CHANGE_PASSWORD} component={ChangePasswordContainer} />
          <PrivateRoute exact path={ROUTE_NEW_COMPLAINT} component={ComplaintContainer} />
          <PublicRoute exact path={ROUTE_LOGIN} component={LoginContainer} />
          <PublicRoute exact path={ROUTE_FORGOT_PASSWORD} component={ForgotPasswordContainer} />
        </Switch>
      </Layout>
    </Router>
  );
};
