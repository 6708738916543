import React from "react";
import { TranslateTextComponent } from "../translator";

export const textTranslateCapitalize = (text, isHtml) => {
  return (
    <TranslateTextComponent capitalize isHtml={isHtml}>
      {text}
    </TranslateTextComponent>
  );
};

export const textTranslate = (text) => {
  return <TranslateTextComponent>{text}</TranslateTextComponent>;
};

export const textTranslateUppercase = (text) => {
  return <TranslateTextComponent uppercase>{text}</TranslateTextComponent>;
};
