import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

const Container = styled.div`
  font-family: 'Roboto';
  cursor: pointer;
  width: 31%;
  transform: translateY(0);
  transition: transform 1s ease;

  ${({ customBackground }) => {
    if (customBackground) {
      return css`
        background: ${() => `url(${customBackground})`};
        background-position: center;
        background-size: cover;
      `;
    }
    return css`
      background: white;
    `;
  }};

  &:hover {
    transform: translateY(-30px);
  }

  @media (max-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    width: 100%;
    height: 32%;
    &:hover {
      transform: unset;
    }
  }
`;

const SectionDetails = ({ routePath, backgroundImage, type }) => {
  const history = useHistory();

  return <Container id={type} onClick={() => history.push(routePath)} customBackground={backgroundImage} />;
};

export default SectionDetails;
