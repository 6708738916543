import React from 'react';
import styled from 'styled-components/macro';
import OutletCategories from '../components/reservations/OutletCategories';
import PreviousReservations from '../components/reservations/PreviousReservations';
import Container from '../components/shared/Container';
import WelcomeHeader from '../components/shared/WelcomeHeader';

const WelcomeHeaderContainer = styled.div`
  @media (min-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    display: none;
  }
`;
const ReservationsContainer = () => {
  return (
    <Container style={{ paddingTop: 20, paddingBottom: 20 }}>
      <WelcomeHeaderContainer className="welcome-header">
        <WelcomeHeader containerStyle={{ marginRight: 10 }} />
      </WelcomeHeaderContainer>
      <PreviousReservations />
      <OutletCategories />
    </Container>
  );
};

export default ReservationsContainer;
