import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import DrawerButton from './DrawerButton';
import { ReactComponent as BorgoEgnaziaLogo } from '../../../assets/images/borgo-egnazia-logo-with-text.svg';
import Cart from './Cart';
import WelcomeHeader from '../../shared/WelcomeHeader';
import { ROUTE_HOME } from '../../../router/routes';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  min-height: 80px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 2;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  .welcome-header {
    display: none !important;
  }
  @media (min-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    .welcome-header {
      display: block !important;
    }
  }
`;

const Header = () => {
  const history = useHistory();

  return (
    <Container>
      <DrawerButton />
      <BorgoEgnaziaLogo style={{ cursor: 'pointer' }} onClick={() => history.push(ROUTE_HOME)} />
      <RightContainer>
        <div className="welcome-header">
          <WelcomeHeader containerStyle={{ marginRight: 10 }} />
        </div>
        <Cart />
      </RightContainer>
    </Container>
  );
};

export default Header;
