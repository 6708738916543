import moment from 'moment';

export const getPrimaryImagePath = (images) => {
  const image = images?.find((img) => img.isPrimary);
  if (image) {
    return image && image.path;
  }
  return null;
};

export const getOutletStatus = (outlet) => {
  let status = 'close';
  const currentSegment = outlet.fbSegments?.find(
    (seg) =>
      moment(seg.startDate).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') &&
      moment(seg.endDate).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')
  );

  // eslint-disable-next-line no-unused-expressions
  currentSegment?.fbSegmentsServices?.forEach((segment) => {
    const startTime = moment(segment.fbService.startTime, 'HH:mm');
    const endTime = moment(segment.fbService.endTime, 'HH:mm');

    if (startTime.hour() > endTime.hour()) {
      endTime.add(1, 'day');
    }
    if (moment().isBetween(startTime, endTime)) {
      status = 'open';
    }
  });

  return status;
};

export const getAllDatesBetween2Days = (startDate, endDate) => {
  const start = moment(startDate).clone();
  const end = moment(endDate).clone();

  const now = start;
  const datesRange = [];

  while (now.isSameOrBefore(end)) {
    datesRange.push(now.format('YYYY-MM-DD'));
    now.add(1, 'days');
  }
  return datesRange;
};
