import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Container = styled.div`
  cursor: pointer;
  min-height: 84px;
  width: 100%;
  padding: 8px 18px;
  background-color: white;
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.16);

  & > div:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: optima;
`;

const Card = ({ data, containerStyle, onClick }) => {
  return (
    <Container style={containerStyle} onClick={onClick}>
      {data.map((d, index) => (
        <RowContainer key={index}>
          <span style={d.left?.style}>{d.left?.element}</span>
          <span style={d.right?.style}>{d.right?.element}</span>
        </RowContainer>
      ))}
    </Container>
  );
};

Card.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      left: PropTypes.shape({ element: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), style: PropTypes.object }),
      right: PropTypes.shape({ element: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), style: PropTypes.object })
    })
  ).isRequired
};

export default Card;
