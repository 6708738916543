import React, { useContext } from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import { LanguageContext, languages } from '../../contexts/LanguageContext';

const LanguageContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: flex-end;
  margin: 0px 20px;
`;

const LanguageDropdown = styled(Dropdown)`
  @media (min-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    font-size: 20px;
    min-width: 120px;

    .visible.menu.transition .item {
      font-size: 18px !important;
    }
  }
`;

const LanguagesBox = () => {
  const { setLanguage, language } = useContext(LanguageContext);

  const handleChangeLanguage = (e, data) => {
    setLanguage(data.value);
  };

  return (
    <LanguageContainer>
      <LanguageDropdown
        options={languages.map((l, key) => ({
          key,
          value: l.value,
          text: l.text
        }))}
        onChange={(e, data) => handleChangeLanguage(e, data)}
        value={language}
      />
    </LanguageContainer>
  );
};

export default LanguagesBox;
