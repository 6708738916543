import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Divider, Input, TextArea } from 'semantic-ui-react';
import { debounce } from 'lodash';
import styled from 'styled-components/macro';
import useCustomQuery from '../../hooks/useCustomQuery';
import { GET_GUEST_PROFILES } from '../../graphql/profiles/queries';
import { GET_ROOMS } from '../../graphql/roombuilding/queries';
import { LanguageContext } from '../../contexts/LanguageContext';
import { BookContext } from '../../contexts/BookContext';
import BoldLabel from '../shared/BoldLabel';
import InputWithLabel from '../shared/InputWithLabel';
import SearchBar from '../shared/SearchBar';
import Container from '../shared/Container';
import { capitalizeFirstLetter, translate, TranslateTextComponent } from '../../translator';
import { theme } from '../../styles/theme';

const InfoContainer = styled.div`
  padding: 9.5px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  margin-top: 10px;
  height: 38px;
`;

const BookFbReservationCheckOutContent = () => {
  const { booking, onSetProfile, onSetNewProfile, onSetBookingByKey } = useContext(BookContext);
  const { language } = useContext(LanguageContext);

  // const [guestProfilesQueryFilters, setGuestProfilesQueryFilters] = useState({ keyword: '', roomNumber: '' });
  const [roomNumber, setRoomNumber] = useState('');
  const [roomSearchValue, setRoomSearchValue] = useState('');

  const [loadingGuests, setLoadingGuets] = useState(false);
  const [guestKeyword, setGuestKeyword] = useState(null);

  const [guestProfiles, setGuestProfiles] = useState([]);
  const getGuestProfiles = useCustomQuery(GET_GUEST_PROFILES, {
    skip: true
  });
  const getRooms = useCustomQuery(GET_ROOMS, { variables: { page: 1, pageSize: 500 }, skip: !roomSearchValue });

  // const onSetGuestProfilesQueryFilters = (key, value) => {
  //   setGuestProfilesQueryFilters((prev) => ({ ...prev, [key]: value }));
  // };

  const onSearchCustomer = useCallback(
    debounce((value) => {
      if (value.length >= 2) {
        getGuestProfiles
          .refetch({ keyword: value, roomNumber })
          .then(({ data }) => {
            setLoadingGuets(false);
            if (data?.getGuestProfiles?.data?.length > 0) setGuestProfiles([...data.getGuestProfiles.data]);
            else setGuestProfiles([]);
          })
          .catch(() => setLoadingGuets(false));
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (booking?.profile) {
      setGuestKeyword(booking?.profile?.title);
    }
    if (booking?.room) {
      setRoomSearchValue(`${booking?.room?.number} - ${booking?.room?.name}`);
      setRoomNumber(booking?.room?.number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container style={{ paddingTop: 20, paddingBottom: 20 }}>
      <BoldLabel label="restaurant-booking-details" style={{ fontSize: 25 }} />
      <div style={{ marginTop: 20 }}>
        <BoldLabel label="booking-date" />
        <InfoContainer>{booking?.date}</InfoContainer>

        <InputWithLabel label="select-room" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold', marginTop: 20 }}>
          <SearchBar
            fluid
            placeholder="room"
            noShadow
            containerStyle={{ width: '100%' }}
            results={
              getRooms.data?.getRooms?.data
                ?.sort((a, b) => a.number - b.number)
                ?.filter(
                  (room) =>
                    room?.number.toLowerCase().includes(roomSearchValue.toLowerCase()) ||
                    room?.name.toLowerCase().includes(roomSearchValue?.replace(/\s/g, '')?.toLowerCase())
                )
                ?.map((room) => ({
                  key: room.uuid,
                  title: `${room.number} - ${room.name}  `,
                  ...room
                })) ?? []
            }
            loading={getRooms.loading}
            value={roomSearchValue}
            onResultSelect={(e, { result }) => {
              onSetBookingByKey('room', result);
              setRoomSearchValue(result.title);
              setRoomNumber(result.number);
            }}
            onChange={(e, { value }) => {
              setRoomSearchValue(value);
              if (value.length === 0) setRoomNumber('');
            }}
          />
        </InputWithLabel>

        <InputWithLabel label="select-profile" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold', marginTop: 20 }}>
          <SearchBar
            fluid
            placeholder="profile"
            noShadow
            containerStyle={{ width: '100%' }}
            results={
              guestProfiles?.map((p) => ({
                key: p.uuid,
                title: `${p.firstName} ${p.lastName} ${p?.currentCheckin?.room?.number ? ` - ${p?.currentCheckin?.room?.number}` : ''}`,
                uuid: p.uuid,
                currentcheckin: p?.currentCheckin
              })) ?? []
            }
            loading={getGuestProfiles.loading || loadingGuests}
            value={guestKeyword}
            onChange={({ target: { value } }) => {
              if (!value || value.length < 2) {
                setLoadingGuets(false);
              } else {
                setLoadingGuets(true);
                onSearchCustomer(value);
              }
              setGuestKeyword(value);
            }}
            onResultSelect={(e, { result }) => {
              if (result?.currentcheckin) {
                setRoomSearchValue(`${result.currentcheckin.room.number} - ${result.currentcheckin.room.name}`);
              } else {
                setRoomSearchValue('');
              }
              setGuestKeyword(result.title);
              onSetProfile(result);
            }}
          />
        </InputWithLabel>

        <Divider horizontal>
          <TranslateTextComponent>or</TranslateTextComponent>
        </Divider>

        <BoldLabel label="create-guest-profile" />

        <Input
          fluid
          value={booking?.newProfile?.firstName ?? ''}
          placeholder={capitalizeFirstLetter(translate('firstName', language))}
          style={{ marginTop: 10 }}
          onChange={(e) => {
            onSetNewProfile('firstName', e.target.value);
            setGuestKeyword('');
          }}
        />

        <Input
          fluid
          value={booking?.newProfile?.lastName ?? ''}
          placeholder={capitalizeFirstLetter(translate('lastName', language))}
          style={{ marginTop: 20 }}
          onChange={(e) => {
            onSetNewProfile('lastName', e.target.value);
            setGuestKeyword('');
          }}
        />

        {booking?.profile?.currentcheckin?.arrangement && (
          <div style={{ marginTop: 20 }}>
            <BoldLabel label="arrangement" />
            <InfoContainer>{booking?.profile?.currentcheckin?.arrangement}</InfoContainer>
          </div>
        )}

        <InputWithLabel label="notes" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold', marginTop: 20 }}>
          <TextArea
            value={booking?.notes ?? ''}
            placeholder={capitalizeFirstLetter(translate('notes', language))}
            onChange={(e) => {
              onSetBookingByKey('notes', e.target.value);
            }}
            style={{
              width: '100%',
              border: '1px solid rgba(34, 36, 38, 0.15)',
              padding: 9.5,
              minHeight: 72,
              color: theme.colors.primaryColor,
              fontSize: 13
            }}
          />
        </InputWithLabel>
      </div>
    </Container>
  );
};

export default BookFbReservationCheckOutContent;
