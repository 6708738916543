import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { ModalContext } from '../../contexts/ModalContext';
import { ServiceCartContext } from '../../contexts/ServiceCartContext';
import { TranslateTextComponent } from '../../translator';
import BoldLabel from '../shared/BoldLabel';
import { ReactComponent as DeleteIcon } from '../../assets/images/action-icons/delete.svg';

const DeleteButton = styled.div`
  display: flex;
  align-items: center;
`;

const CartInfoContent = () => {
  const modalContext = useContext(ModalContext);
  const { serviceCart, removeAllServices, getTotalPrice, removeService } = useContext(ServiceCartContext);

  const openModalToEmptyCart = () => {
    modalContext.openModal({
      title: 'are-you-sure',
      text: 'empty-the-cart',
      buttons: [
        {
          label: 'cancel',
          onClick: () => modalContext.closeModal()
        },
        {
          label: 'confirm',
          onClick: () => {
            modalContext.closeModal();
            removeAllServices();
          }
        }
      ]
    });
  };
  return (
    <div>
      <BoldLabel label={serviceCart?.fbOutlet?.name || 'cart'} />
      <SubTotal items={serviceCart?.services} totalPrice={getTotalPrice()} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <TranslateTextComponent style={{ fontFamily: 'optima-medium', fontSize: 20 }} capitalize>
          order-summary
        </TranslateTextComponent>
        {serviceCart?.services?.length > 0 && (
          <div
            style={{
              display: 'flex',
              height: '100%',
              paddingTop: 2
            }}
          >
            <DeleteButton onClick={openModalToEmptyCart}>
              <TranslateTextComponent style={{ fontSize: 15 }} capitalize>
                all
              </TranslateTextComponent>

              <DeleteIcon style={{ marginLeft: 5 }} />
            </DeleteButton>
          </div>
        )}
      </div>
      {serviceCart?.services?.map((item, index) => {
        return <CartItem key={index} item={item} onRemoveItem={() => removeService(item)} />;
      })}
    </div>
  );
};

const SubTotal = ({ items, totalPrice }) => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 10
      }}
    >
      <span style={{ fontFamily: 'optima-medium', fontSize: 20 }}>
        <TranslateTextComponent style={{ fontFamily: 'optima-medium', fontSize: 20 }} capitalize>
          total
        </TranslateTextComponent>{' '}
        ({items?.filter((item) => item?.id).length}{' '}
        <TranslateTextComponent style={{ fontFamily: 'optima-medium', fontSize: 20 }} capitalize>
          {items?.filter((item) => item?.id).length === 1 ? 'item' : 'items'}
        </TranslateTextComponent>
        )
      </span>
      <span style={{ fontFamily: 'optima-medium', fontSize: 20 }}>€ {totalPrice}</span>
    </div>
  );
};

const CartItem = ({ item, onRemoveItem }) => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 15
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <span style={{ fontSize: 18 }}>
          {item?.quantity} x {item?.name}
        </span>
      </div>
      <div
        style={{
          display: 'flex',
          height: '100%',
          paddingTop: 2
        }}
      >
        <span style={{ fontSize: 15 }}>{item?.quantity * item?.price} €</span>
        {item?.id && (
          <DeleteButton onClick={() => onRemoveItem(item)}>
            <DeleteIcon style={{ marginLeft: 5 }} />
          </DeleteButton>
        )}
      </div>
    </div>
  );
};

export default CartInfoContent;
