import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import Container from '../components/shared/Container';
import { GET_FB_ORDER } from '../graphql/orders/queries';
import useCustomQuery from '../hooks/useCustomQuery';
import { TranslateTextComponent } from '../translator';

const TitleContainer = styled.div`
  margin-top: 5px;
  font-size: 1.5rem;
  text-align: center;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 10px;
`;

const SectionTitle = styled(TranslateTextComponent).attrs({ capitalize: true })`
  font-family: optima-bold;
  font-size: 1.4rem;
`;

const SectionData = styled.span`
  font-size: 1.3rem;
  margin-top: 5px;
`;

const ServiceDetailContainer = () => {
  const { uuid } = useParams();
  // Queries
  const getFbOrder = useCustomQuery(GET_FB_ORDER, { variables: { fbOrderUUID: uuid }, skip: !uuid });

  return (
    <Container>
      <TitleContainer>Order #{getFbOrder.data?.getFbOrder?.orderNumber ?? ''}</TitleContainer>
      <SectionContainer>
        <SectionTitle>date</SectionTitle>
        <SectionData>{getFbOrder.data?.getFbOrder?.date ?? '-'}</SectionData>
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>room</SectionTitle>
        <SectionData>{getFbOrder.data?.getFbOrder?.guestRoom ?? '-'}</SectionData>
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>outlet</SectionTitle>
        <SectionData>{getFbOrder.data?.getFbOrder?.fbOutlet?.name ?? '-'}</SectionData>
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>full-name</SectionTitle>
        <SectionData>
          {`${getFbOrder.data?.getFbOrder?.profile?.firstName ?? ''} ${getFbOrder.data?.getFbOrder?.profile?.lastName ?? ''}`.trim() ||
            getFbOrder.data?.getFbOrder?.clientName ||
            '-'}
        </SectionData>
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>order</SectionTitle>
        {getFbOrder.data?.getFbOrder?.orderSmartServices?.map((o, index) => (
          <SectionData key={index}>
            {o.quantity} x {o.name}
          </SectionData>
        ))}
      </SectionContainer>
    </Container>
  );
};

export default ServiceDetailContainer;
