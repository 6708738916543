import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import moment from 'moment';
import styled from 'styled-components/macro';
import { LanguageContext } from '../../contexts/LanguageContext';
import useCustomQuery from '../../hooks/useCustomQuery';
import { GET_PROFILES } from '../../graphql/profiles/queries';
import { GET_FB_SERVICES, GET_SMART_OUTLETS } from '../../graphql/outlets/queries';
import { GET_BUILDINGS, GET_ROOMS } from '../../graphql/roombuilding/queries';
import { GET_COMPLAINT_TYPES } from '../../graphql/complaints/queries';
import { GET_TICKET_TYPES } from '../../graphql/tickets/queries';
import { capitalizeFirstLetter, translate, TranslateTextComponent } from '../../translator';
import SUIContainer from './Container';
import DatePicker from './DatePicker';
import InputWithLabel from './InputWithLabel';
import SearchBar from './SearchBar';
import Button from './Button';

const Container = styled(SUIContainer)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transform: ${({ show }) => (show ? 'translateX(0)' : 'translateX(200%)')};
  transition: transform 0.3s;
  overflow: auto;

  .clndr-calendars-segment.clndr-floating {
    bottom: 40px;
  }

  & > div:not(:first-child):not(:last-child) {
    margin-bottom: 20px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Title = styled(TranslateTextComponent).attrs({ capitalize: true })`
  display: inline-block;
  font-family: optima-bold;
  font-size: 25px;
  margin-bottom: 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: ${({ theme: { colors } }) => `1px solid ${colors.borderColor}`};
  border-bottom: ${({ theme: { colors } }) => `1px solid ${colors.borderColor}`};

  .ui.button {
    margin: 0px;
    width: 100%;
    text-transform: capitalize !important;
    border-left: ${({ theme: { colors } }) => `1px solid ${colors.borderColor}`};
    border-right: ${({ theme: { colors } }) => `1px solid ${colors.borderColor}`};
    border-radius: 0px;
  }

  .active-button {
    background-color: ${({ theme: { colors } }) => `${colors.tertiaryColor} !important`};
    color: white !important;
  }

  .not-active-button {
    background-color: white !important;
    color: ${({ theme: { colors } }) => `${colors.primaryColor} !important`};
  }
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  & > *:first-child {
    margin-right: 16px;
  }

  @media (max-width: 500px) {
    flex-flow: column;
    & > *:first-child {
      margin-right: 0px;
      margin-bottom: 16px;
    }
  }
`;

const resetFiltersValues = {
  keyword: '',
  date: null,
  guestUUID: null,
  fbOutletUUID: null,
  fbServiceUUID: null,
  profileUUID: null,
  complaintTypeUUID: null,
  roomUUID: null,
  ticketTypeUUID: null,
  priority: null,
  page: 1,
  pageSize: 10
};

const MultipleFilters = ({ type, showFilters, initialFilters, onFilterChange, onClear }) => {
  const { language } = useContext(LanguageContext);
  const [filters, setFilters] = useState({
    keyword: initialFilters?.keyword ?? '',
    date: initialFilters?.date ?? null,
    guestUUID: initialFilters?.guestUUID ?? null,
    fbOutletUUID: initialFilters?.fbOutletUUID ?? null,
    fbServiceUUID: initialFilters?.fbServiceUUID ?? null,
    profileUUID: initialFilters?.profileUUID ?? null,
    buildingUUID: initialFilters?.buildingUUID ?? null,
    complaintTypeUUID: initialFilters?.complaintTypeUUID ?? null,
    roomUUID: initialFilters?.roomUUID ?? null,
    ticketTypeUUID: initialFilters?.ticketTypeUUID ?? null,
    priority: initialFilters?.priority ?? null,
    page: initialFilters?.page ?? 1,
    pageSize: initialFilters?.pageSize ?? 10
  });

  const [profileSearchValue, setProfileSearchValue] = useState('');
  const [roomSearchValue, setRoomSearchValue] = useState('');
  const [buildingSearchValue, setBuildingSearchValue] = useState('');

  // Queries
  const getProfiles = useCustomQuery(GET_PROFILES, {
    variables: { keyword: profileSearchValue },
    skip: type === 'tickets'
  });
  const getSmartOutlets = useCustomQuery(GET_SMART_OUTLETS, { skip: type === 'complaints' && type === 'tickets' });
  const getComplaintTypes = useCustomQuery(GET_COMPLAINT_TYPES, { skip: type !== 'complaints' });
  const getRooms = useCustomQuery(GET_ROOMS, { skip: type !== 'complaints' });
  const getBuildings = useCustomQuery(GET_BUILDINGS, { skip: type !== 'tickets' });
  const getTicketTypes = useCustomQuery(GET_TICKET_TYPES, { skip: type !== 'tickets' });
  const getFbServices = useCustomQuery(GET_FB_SERVICES, {
    variables: {
      fbOutletUUID: filters?.fbOutletUUID
    },
    fetchPolicy: 'network-only',
    skip: type !== 'reservations' || !filters?.fbOutletUUID
  });

  useEffect(() => {
    if (filters?.buildingUUID && showFilters) {
      const res = getBuildings.data.getBuildings.data.find((building) => building.uuid === filters.buildingUUID);
      setBuildingSearchValue(`${res?.number}${res?.number ? ` - ${res?.name}` : res?.name} `);
    }
    if ((filters?.profileUUID || filters?.guestUUID) && showFilters) {
      const res = getProfiles.data.getProfiles.data.find((profile) => profile.uuid === filters.profileUUID || profile?.uuid === filters?.guestUUID);
      setProfileSearchValue(`${res?.firstName} ${res?.lastName}`);
    }
    if (filters?.roomUUID && showFilters) {
      const res = getRooms.data.getRooms.data.find((room) => room.uuid === filters.roomUUID);
      setRoomSearchValue(`${res?.number}${res?.number ? ` - ${res?.name}` : res?.name} `);
    }
    setFilters({ ...initialFilters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilters, initialFilters]);

  return (
    <Container show={showFilters}>
      <TitleContainer>
        <Title>filter-by</Title>
        <TranslateTextComponent
          style={{ cursor: 'pointer', color: 'red', textDecoration: 'underline' }}
          capitalize
          onClick={() => {
            setProfileSearchValue('');
            setRoomSearchValue('');
            setBuildingSearchValue('');
            setFilters({ ...resetFiltersValues });
            if (onClear) {
              onClear();
            }
            onFilterChange({ key: 'apply-filters' });
          }}
        >
          clear
        </TranslateTextComponent>
      </TitleContainer>
      <SearchBar
        open={false}
        placeholder="keyword"
        value={filters.keyword}
        onChange={(e, { value }) => setFilters((prev) => ({ ...prev, keyword: value }))}
      />
      {type !== 'tickets' && (
        <InputWithLabel label="select-profile" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold' }}>
          <SearchBar
            fluid
            placeholder="profile"
            containerStyle={{ width: '100%' }}
            results={getProfiles.data?.getProfiles?.data?.map((p) => ({ key: p.uuid, title: `${p.firstName} ${p.lastName}`, uuid: p.uuid })) ?? []}
            loading={getProfiles.loading}
            value={profileSearchValue}
            onResultSelect={(e, { result: { title, uuid } }) => {
              setProfileSearchValue(title);
              setFilters((prev) => ({ ...prev, guestUUID: uuid, profileUUID: uuid }));
            }}
            onChange={(e, { value }) => {
              if (value?.length <= 0) setProfileSearchValue(null);
              setProfileSearchValue(value);
            }}
          />
        </InputWithLabel>
      )}
      {type === 'complaints' && (
        <InputWithLabel label="complaint-type" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold' }}>
          <Dropdown
            fluid
            search
            selection
            clearable
            options={getComplaintTypes.data?.getComplaintTypes?.data?.map((c) => ({ text: c.name, value: c.uuid })) ?? []}
            value={filters.complaintTypeUUID}
            onChange={(e, { value }) => setFilters((prev) => ({ ...prev, complaintTypeUUID: value }))}
          />
        </InputWithLabel>
      )}
      {type === 'complaints' && (
        <InputWithLabel label="select-room" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold' }}>
          <SearchBar
            fluid
            placeholder={capitalizeFirstLetter(translate('room', language))}
            containerStyle={{ width: '100%' }}
            results={
              getRooms.data?.getRooms?.data
                ?.map((r) => ({ key: r.uuid, title: `${r.number ? `${r.number} - ` : ''}${r.name ?? ''}`.trim(), uuid: r.uuid }))
                ?.filter(
                  (r) => r.title?.toLowerCase().includes(roomSearchValue?.toLowerCase()) || r.number?.().includes(roomSearchValue?.toLowerCase())
                ) ?? []
            }
            loading={getRooms.loading}
            value={roomSearchValue}
            onResultSelect={(e, { result: { title, uuid } }) => {
              setRoomSearchValue(title);
              setFilters((prev) => ({ ...prev, roomUUID: uuid }));
            }}
            onChange={(e, { value }) => {
              if (value?.length <= 0) {
                setRoomSearchValue(null);
                setFilters((prev) => ({ ...prev, roomUUID: null }));
              }
              setRoomSearchValue(value);
            }}
          />
        </InputWithLabel>
      )}
      {type === 'tickets' && (
        <InputWithLabel label="Room/Building" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold' }}>
          <SearchBar
            fluid
            placeholder={capitalizeFirstLetter(translate('room', language))}
            containerStyle={{ width: '100%' }}
            results={
              getBuildings.data?.getBuildings?.data
                ?.map((b) => ({ key: b.uuid, title: `${b.number ? `${b.number} - ` : ''}${b.name}` ?? '', uuid: b.uuid }))
                ?.filter(
                  (b) =>
                    b.title?.toLowerCase().includes(buildingSearchValue?.toLowerCase()) || b.number?.().includes(buildingSearchValue?.toLowerCase())
                ) ?? []
            }
            loading={getBuildings.loading}
            value={buildingSearchValue}
            onResultSelect={(e, { result: { title, uuid } }) => {
              setBuildingSearchValue(title);
              setFilters((prev) => ({ ...prev, buildingUUID: uuid }));
            }}
            onChange={(e, { value }) => {
              if (value?.length <= 0) {
                setBuildingSearchValue(null);
                setFilters((prev) => ({ ...prev, buildingUUID: null }));
              }
              setBuildingSearchValue(value);
            }}
          />
        </InputWithLabel>
      )}
      {type === 'tickets' && (
        <InputWithLabel label="ticket-type" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold' }}>
          <Dropdown
            fluid
            placeholder={capitalizeFirstLetter(translate('ticket-type', language))}
            search
            selection
            clearable
            options={getTicketTypes.data?.getTicketTypes?.data?.map((o) => ({ text: o.name, value: o.uuid })) ?? []}
            value={filters.ticketTypeUUID}
            onChange={(e, { value }) => setFilters((prev) => ({ ...prev, ticketTypeUUID: value }))}
          />
        </InputWithLabel>
      )}
      {(type === 'complaints' || type === 'tickets') && (
        <InputWithLabel label="priority" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold' }}>
          <ButtonsContainer>
            <Button
              className={filters.priority === 'low' ? 'active-button' : 'not-active-button'}
              onClick={() => setFilters((prev) => ({ ...prev, priority: prev.priority === 'low' ? null : 'low' }))}
            >
              low
            </Button>
            <Button
              className={filters.priority === 'medium' ? 'active-button' : 'not-active-button'}
              onClick={() => setFilters((prev) => ({ ...prev, priority: prev.priority === 'medium' ? null : 'medium' }))}
            >
              medium
            </Button>
            <Button
              className={filters.priority === 'high' ? 'active-button' : 'not-active-button'}
              onClick={() => setFilters((prev) => ({ ...prev, priority: prev.priority === 'high' ? null : 'high' }))}
            >
              high
            </Button>
          </ButtonsContainer>
        </InputWithLabel>
      )}
      {type !== 'complaints' && type !== 'tickets' && (
        <InputWithLabel label="select-outlet" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold' }}>
          <Dropdown
            fluid
            placeholder="Outlet"
            search
            selection
            clearable
            options={getSmartOutlets.data?.getSmartOutlets?.data?.map((o) => ({ text: o.name, value: o.uuid })) ?? []}
            value={filters.fbOutletUUID}
            onChange={(e, { value }) => setFilters((prev) => ({ ...prev, fbOutletUUID: value, fbServiceUUID: null }))}
          />
        </InputWithLabel>
      )}
      <DateContainer>
        <InputWithLabel label="start-date" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold' }}>
          <DatePicker
            value={filters.startDate ? new Date(filters.startDate) : null}
            clearable
            onChange={(e, { value }) => setFilters((prev) => ({ ...prev, startDate: value ? moment(value).format('YYYY-MM-DD') : null }))}
          />
        </InputWithLabel>

        <InputWithLabel label="end-date" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold' }}>
          <DatePicker
            value={filters.endDate ? new Date(filters.endDate) : null}
            clearable
            onChange={(e, { value }) => setFilters((prev) => ({ ...prev, endDate: value ? moment(value).format('YYYY-MM-DD') : null }))}
          />
        </InputWithLabel>
      </DateContainer>
      {type === 'reservations' && filters?.fbOutletUUID && (
        <InputWithLabel label="service" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold' }}>
          <Dropdown
            fluid
            search
            selection
            clearable
            options={
              getFbServices?.data?.getFbServices?.data?.filter((service) => service?.isActive)?.map((s) => ({ text: s.name, value: s.uuid })) ?? []
            }
            value={filters.fbServiceUUID}
            onChange={(e, { value }) => setFilters((prev) => ({ ...prev, fbServiceUUID: value }))}
          />
        </InputWithLabel>
      )}
      <Button
        text="apply-filters"
        style={{ width: '100%', marginBottom: 10 }}
        onClick={() => onFilterChange({ key: 'apply-filters', value: filters })}
      />
    </Container>
  );
};

MultipleFilters.defaultProps = {
  type: ''
};

export default MultipleFilters;
