import { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ModalContext } from '../contexts/ModalContext';

const useCustomQuery = (query, params) => {
  const modalContext = useContext(ModalContext);

  const queryResponse = useQuery(query, {
    fetchPolicy: 'network-only',
    ...params,
    onError: (err) => {
      const textError = JSON.stringify(err);
      const queryName = query.definitions[0]?.name?.value ?? '';
      console.log(`Error ${queryName}:`, JSON.parse(textError));
      modalContext.openModal({
        class: 'danger',
        title: 'error',
        text: `${queryName}: ${err}`
      });
    }
  });
  return queryResponse;
};

export default useCustomQuery;
