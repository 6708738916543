/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';

export const GET_BUILDINGS = gql`
  query getBuildings($orderBy: String, $orderType: String, $page: Int, $pageSize: Int) {
    getBuildings(orderBy: $orderBy, orderType: $orderType, page: $page, pageSize: $pageSize) {
      totalCount
      data {
        id
        uuid
        name
        number
      }
    }
  }
`;

export const GET_ROOMS = gql`
  query getRooms($orderBy: String, $orderType: String, $page: Int, $pageSize: Int) {
    getRooms(orderBy: $orderBy, orderType: $orderType, page: $page, pageSize: $pageSize) {
      totalCount
      data {
        id
        uuid
        name
        number
      }
    }
  }
`;
