import React from 'react';
import SectionDetails from './SectionDetails';
import reservations from '../../assets/images/home/reservations.png';
import complaints from '../../assets/images/home/complaints.png';
import tickets from '../../assets/images/home/tickets.png';
import { SECTION_TYPES } from '../../constants/genericConstants';

const Section = ({ routePath, type }) => {
  switch (type) {
    case SECTION_TYPES.RESERVATIONS:
      return <SectionDetails type={type} routePath={routePath} backgroundImage={reservations} />;
    case SECTION_TYPES.COMPLAINTS:
      return <SectionDetails type={type} routePath={routePath} backgroundImage={complaints} />;
    case SECTION_TYPES.TICKETS:
      return <SectionDetails type={type} routePath={routePath} backgroundImage={tickets} />;
    default:
      return null;
  }
};

export default Section;
