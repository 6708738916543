import { useMutation } from '@apollo/react-hooks';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ModalContext } from '../contexts/ModalContext';
import { getApolloErrors } from '../apollo/ApolloProvider';
import useCustomQuery from '../hooks/useCustomQuery';
import { DELETE_FB_RESERVATION } from '../graphql/reservations/mutations';
import { GET_FB_RESERVATION } from '../graphql/reservations/queries';
import Container from '../components/shared/Container';
import FooterButton from '../components/shared/FooterButton';
import { TranslateTextComponent } from '../translator';
import Spinner from '../components/shared/Spinner';

const TitleContainer = styled.div`
  margin-top: 5px;
  font-size: 1.5rem;
  text-align: center;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 10px;
`;

const SectionTitle = styled(TranslateTextComponent).attrs({ capitalize: true })`
  font-family: optima-bold;
  font-size: 1.4rem;
`;

const SectionData = styled.span`
  font-size: 1.3rem;
  margin-top: 5px;
  text-transform: capitalize;

  a {
    color: ${({ theme: { colors } }) => colors.primaryColor};
    text-decoration: underline;
  }
`;

const ReservationDetailContainer = () => {
  const { uuid } = useParams();
  const modalContext = useContext(ModalContext);
  const [isLoading, setIsLoading] = useState(false);

  // Queries
  const getFbReservation = useCustomQuery(GET_FB_RESERVATION, { variables: { fbReservationUUID: uuid }, skip: !uuid });

  const [deleteFbReservation] = useMutation(DELETE_FB_RESERVATION);
  const deleteReservation = () => {
    modalContext.openModal({
      title: 'are-you-sure',
      text: 'delete-fb-reservation',
      buttons: [
        { label: 'cancel', onClick: () => modalContext.closeModal() },
        {
          label: 'confirm',
          onClick: () => {
            setIsLoading(true);
            modalContext.closeModal();
            deleteFbReservation({ variables: { fbReservationUUID: uuid } })
              .then(() => getFbReservation.refetch())
              .catch((err) =>
                modalContext.openModal({
                  title: 'error',
                  text: getApolloErrors(err)?.[0]?.toLowerCase().replace(/\s/g, '-') ?? ''
                })
              )
              .finally(() => setIsLoading(false));
          }
        }
      ]
    });
  };

  return (
    <Container style={{ paddingTop: 20 }}>
      {isLoading || getFbReservation?.loading ? (
        <Spinner />
      ) : (
        <>
          <TitleContainer>Reservation #{getFbReservation.data?.getFbReservation?.reservationNumber ?? ''}</TitleContainer>
          <SectionContainer>
            <SectionTitle>status</SectionTitle>
            <SectionData>{getFbReservation.data?.getFbReservation?.status ?? '-'}</SectionData>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>date</SectionTitle>
            <SectionData>
              {`${getFbReservation.data?.getFbReservation?.date ?? ''} - ${getFbReservation.data?.getFbReservation?.reservationTime ?? ''}`.trim()}
            </SectionData>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>outlet</SectionTitle>
            <SectionData>{getFbReservation.data?.getFbReservation?.fbService?.fbOutlet?.name ?? '-'}</SectionData>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>room</SectionTitle>
            <SectionData>{getFbReservation.data?.getFbReservation?.guestRoom ?? '-'}</SectionData>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>contact-us</SectionTitle>
            <SectionData>
              {getFbReservation.data?.getFbReservation?.fbService?.fbOutlet?.phoneNumber ? (
                <a href={`tel:${getFbReservation.data.getFbReservation.fbService.fbOutlet.phoneNumber}`}>
                  {getFbReservation.data.getFbReservation.fbService.fbOutlet.phoneNumber}
                </a>
              ) : (
                '-'
              )}
            </SectionData>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>reservation</SectionTitle>
            <SectionData>Pax: {getFbReservation.data?.getFbReservation?.nPax ?? '-'}</SectionData>
            <SectionData>
              <TranslateTextComponent capitalize>name</TranslateTextComponent>:{' '}
              {`${getFbReservation.data?.getFbReservation?.bookedBy?.firstname ?? ''} ${
                getFbReservation.data?.getFbReservation?.bookedBy?.lastname ?? ''
              }`.trim() || '-'}
            </SectionData>
            <SectionData style={{ textTransform: 'none' }}>
              Email: {getFbReservation.data?.getFbReservation?.bookedBy?.email?.toLowerCase() ?? '-'}
            </SectionData>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>Notes</SectionTitle>
            <SectionData style={{ textTransform: 'none' }}>{getFbReservation.data?.getFbReservation?.notes ?? '-'}</SectionData>
          </SectionContainer>
          {getFbReservation?.data?.getFbReservation?.status !== 'deleted' && (
            <FooterButton
              text="delete"
              onClick={() => {
                deleteReservation();
              }}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default ReservationDetailContainer;
