import React from 'react';
import styled from 'styled-components/macro';
import { TranslateTextComponent } from '../../translator';
import { ReactComponent as ChevronRight } from '../../assets/images/arrows/chevron-right.svg';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  cursor: pointer;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  justify-content: center;
  width: 30px;
  height: 30px;
  align-items: center;
  margin-right: 10px;
`;

const SettingButton = ({ disabled, icon, text, rightComponent, rightText, onClick }) => {
  return (
    <Container disabled={disabled} onClick={onClick}>
      <LeftContainer>
        {icon && (
          <IconContainer>
            <ChevronRight />
          </IconContainer>
        )}

        <TranslateTextComponent style={{ fontFamily: 'optima-medium', fontSize: 20 }} capitalize>
          {text}
        </TranslateTextComponent>
      </LeftContainer>
      {rightComponent || (
        <div
          style={{
            marginRight: 10,
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          {rightText && (
            <TranslateTextComponent style={{ fontFamily: 'optima-medium', fontSize: 20, marginRight: 10 }}>{rightText}</TranslateTextComponent>
          )}
          <ChevronRight />
        </div>
      )}
    </Container>
  );
};

export default SettingButton;
