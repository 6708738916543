import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Icon } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from 'react-router-dom';
import useCustomQuery from '../hooks/useCustomQuery';
import { GET_FB_RESERVATIONS } from '../graphql/reservations/queries';
import { ROUTE_RESERVATION_DETAIL } from '../router/routes';
import { TranslateTextComponent } from '../translator';
import SUIContainer from '../components/shared/Container';
import Spinner from '../components/shared/Spinner';
import Card from '../components/shared/Card';
import SearchBar from '../components/shared/SearchBar';
import MultipleFilters from '../components/shared/MultipleFilters';

const Container = styled(SUIContainer)`
  position: relative;
  margin-top: 12px;
  overflow: hidden;
  height: 100%;

  & > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const Title = styled(TranslateTextComponent).attrs({ capitalize: true })`
  display: inline-block;
  font-family: optima-bold;
  font-size: 25px;
  margin-bottom: 10px;
  margin-right: 10px;
`;

const ReservationNumbers = styled.span`
  display: inline-block;
  font-family: optima-bold;
  font-size: 25px;
  margin-bottom: 10px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CardsContainer = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const ReservationsListContainer = () => {
  const history = useHistory();
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    keyword: '',
    date: null,
    guestUUID: null,
    fbOutletUUID: null,
    fbServiceUUID: null,
    pageSize: 10,
    page: 1,
    orderType: 'DESC'
  });

  // Queries
  const getFbReservations = useCustomQuery(GET_FB_RESERVATIONS, { variables: { ...filters } });

  const onFilterChange = ({ key, value }) => {
    switch (key) {
      case 'keyword':
        getFbReservations.refetch({ ...filters, keyword: value }).then(({ data }) => {
          setReservations([...(data?.getFbReservations?.data ?? [])]);
        });
        setFilters((prev) => ({ ...prev, keyword: value }));
        break;
      case 'apply-filters':
        getFbReservations.refetch({ ...value }).then(({ data }) => {
          setReservations([...(data?.getFbReservations?.data ?? [])]);
        });
        setFilters({ ...value });
        setShowFilters(false);
        break;
      default:
        break;
    }
  };

  const getTotalPax = () => {
    return reservations.reduce((pax, r) => {
      return pax + r.nPax;
    }, 0);
  };

  return (
    <Container>
      <Title>reservations</Title>
      {!getFbReservations.loading && reservations.length > 0 && (
        <ReservationNumbers>{`${reservations?.length}/${getFbReservations.data?.getFbReservations?.totalCount}`}</ReservationNumbers>
      )}
      <FiltersContainer>
        <SearchBar
          fluid
          placeholder="search-here"
          containerStyle={{ width: '100%', marginRight: 10 }}
          loading={getFbReservations.loading}
          open={false}
          onChange={(e, { value }) => onFilterChange({ key: 'keyword', value })}
        />
        <Icon name="options" style={{ cursor: 'pointer', fontSize: '1.5em' }} onClick={() => setShowFilters(true)} />
      </FiltersContainer>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <TranslateTextComponent capitalize>total-pax</TranslateTextComponent>
        <span> {getTotalPax()}</span>
      </div>

      <MultipleFilters showFilters={showFilters} initialFilters={filters} onFilterChange={onFilterChange} type="reservations" />
      {getFbReservations.loading && reservations.length <= 0 ? (
        <Spinner />
      ) : (
        !showFilters && (
          <InfiniteScroll
            pageStart={1}
            hasMore={reservations.length < getFbReservations.data?.getFbReservations?.totalCount && !loading}
            loadMore={(page) => {
              setLoading(true);
              return getFbReservations.refetch({ ...filters, page }).then(({ data }) => {
                setLoading(false);
                setReservations((prev) => [...prev, ...(data?.getFbReservations?.data ?? [])]);
              });
            }}
            loader={<Spinner key={0} />}
          >
            <CardsContainer>
              {reservations?.map((r) => (
                <Card
                  key={r.uuid}
                  data={[
                    { left: { element: `Reservation #${r.reservationNumber}` }, right: { element: r.guestRoom ? `Room: ${r.guestRoom ?? ''}` : '' } },
                    {
                      left: { element: `${r.bookedBy?.firstname} ${r.bookedBy?.lastname}` },
                      right: { element: `Pax: ${r.nPax}` }
                    },
                    {
                      left: { element: `${r.date} - ${r.reservationTime}` },
                      right: { element: r.checkin?.arrangement ? `Arrangement: ${r.checkin?.arrangement ?? ''}` : '' }
                    },
                    {
                      left: { element: r.fbService?.fbOutlet?.name, style: { fontFamily: 'optima-bold' } },
                      right: { element: `Status: ${r?.status}` }
                    }
                  ]}
                  onClick={() => history.push(ROUTE_RESERVATION_DETAIL.replace(':uuid', r.uuid))}
                />
              ))}
            </CardsContainer>
          </InfiniteScroll>
        )
      )}
    </Container>
  );
};

export default ReservationsListContainer;
