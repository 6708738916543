import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Search } from 'semantic-ui-react';
import { LanguageContext } from '../../contexts/LanguageContext';
import { capitalizeFirstLetter, translate } from '../../translator';

const Container = styled.div`
  .ui.input {
    width: 100%;
  }

  .ui.search .prompt {
    border-radius: 0px;
    box-shadow: ${({ noShadow }) => (noShadow ? 'unset' : ' 3px 3px 3px rgba(0, 0, 0, 0.16)')};
  }

  .ui.icon.input > i.icon {
    left: 0;
  }

  .ui.icon.input > input {
    padding-left: 2.67142857em !important;
    padding-right: 5px !important;
  }

  .results {
    max-height: ${({ resultsHeight }) => resultsHeight};
    overflow: auto;
  }
`;

const SearchBar = ({
  fluid,
  placeholder,
  loading,
  open,
  icon = 'search',
  noShadow,
  size,
  noResultsMessage,
  containerStyle,
  style,
  value,
  results,
  onResultSelect,
  onClick,
  onChange
}) => {
  const { language } = useContext(LanguageContext);
  const containerRef = useRef(null);
  const [resultsHeight, setResultsHeight] = useState('50vh');

  const offset = 50;

  useEffect(() => {
    setResultsHeight(
      `${window.innerHeight - containerRef.current?.getBoundingClientRect().y - containerRef.current?.getBoundingClientRect()?.height - offset}px`
    );
  }, [containerRef]);

  return (
    <Container ref={containerRef} resultsHeight={resultsHeight} style={containerStyle} noShadow={noShadow}>
      <Search
        fluid={fluid}
        loading={loading}
        open={open}
        icon={loading ? 'spinner' : icon}
        size={size}
        placeholder={capitalizeFirstLetter(translate(placeholder, language))}
        style={style}
        noResultsMessage={capitalizeFirstLetter(translate(noResultsMessage, language))}
        showNoResults={!loading && value?.length > 0 && results?.length <= 0}
        value={value}
        results={results}
        onClick={onClick}
        onResultSelect={onResultSelect}
        onSearchChange={onChange}
      />
    </Container>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  noResultsMessage: PropTypes.string
};

SearchBar.defaultProps = {
  placeholder: '',
  noResultsMessage: 'no-results-found'
};

export default SearchBar;
