import { useMutation } from '@apollo/react-hooks';
import React, { useEffect, createContext, useState } from 'react';
import { CREATE_STAFF_PROFILE_SMART_SERVICE_ORDER } from '../graphql/services/mutations';

export const ServiceCartContext = createContext();

export const DISPATCHER_ACTIONS = {
  ADD_ITEM: 'add-item',
  REMOVE_ITEM: 'remove-item',
  DATE: 'date',
  RESET: 'reset'
};

const initialState = {
  date: null,
  services: [],
  notes: null,
  profile: null,
  room: null,
  fbOutlet: null,
  newProfile: null,
  total: 0
};

export const ServiceCartProvider = ({ children }) => {
  const [createStaffProfileSmartServiceOrder] = useMutation(CREATE_STAFF_PROFILE_SMART_SERVICE_ORDER);

  const [serviceCart, setServiceCart] = useState(JSON.parse(localStorage.getItem('serviceCart')) || initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    localStorage.setItem('serviceCart', JSON.stringify(serviceCart));
  }, [serviceCart]);

  const onSetServiceCart = (book) => {
    setServiceCart(book);
  };

  const onSetServiceCartByKey = (key, value) => {
    const book = { ...serviceCart, [key]: value };
    setServiceCart(book);
  };

  const onSetProfile = (profile) => {
    onSetServiceCart({
      ...serviceCart,
      profile,
      newProfile: null,
      room: profile?.currentcheckin?.room
    });
  };

  const onSetNewProfile = (key, value) => {
    const updatedNewProfile = { ...serviceCart?.newProfile };
    updatedNewProfile[key] = value;
    onSetServiceCart({
      ...serviceCart,
      newProfile: updatedNewProfile,
      profile: null
    });
  };

  const addService = (item, currentOutlet, date, reset) => {
    let updatedServices = [...serviceCart.services];

    if ((serviceCart.fbOutlet && serviceCart.fbOutlet.uuid !== currentOutlet.uuid) || reset) {
      updatedServices = [];
    }

    const foundItem = updatedServices.find((serviceItem) => serviceItem?.id === item?.id);

    if (foundItem) {
      foundItem.quantity += item.quantity;
    } else {
      updatedServices.unshift(item);
    }

    setServiceCart({
      ...serviceCart,
      services: updatedServices,
      fbOutlet: currentOutlet,
      date
    });
  };

  const removeService = (item) => {
    const updatedServices = [...serviceCart.services];

    const itemIndexToRemove = updatedServices.findIndex((serviceItem) => serviceItem?.id === item?.id);
    if (itemIndexToRemove > -1) {
      updatedServices.splice(itemIndexToRemove, 1);
    }

    setServiceCart({
      ...serviceCart,
      services: updatedServices
    });
  };

  const checkAvailability = (availability) => {
    const updatedServices = [...serviceCart.services];
    availability.forEach((item) => {
      const itemIndexToEdit = serviceCart.services.findIndex((service) => service.uuid === item.smartServiceUUID);
      if (!item.isAvailable && item.maxQuantity === 0) {
        updatedServices.splice(itemIndexToEdit, 1);
      } else if (!item.isAvailable) {
        updatedServices[itemIndexToEdit].quantity = item.maxQuantity;
      }
    });

    setServiceCart({
      ...serviceCart,
      services: updatedServices
    });
  };

  const checkIfCartItemQuantityIsMinorOfMaxQuantity = (id, maxQuantity) => {
    const cartItem = serviceCart.services.find((service) => service.id === id);
    if (cartItem) {
      return cartItem.quantity < maxQuantity;
    }
    return true;
  };

  const getTotalPrice = () => {
    return serviceCart?.services?.reduce((totalPrice, service) => {
      totalPrice += service.price * service.quantity;

      return totalPrice;
    }, 0);
  };

  const isSameOutlet = (fbOutlet) => {
    if (!serviceCart.fbOutlet) return true;
    return serviceCart.fbOutlet?.uuid === fbOutlet.uuid;
  };

  const removeAllServices = () => {
    setServiceCart({ ...serviceCart, services: [] });
  };

  const resetServiceCart = () => {
    setServiceCart(initialState);
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    return createStaffProfileSmartServiceOrder({
      variables: {
        notes: serviceCart?.notes,
        date: serviceCart?.date,
        items: serviceCart?.services?.map((service) => {
          return {
            quantity: service.quantity,
            smartServiceUUID: service.uuid
          };
        }),
        fbOutletUUID: serviceCart?.fbOutlet?.uuid,
        guestRoom: serviceCart.room?.number,
        profileUUID: serviceCart.profile?.uuid,
        createProfile: serviceCart.newProfile
      }
    }).finally(() => {
      setIsSubmitting(false);
    });
  };
  return (
    <ServiceCartContext.Provider
      value={{
        serviceCart,
        onSetServiceCart,
        onSetServiceCartByKey,
        onSetProfile,
        onSetNewProfile,
        addService,
        removeService,
        checkAvailability,
        checkIfCartItemQuantityIsMinorOfMaxQuantity,
        resetServiceCart,
        getTotalPrice,
        isSameOutlet,
        removeAllServices,
        onSubmit,
        isSubmitting
      }}
    >
      {children}
    </ServiceCartContext.Provider>
  );
};

export const ServiceCartConsumer = ServiceCartContext.Consumer;
