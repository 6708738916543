import React, { useContext } from 'react';
import { Input } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import { LanguageContext } from '../../contexts/LanguageContext';
import { ModalContext } from '../../contexts/ModalContext';
import { FORGOT_PASSWORD_REQUEST } from '../../graphql/auth/queries';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import useCustomQuery from '../../hooks/useCustomQuery';
import InputWithLabel from '../shared/InputWithLabel';
import Button from '../shared/Button';
import { capitalizeFirstLetter, TranslateTextComponent, translate } from '../../translator';
import { textTranslateUppercase } from '../../utils/functions';

const Container = styled.form`
  height: 100%;
  font-family: 'Optima';
  padding: 20px;

  @media (min-width: 768px) {
    margin: 5% 15%;
  }
`;

const ForgotPasswordText = styled.div`
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.primaryColor};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ForgotPassword = () => {
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);

  const forgotPasswordRequest = useCustomQuery(FORGOT_PASSWORD_REQUEST, { skip: true });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(capitalizeFirstLetter(translate('invalid-email', language)))
      .required(capitalizeFirstLetter(translate('required', language)))
  });

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        forgotPasswordRequest
          .refetch({ email: values.email })
          .then(() => {
            modalContext.openModal({
              title: 'forgot-password-success-title',
              text: (
                <TranslateTextComponent capitalize vars={{ forgotPasswordEmail: values.email }}>
                  forgot-password-success-text
                </TranslateTextComponent>
              ),
              buttons: [{ label: 'close-modal', onClick: () => modalContext.closeModal() }]
            });
          })
          .catch((err) => {
            modalContext.openModal({
              title: 'forgot-password-failed-title',
              text: getApolloErrors(err)?.[0]?.toLowerCase().replace(/\s/g, '-') ?? '',
              buttons: [{ label: 'close-modal', onClick: () => modalContext.closeModal() }]
            });
          });
        setSubmitting(false);
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, touched }) => (
        <Container onSubmit={handleSubmit}>
          <ForgotPasswordText>{textTranslateUppercase('forgot-password-without-question-mark')}</ForgotPasswordText>
          <InputWithLabel label="email" error={touched.email && errors.email} labelStyle={{ fontWeight: 'bold', fontFamily: 'Optima' }}>
            <Input
              fluid
              name="email"
              value={values.email}
              error={touched.email && !!errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={capitalizeFirstLetter(translate('email', language))}
            />
          </InputWithLabel>
          <ButtonContainer>
            <Button style={{ width: 163, marginTop: 20, fontFamily: 'Optima' }} type="submit">
              confirm
            </Button>
          </ButtonContainer>
        </Container>
      )}
    </Formik>
  );
};

export default ForgotPassword;
