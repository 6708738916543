import React from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from '../../styles/theme';

const ItemButton = styled.div`
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  padding: 10px 0px;
  ${({ isLast }) => {
    if (!isLast) {
      return css`
        border-bottom: 1px solid ${theme.colors.borderColor};
      `;
    }
    return null;
  }};
`;

const Item = ({ item, isLast, onItemClick, disabled, priceFontSize = 15 }) => {
  return (
    <ItemButton isLast={isLast} onClick={onItemClick} disabled={disabled}>
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex'
        }}
      >
        <div
          style={{
            flex: 0.9,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'colum'
          }}
        >
          {item.name !== null && item.name !== '' && (
            <span style={{ fontFamily: 'optima-bold', fontSize: 15, width: '100%', textAlign: 'left' }}>{item.name}</span>
          )}

          {item.description !== '' && item.description !== null && (
            <span style={{ fontFamily: 'optima-regular', fontSize: 15, width: '100%', paddingTop: 5, textAlign: 'left' }}>{item.description}</span>
          )}

          {item?.secondaryName !== null && item?.secondaryName !== '' && (
            <span
              style={{ fontFamily: 'optima-bold', fontSize: 15, width: '100%', paddingTop: 5, color: theme.colors.tertiaryColor, textAlign: 'left' }}
            >
              {item.secondaryName}
            </span>
          )}

          {item?.secondaryDescription !== null && item?.secondaryDescription !== '' && (
            <span
              style={{
                fontFamily: 'optima-regular',
                fontSize: 15,
                width: '100%',
                paddingTop: 5,
                color: theme.colors.tertiaryColor,
                textAlign: 'left'
              }}
            >
              {item.secondaryDescription}
            </span>
          )}
        </div>
        {!Number.isNaN(item?.price) && <span style={{ fontFamily: 'optima-medium', fontSize: priceFontSize }}>{item?.price} €</span>}
      </div>
    </ItemButton>
  );
};

export default Item;
