import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { TranslateTextComponent } from '../../translator';
import { AuthContext } from '../../contexts/AuthContext';

const WelcomeText = styled(TranslateTextComponent).attrs({ capitalize: true })`
  font-family: optima-regular;
  font-size: 25px;
  color: ${({ theme }) => theme.colors.primaryColor};
`;

const Text = styled.span`
  font-family: optima-bold;
  font-size: 25px;
  color: ${({ theme }) => theme.colors.primaryColor};
`;

const WelcomeHeader = ({ containerStyle }) => {
  const authContext = useContext(AuthContext);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', ...containerStyle }}>
      <div style={{ paddingBottom: 3 }}>
        <WelcomeText>welcome</WelcomeText>
        <Text>,&nbsp;</Text>
      </div>
      <Text>{authContext?.user?.firstName}</Text>
    </div>
  );
};

export default WelcomeHeader;
