import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { Icon } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from 'react-router-dom';
import useCustomQuery from '../hooks/useCustomQuery';
import { AuthContext } from '../contexts/AuthContext';
import { GET_STAFF_USERS_SMART_ORDERS } from '../graphql/orders/queries';
import { ROUTE_SERVICE_DETAIL } from '../router/routes';
import { TranslateTextComponent } from '../translator';
import SUIContainer from '../components/shared/Container';
import SearchBar from '../components/shared/SearchBar';
import MultipleFilters from '../components/shared/MultipleFilters';
import Spinner from '../components/shared/Spinner';
import Card from '../components/shared/Card';

const Container = styled(SUIContainer)`
  position: relative;
  margin-top: 12px;
  overflow: hidden;
  height: 100%;

  & > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const Title = styled(TranslateTextComponent).attrs({ capitalize: true })`
  display: inline-block;
  font-family: optima-bold;
  font-size: 25px;
  margin-bottom: 10px;
  margin-right: 10px;
`;

const ReservationNumbers = styled.span`
  display: inline-block;
  font-family: optima-bold;
  font-size: 25px;
  margin-bottom: 10px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CardsContainer = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const ServicesListContainer = () => {
  const { appOutletUUID } = useContext(AuthContext);
  const history = useHistory();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    keyword: '',
    date: null,
    guestUUID: null,
    fbOutletUUID: appOutletUUID,
    fbServiceUUID: null,
    pageSize: 10,
    page: 1,
    orderType: 'DESC',
    type: 'SERVICE'
  });

  // Queries
  const getStaffUserSmartOrders = useCustomQuery(GET_STAFF_USERS_SMART_ORDERS, { variables: { ...filters } });

  const onFilterChange = ({ key, value }) => {
    switch (key) {
      case 'keyword':
        setFilters((prev) => ({ ...prev, keyword: value }));
        getStaffUserSmartOrders.refetch({ ...filters, keyword: value }).then(({ data }) => {
          setServices([...(data?.getStaffUserSmartOrders?.data ?? [])]);
        });
        break;
      case 'apply-filters':
        if (!value.fbOutletUUID) {
          getStaffUserSmartOrders.refetch({ ...value, fbOutletUUID: appOutletUUID }).then(({ data }) => {
            setServices([...(data?.getStaffUserSmartOrders?.data ?? [])]);
          });
          setFilters({ ...value, fbOutletUUID: appOutletUUID });
        } else {
          getStaffUserSmartOrders.refetch({ ...value }).then(({ data }) => {
            setServices([...(data?.getStaffUserSmartOrders?.data ?? [])]);
          });
          setFilters({ ...value });
        }
        setShowFilters(false);
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <Title>services</Title>
      {!getStaffUserSmartOrders.loading && services.length > 0 && (
        <ReservationNumbers>{getStaffUserSmartOrders.data?.getStaffUserSmartOrders?.totalCount}</ReservationNumbers>
      )}
      <FiltersContainer>
        <SearchBar
          fluid
          placeholder="search-here"
          containerStyle={{ width: '100%', marginRight: 10 }}
          loading={getStaffUserSmartOrders.loading}
          open={false}
          onChange={(e, { value }) => onFilterChange({ key: 'keyword', value })}
        />
        <Icon name="options" style={{ cursor: 'pointer', fontSize: '1.5em' }} onClick={() => setShowFilters(true)} />
      </FiltersContainer>
      <MultipleFilters showFilters={showFilters} initialFilters={filters} onFilterChange={onFilterChange} />
      {getStaffUserSmartOrders.loading && services.length <= 0 ? (
        <Spinner />
      ) : (
        !showFilters && (
          <InfiniteScroll
            pageStart={1}
            hasMore={services.length < getStaffUserSmartOrders.data?.getStaffUserSmartOrders?.totalCount && !loading}
            loadMore={(page) => {
              setLoading(true);
              return getStaffUserSmartOrders.refetch({ ...filters, page }).then(({ data }) => {
                setLoading(false);
                setServices((prev) => [...prev, ...(data?.getStaffUserSmartOrders?.data ?? [])]);
              });
            }}
            loader={<Spinner key={0} />}
          >
            <CardsContainer>
              {services?.map((s) => (
                <Card
                  key={s.uuid}
                  data={[
                    { left: { element: `Order #${s.orderNumber}` }, right: { element: s.type } },
                    {
                      left: { element: s.profile?.firstName ? `${s.profile?.firstName ?? ''} ${s.profile?.lastName ?? ''}` : s.clientName ?? '' },
                      right: { element: s.guestRoom ? `Room: ${s.guestRoom ?? ''}` : '' }
                    },
                    { left: { element: s.fbOutlet?.name, style: { fontFamily: 'optima-bold' } }, right: { element: s.date } }
                  ]}
                  onClick={() => history.push(ROUTE_SERVICE_DETAIL.replace(':uuid', s.uuid))}
                />
              ))}
            </CardsContainer>
          </InfiniteScroll>
        )
      )}
    </Container>
  );
};

export default ServicesListContainer;
