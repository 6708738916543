import React, { useContext } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { NotificationContext } from '../contexts/NotificationContext';
import { ModalContext } from '../contexts/ModalContext';
import { BookContext } from '../contexts/BookContext';
import { ServiceCartContext } from '../contexts/ServiceCartContext';
import BookFbReservationCheckOutContent from '../components/reservations/BookFbReservationCheckOutContent';
import BookServicesCheckOutContent from '../components/reservations/BookServicesCheckOutContent';
import FooterButton from '../components/shared/FooterButton';
import { ROUTE_HOME, ROUTE_BOOK_CHECKOUT_FB_RESERVATION, ROUTE_BOOK_CHECKOUT_SERVICES } from '../router/routes';
import { getApolloErrors } from '../apollo/ApolloProvider';

const Container = styled.div``;

const BookCheckOutContainer = () => {
  const history = useHistory();
  const { showNotification } = useContext(NotificationContext);
  const modalContext = useContext(ModalContext);
  const { booking, isSubmitting: isSubmittingFbReservation, onSubmit: onSubmitFbReservation, resetBook } = useContext(BookContext);
  const { serviceCart, isSubmitting: isSubmittingServices, onSubmit: onSubmitServices, checkAvailability, resetServiceCart } = useContext(
    ServiceCartContext
  );

  return (
    <Container>
      <Switch>
        <Route path={ROUTE_BOOK_CHECKOUT_FB_RESERVATION}>
          <BookFbReservationCheckOutContent />
          <FooterButton
            text="confirm"
            disabled={
              !booking?.date ||
              booking?.fbGuestList?.adults?.length <= 0 ||
              !booking?.fbService ||
              (!booking?.profile && !booking?.newProfile?.firstName && !booking?.profile?.lastName) ||
              isSubmittingFbReservation
            }
            onClick={() => {
              onSubmitFbReservation()
                .then(() => {
                  showNotification({ message: 'booking-confirmed' });
                  history.push(ROUTE_HOME);
                  resetBook();
                })
                .catch((err) => {
                  console.log('err', err);
                  modalContext.openModal({
                    title: 'error',
                    text: getApolloErrors(err)?.[0]?.toLowerCase().replace(/\s/g, '-') ?? '',
                    buttons: [{ label: 'close-modal', onClick: () => modalContext.closeModal() }]
                  });
                });
            }}
          />
        </Route>
        <Route path={ROUTE_BOOK_CHECKOUT_SERVICES}>
          <BookServicesCheckOutContent />
          <FooterButton
            text="confirm"
            disabled={
              !serviceCart?.date ||
              serviceCart?.services?.length <= 0 ||
              (!serviceCart?.profile && !serviceCart?.newProfile?.firstName && !serviceCart?.profile?.lastName) ||
              isSubmittingServices
            }
            onClick={() => {
              onSubmitServices()
                .then((res) => {
                  if (!res?.data?.createStaffProfileSmartServiceOrder?.isFullAvailable) {
                    modalContext.openModal({
                      title: 'error',
                      text: 'services-no-more-available'
                    });
                    checkAvailability(res.data.createStaffProfileSmartServiceOrder.availability);
                  } else {
                    showNotification({ message: 'booking-confirmed' });
                    history.push(ROUTE_HOME);
                    resetServiceCart();
                  }
                })
                .catch((err) => {
                  console.log('err', err);
                  modalContext.openModal({
                    title: 'error',
                    text: getApolloErrors(err)?.[0]?.toLowerCase().replace(/\s/g, '-') ?? '',
                    buttons: [{ label: 'close-modal', onClick: () => modalContext.closeModal() }]
                  });
                });
            }}
          />
        </Route>
      </Switch>
    </Container>
  );
};

export default BookCheckOutContainer;
