import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import SideDrawerContext from '../../contexts/SideDrawerContext';
import Wrapper from '../shared/Wrapper';
import Backdrop from '../shared/Backdrop';
import SideDrawer from './side-drawer/SideDrawer';
import Header from './header/Header';
import { AuthContext } from '../../contexts/AuthContext';

const StyledWrapper = styled(Wrapper).attrs({
  style: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  }
})``;

const Content = styled(Wrapper).attrs({
  style: {
    flex: 1
  }
})``;

const Layout = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext);

  const [isSideDrawerOpened, setIsSideDrawerOpened] = useState(false);

  const toggleSideDrawer = () => {
    setIsSideDrawerOpened((prev) => !prev);
  };

  const closeSideDrawer = () => {
    setIsSideDrawerOpened(false);
  };

  return (
    <StyledWrapper>
      {isLoggedIn() ? (
        <SideDrawerContext.Provider value={{ isSideDrawerOpened, toggleSideDrawer, closeSideDrawer }}>
          <Backdrop />
          <Header />
          <SideDrawer />
          <Content className="main-content-container">{children}</Content>
          {/* <Footer /> */}
        </SideDrawerContext.Provider>
      ) : (
        <Content>{children}</Content>
      )}
    </StyledWrapper>
  );
};

export default Layout;
