/* eslint-disable camelcase */
import person from './adults.svg';
import child_friendly from './infants.svg';
import child_care from './kids.svg';

export default {
  person,
  child_friendly,
  child_care
};
