import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import SideDrawerContext from '../../../contexts/SideDrawerContext';
import { ReactComponent as DrawerButtonIcon } from '../../../assets/images/header/drawer-button.svg';

const Container = styled.div`
  cursor: pointer;
`;

const DrawerButton = () => {
  const { toggleSideDrawer } = useContext(SideDrawerContext);

  return (
    <Container id="drawer-button" tabIndex="0" onClick={toggleSideDrawer}>
      <DrawerButtonIcon />
    </Container>
  );
};

export default DrawerButton;
