import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import CartInfoContent from '../components/reservations/CartInfoContent';
import FooterButton from '../components/shared/FooterButton';
import Container from '../components/shared/Container';
import { ROUTE_BOOK_CHECKOUT_SERVICES } from '../router/routes';
import { ServiceCartContext } from '../contexts/ServiceCartContext';

const CartInfoContainer = () => {
  const history = useHistory();
  const { serviceCart } = useContext(ServiceCartContext);

  return (
    <div>
      <Container style={{ paddingTop: 20, paddingBottom: 20 }}>
        <CartInfoContent />
      </Container>
      <FooterButton
        text="confirm"
        onClick={() => history.push(ROUTE_BOOK_CHECKOUT_SERVICES)}
        disabled={serviceCart?.services?.length <= 0 || !serviceCart?.fbOutlet}
      />
    </div>
  );
};

export default CartInfoContainer;
