/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';

export const GUEST_SIGN_UP = gql`
  mutation guestSignUp($signUpInput: SignUpInputDto!) {
    guestSignUp(signUpInput: $signUpInput) {
      id
      uuid
      username
      firstName
      lastName
      email
      avatar
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      id
    }
  }
`;

export const EDIT_PROFILE_INFO = gql`
  mutation editProfileInfo($editProfileData: EditSmartProfileDto!) {
    editProfileInfo(editProfileData: $editProfileData) {
      firstName
      lastName
      dateOfBirth
      placeOfBirth
      phone
    }
  }
`;
