/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';
import { FB_RESERVATION_FRAGMENT, FB_OUTLETS_FRAGMENT, PROFILE_PHYSICAL_CATEGORIES_FRAGMENT } from '../fragments';

export const GET_FB_RESERVATIONS = gql`
  query getFbReservations(
    $fbOutletUUID: String
    $fbServiceUUID: String
    $guestUUID: String
    $status: FbReservationStatuses
    $date: String
    $time: String
    $keyword: String
    $page: Int
    $pageSize: Int
    $orderBy: String
    $orderType: String
  ) {
    getFbReservations(
      fbOutletUUID: $fbOutletUUID
      fbServiceUUID: $fbServiceUUID
      guestUUID: $guestUUID
      status: $status
      date: $date
      time: $time
      keyword: $keyword
      page: $page
      pageSize: $pageSize
      orderBy: $orderBy
      orderType: $orderType
    ) {
      totalCount
      data {
        ...fbReservationFragment
      }
    }
  }
  ${FB_RESERVATION_FRAGMENT}
`;

export const GET_FB_RESERVATION = gql`
  query getFbReservation($fbReservationUUID: String!) {
    getFbReservation(fbReservationUUID: $fbReservationUUID) {
      ...fbReservationFragment
    }
  }
  ${FB_RESERVATION_FRAGMENT}
`;

export const GET_FB_RESERVATIONS_SUMMARY = gql`
  query getFbReservationsSummary($date: CustomDate!, $toDate: CustomDate!) {
    getFbReservationsSummary(date: $date, toDate: $toDate) {
      outlet {
        ...fbOutletsFragment
      }
      fbServices {
        totalPax
        fbService {
          ...fbServicesFragment
        }
        profilePhysicalCategories {
          totalPax
          profilePhysicalCategory {
            ...profilePhysicalCategoriesFragment
          }
        }
      }
    }
  }
  ${FB_OUTLETS_FRAGMENT}
  ${PROFILE_PHYSICAL_CATEGORIES_FRAGMENT}
`;
