import React from 'react';
import styled from 'styled-components/macro';

const ImageCardButton = styled.div`
  height: 80px;
  cursor: pointer;
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
`;

const Image = styled.div`
  background: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-color: rgba(0, 0, 0, 0.16);
  flex: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: saturation;
  padding: 10px;
`;

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageCard = ({ footer, backgroundImage, text, subText, cardStyle, textStyle, onClick }) => {
  return (
    <ImageCardButton style={cardStyle} onClick={onClick}>
      <Image backgroundImage={backgroundImage}>
        <TextContainer style={textStyle}>
          {text && <span style={{ fontFamily: 'optima-bold', fontSize: 20, color: 'white' }}>{text}</span>}
          {subText}
        </TextContainer>
      </Image>
      {footer}
    </ImageCardButton>
  );
};

export default ImageCard;
