import { gql } from 'apollo-boost';
import { TICKET_FRAGMENT } from '../fragments';

export const GET_TICKET_TYPES = gql`
  query getTicketTypes($orderBy: String, $orderType: String, $page: Int, $pageSize: Int) {
    getTicketTypes(orderBy: $orderBy, orderType: $orderType, page: $page, pageSize: $pageSize) {
      totalCount
      data {
        id
        uuid
        name
        tickets {
          id
          uuid
          status
          priority
        }
      }
    }
  }
`;

export const GET_TICKETS = gql`
  query getTickets(
    $orderBy: String
    $orderType: String
    $page: Int
    $pageSize: Int
    $buildingUUID: String
    $ticketTypeUUID: String
    $priority: PriorityLevels
    $status: GenericStatuses
    $isRequestedByGuest: Boolean
    $startDate: String
    $endDate: String
    $keyword: String
    $createdByUserUUID: String
  ) {
    getTickets(
      orderBy: $orderBy
      orderType: $orderType
      page: $page
      pageSize: $pageSize
      buildingUUID: $buildingUUID
      ticketTypeUUID: $ticketTypeUUID
      priority: $priority
      status: $status
      isRequestedByGuest: $isRequestedByGuest
      startDate: $startDate
      endDate: $endDate
      keyword: $keyword
      createdByUserUUID: $createdByUserUUID
    ) {
      totalCount
      data {
        ...ticketFragment
      }
    }
  }
  ${TICKET_FRAGMENT}
`;

export const GET_TICKET = gql`
  query getTicket($ticketUUID: String!) {
    getTicket(ticketUUID: $ticketUUID) {
      ...ticketFragment
    }
  }
  ${TICKET_FRAGMENT}
`;
