import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import { Dropdown, Input, Image, Icon } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { debounce } from 'lodash';
import FooterButton from '../components/shared/FooterButton';
import { theme } from '../styles/theme';
import { capitalizeFirstLetter, translate, TranslateTextComponent } from '../translator';
import { LanguageContext } from '../contexts/LanguageContext';
import { ModalContext } from '../contexts/ModalContext';
import { SAVE_COMPLAINT } from '../graphql/complaints/mutations';
import useCustomQuery from '../hooks/useCustomQuery';
import { GET_COMPLAINT, GET_COMPLAINT_TYPES } from '../graphql/complaints/queries';
import { GET_GUEST_PROFILES } from '../graphql/profiles/queries';
import { GET_ROOMS } from '../graphql/roombuilding/queries';
import { ROUTE_COMPLAINTS } from '../router/routes';
import InputWithLabel from '../components/shared/InputWithLabel';
import SearchBar from '../components/shared/SearchBar';
import Container from '../components/shared/Container';
import { getApolloErrors } from '../apollo/ApolloProvider';
import { PRIORITY, STATUS } from '../constants/genericConstants';
import Selector from '../components/complaints/Selector';
import toBase64 from '../utils/toBase64';
import Button from '../components/shared/Button';
import Spinner from '../components/shared/Spinner';

const ContainerForm = styled.form`
  height: 100%;
  font-family: 'Optima';
  color: ${({ theme: { colors } }) => colors.primaryColor};
  margin: 20px 0px;
`;

const PhotoContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`;

const DropdownContainer = styled.div`
  margin-top: 15px;
`;

const ComplaintImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: 10px;
  position: relative;
`;

const ComplaintContainer = () => {
  const history = useHistory();
  const { uuid } = useParams();

  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);

  // States
  const [selectedPriorityValue, setSelectedPriorityValue] = useState(null);
  const [selectedStatusValue, setSelectedStatusValue] = useState(null);
  const [profileSearchValue, setProfileSearchValue] = useState('');
  const [roomSearchValue, setRoomSearchValue] = useState('');
  const [isSavingComplaint, setIsSavingComplaint] = useState(false);
  const [loadingGuests, setLoadingGuets] = useState(false);
  const [guestProfiles, setGuestProfiles] = useState([]);

  // Mutations
  const [saveComplaint, { loading: saveComplaintLoading }] = useMutation(SAVE_COMPLAINT);

  // Queries
  const getComplaintTypes = useCustomQuery(GET_COMPLAINT_TYPES, { variables: { orderType: 'ASC', orderBy: 'name' } });
  const getGuestProfiles = useCustomQuery(GET_GUEST_PROFILES, { skip: true });
  const getRooms = useCustomQuery(GET_ROOMS, { variables: { page: 1, pageSize: 500 }, skip: !roomSearchValue });

  const getComplaintData = useCustomQuery(GET_COMPLAINT, {
    variables: { complaintUUID: uuid },
    onCompleted: ({ getComplaint }) => {
      setProfileSearchValue(`${getComplaint?.profile?.firstName} ${getComplaint?.profile?.lastName}` ?? '');
      setRoomSearchValue(`${getComplaint?.room?.number} - ${getComplaint?.room?.name}` ?? '');
      setSelectedPriorityValue(getComplaint?.priority);
      setSelectedStatusValue(getComplaint?.status);
    },
    skip: !uuid
  });

  const complaintsTypes = getComplaintTypes?.data?.getComplaintTypes?.data ?? null;
  const rooms = getRooms.data?.getRooms?.data ?? null;
  const complaintData = getComplaintData?.data?.getComplaint ?? null;

  const validationSchema = Yup.object().shape({
    guestProfile: Yup.string(),
    complaintType: Yup.string().required(capitalizeFirstLetter(translate('required', language))),
    room: Yup.string().required(capitalizeFirstLetter(translate('required', language))),
    description: Yup.string().required(capitalizeFirstLetter(translate('required', language))),
    actionTaken: Yup.string().nullable(),
    followUp: Yup.string().nullable(),
    feedbackOnClose: Yup.string().nullable(),
    cost: Yup.number().nullable()
  });

  const reset = () => {
    setSelectedPriorityValue(null);
    setSelectedStatusValue(null);
    setProfileSearchValue('');
    setRoomSearchValue('');
    setIsSavingComplaint(false);
  };

  const onSearchCustomer = useCallback(
    debounce((value) => {
      if (value.length >= 2) {
        getGuestProfiles
          .refetch({ keyword: value })
          .then(({ data }) => {
            setLoadingGuets(false);
            if (data?.getGuestProfiles?.data?.length > 0) setGuestProfiles([...data.getGuestProfiles.data]);
            else setGuestProfiles([]);
          })
          .catch(() => setLoadingGuets(false));
      }
    }, 500),
    []
  );
  return (
    <Formik
      initialValues={{
        guestProfile: complaintData?.profile?.uuid ?? '',
        complaintType: complaintData?.complaintType?.uuid ?? '',
        room: complaintData?.room?.uuid ?? '',
        description: complaintData?.description ?? '',
        actionTaken: complaintData?.actionTaken ?? '',
        followUp: complaintData?.followUp ?? '',
        feedbackOnClose: complaintData?.feedbackOnClose ?? '',
        cost: complaintData?.cost ?? '',
        image: complaintData?.picture !== '' ? complaintData?.picture : null ?? ''
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        saveComplaint({
          variables: {
            complaintData: {
              description: values?.description,
              priority: selectedPriorityValue,
              status: selectedStatusValue,
              actionTaken: values.actionTaken,
              followUp: values.followUp,
              feedbackOnClose: values.feedbackOnClose,
              cost: Number(values.cost),
              pictureData: complaintData?.picture === values.image ? '' : values.image
            },
            roomUUID: values.room,
            profileUUID: values.guestProfile,
            complaintTypeUUID: values.complaintType,
            complaintUUID: complaintData?.uuid ?? null
          }
        })
          .then(() => {
            modalContext.openModal({
              title: uuid ? 'edit-complaint' : 'new-complaint',
              text: uuid ? (
                <TranslateTextComponent capitalize>complaint-successfully-edited</TranslateTextComponent>
              ) : (
                <TranslateTextComponent capitalize>new-complaint-successfully-created</TranslateTextComponent>
              ),
              buttons: [
                {
                  label: 'close-modal',
                  onClick: () => {
                    modalContext.closeModal();
                    history.push(ROUTE_COMPLAINTS);
                    reset();
                  }
                }
              ]
            });
          })
          .error((err) => {
            modalContext.openModal({
              title: 'new-complaint-failed-title',
              text: getApolloErrors(err)?.[0]?.toLowerCase().replace(/\s/g, '-') ?? '',
              buttons: [{ label: 'close-modal', onClick: () => modalContext.closeModal() }]
            });
          });

        setSubmitting(false);
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, touched, setFieldValue, setValues, dirty }) => (
        <Container>
          <ContainerForm onSubmit={handleSubmit}>
            <TranslateTextComponent capitalize style={{ fontSize: 25, fontWeight: 'bold' }}>
              {uuid ? `Complaint #${complaintData?.id}` : 'new-complaint'}
            </TranslateTextComponent>
            <InputWithLabel
              label="select-profile"
              labelStyle={{ fontSize: 20, fontFamily: 'optima-bold', marginTop: 15 }}
              error={touched.guestProfile && errors.guestProfile}
            >
              <SearchBar
                fluid
                placeholder="profile"
                noShadow
                containerStyle={{ width: '100%' }}
                results={
                  guestProfiles?.map((p) => ({
                    key: p.uuid,
                    title: `${p.firstName} ${p.lastName} ${p?.currentCheckin?.room?.number ? ` - ${p?.currentCheckin?.room?.number}` : ''}`,
                    uuid: p.uuid,
                    currentcheckin: p?.currentCheckin
                  })) ?? []
                }
                loading={getGuestProfiles.loading || loadingGuests}
                value={profileSearchValue}
                onChange={({ target: { value } }) => {
                  if (!value || value.length < 2) {
                    setLoadingGuets(false);
                  } else {
                    setLoadingGuets(true);
                    onSearchCustomer(value);
                  }
                  setProfileSearchValue(value);
                }}
                onResultSelect={(e, { result }) => {
                  if (result?.currentcheckin) {
                    setRoomSearchValue(`${result.currentcheckin.room.number} - ${result.currentcheckin.room.name}`);
                  } else {
                    setRoomSearchValue('');
                  }
                  setProfileSearchValue(result.title);
                  setValues((prev) => ({ ...prev, room: result?.currentcheckin?.room?.uuid, guestProfile: result.uuid }));
                }}
              />
            </InputWithLabel>
            <DropdownContainer>
              <InputWithLabel
                label="complaint-type"
                labelStyle={{ fontSize: 20, fontFamily: 'optima-bold' }}
                error={touched.complaintType && errors.complaintType}
              >
                <Dropdown
                  fluid
                  selection
                  search
                  value={complaintData?.complaintType?.uuid}
                  options={
                    complaintsTypes?.map((complaint, key) => ({
                      key,
                      value: complaint.uuid,
                      text: complaint.name
                    })) ?? []
                  }
                  onChange={(e, data) => setFieldValue('complaintType', data?.value)}
                />
              </InputWithLabel>
            </DropdownContainer>
            <InputWithLabel
              label="select-room"
              labelStyle={{ fontSize: 20, fontFamily: 'optima-bold', marginTop: 20 }}
              error={touched.room && errors.room}
            >
              <SearchBar
                fluid
                placeholder="room"
                noShadow
                containerStyle={{ width: '100%' }}
                results={
                  rooms
                    ?.sort((a, b) => a.number - b.number)
                    ?.filter(
                      (room) =>
                        room?.number.toLowerCase().includes(roomSearchValue.toLowerCase()) ||
                        room?.name.toLowerCase().includes(roomSearchValue?.replace(/\s/g, '')?.toLowerCase())
                    )
                    ?.map((room) => ({
                      key: room.uuid,
                      title: `${room.number} - ${room.name}  `,
                      ...room
                    })) ?? []
                }
                loading={getRooms.loading}
                value={roomSearchValue || ''}
                onResultSelect={(e, { result }) => {
                  setFieldValue('room', result?.uuid);
                  setRoomSearchValue(result.title);
                }}
                onChange={(e, { value }) => {
                  if (!value) {
                    setFieldValue('room', null);
                    setRoomSearchValue('');
                  } else {
                    setRoomSearchValue(value);
                  }
                }}
              />
            </InputWithLabel>
            <Selector
              key="prioritySelector"
              name="priority"
              selectedItem={selectedPriorityValue}
              containerStyle={{ marginTop: 15 }}
              options={PRIORITY}
              onChange={(value) => setSelectedPriorityValue(value)}
              error={!selectedPriorityValue && isSavingComplaint ? 'required' : null}
            />
            <Selector
              key="StatusSelector"
              name="status"
              selectedItem={selectedStatusValue}
              containerStyle={{ marginTop: 15 }}
              options={STATUS}
              onChange={(value) => setSelectedStatusValue(value)}
              error={!selectedStatusValue && isSavingComplaint ? 'required' : null}
            />
            <InputWithLabel
              label="description"
              error={touched.description && errors.description}
              labelStyle={{ fontWeight: 'bold', fontFamily: 'Optima', fontSize: 20 }}
              containerStyle={{ marginTop: 15 }}
            >
              <Input
                fluid
                name="description"
                style={{ minHeight: 60 }}
                value={values.description ?? ''}
                error={touched.description && !!errors.description}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={capitalizeFirstLetter(translate('description', language))}
              />
            </InputWithLabel>
            <InputWithLabel
              label="action-taken"
              error={touched.actionTaken && errors.actionTaken}
              labelStyle={{ fontWeight: 'bold', fontFamily: 'Optima', fontSize: 20 }}
              containerStyle={{ marginTop: 15 }}
            >
              <Input
                fluid
                name="actionTaken"
                style={{ minHeight: 60 }}
                value={values.actionTaken || ''}
                error={touched.actionTaken && !!errors.actionTaken}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={capitalizeFirstLetter(translate('action-taken', language))}
              />
            </InputWithLabel>
            <InputWithLabel
              label="follow-up"
              error={touched.followUp && errors.followUp}
              labelStyle={{ fontWeight: 'bold', fontFamily: 'Optima', fontSize: 20 }}
              containerStyle={{ marginTop: 15 }}
            >
              <Input
                fluid
                name="followUp"
                style={{ minHeight: 60 }}
                value={values.followUp || ''}
                error={touched.followUp && !!errors.followUp}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={capitalizeFirstLetter(translate('follow-up', language))}
              />
            </InputWithLabel>
            <InputWithLabel
              label="feedback-closing"
              error={touched.feedbackOnClose && errors.feedbackOnClose}
              labelStyle={{ fontWeight: 'bold', fontFamily: 'Optima', fontSize: 20 }}
              containerStyle={{ marginTop: 15 }}
            >
              <Input
                fluid
                name="feedbackOnClose"
                style={{ minHeight: 60 }}
                value={values.feedbackOnClose || ''}
                error={touched.feedbackOnClose && !!errors.feedbackOnClose}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={capitalizeFirstLetter(translate('feedback-closing', language))}
              />
            </InputWithLabel>

            <InputWithLabel
              label="cost"
              error={touched.cost && errors.cost}
              labelStyle={{ fontWeight: 'bold', fontFamily: 'Optima', fontSize: 20 }}
              containerStyle={{ marginTop: 15 }}
            >
              <Input
                fluid
                name="cost"
                value={values.cost ?? ''}
                error={touched.cost && !!errors.cost}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={capitalizeFirstLetter(translate('cost', language))}
              />
            </InputWithLabel>
            <PhotoContainer>
              <TranslateTextComponent capitalize style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 5 }}>
                photo
              </TranslateTextComponent>

              <Input
                id="input"
                type="file"
                style={{ display: 'none' }}
                name="complaintImage"
                onChange={async (e) => {
                  const { files } = e.target;
                  if (files) {
                    const dataBase64 = await toBase64(files[0]);
                    setFieldValue('image', dataBase64);
                  }
                }}
              />
              <Button
                text="click-to-upload"
                type="button"
                style={{
                  fontFamily: 'Optima',
                  marginRight: 0,
                  backgroundColor: 'white',
                  color: theme.colors.tertiaryColor,
                  border: `1px solid ${theme.colors.tertiaryColor}`
                }}
                onClick={() => document.getElementById('input').click()}
              />

              {values.image && (
                <ComplaintImage>
                  <Icon
                    name="times circle"
                    size="big"
                    style={{
                      position: 'absolute',
                      right: -17,
                      zIndex: 2,
                      color: theme.colors.errorColor,
                      cursor: 'pointer',
                      backgroundColor: 'white',
                      borderRadius: 15
                    }}
                    onClick={() => setFieldValue('image', null)}
                  />
                  <Image
                    src={uuid && !values.image?.includes('data') ? process.env.REACT_APP_SERVER_URL + values.image : values.image}
                    alt={capitalizeFirstLetter(translate('complaint-photo', language))}
                    height="auto"
                    width="auto"
                    style={{ marginTop: 10 }}
                  />
                </ComplaintImage>
              )}
            </PhotoContainer>

            <FooterButton
              type="submit"
              text="save"
              disabled={!values.complaintType || !values.description || !values.room || !selectedStatusValue || !selectedPriorityValue}
              onClick={() => {
                setIsSavingComplaint(true);
              }}
            />

            <FooterButton
              type="submit"
              text="save"
              disabled={
                saveComplaintLoading ||
                !dirty ||
                !values.complaintType ||
                !values.description ||
                !values.room ||
                !selectedStatusValue ||
                !selectedPriorityValue
              }
              onClick={handleSubmit}
            >
              {saveComplaintLoading && <Spinner size={15} />}
            </FooterButton>
          </ContainerForm>
        </Container>
      )}
    </Formik>
  );
};

export default ComplaintContainer;
