export const theme = {
  layout: {
    desktopWidth: '80%',
    mobileWidth: '95%'
  },
  header: {
    height: 90,
    sectionsHeight: 104
  },
  footer: {
    desktopHeight: 193,
    mobileHeight: 329
  },
  colors: {
    primaryColor: '#5c5c5c',
    secondaryColor: '#606060',
    tertiaryColor: '#888b8d',
    borderColor: '#ECEBEC',
    errorColor: '#CB2D24',
    labelColor: '#B5B5B5',
    disabledColor: '#888B8D59',
    radioCardBackgroundColor: '#FFCCCE59',
    textColor: '#474243',
    green: '#489D48',
    orange: '#F7A445',
    screenBackground: 'white',
    imageOpacity: '#909090',
    darkGrey: '#464243',
    disabledButtonColor: '#888B8D59'
  },
  mediaQueries: {
    mobileBreakpoint: '48rem',
    mobilePixel: 768,
    footerMinWidth: '50rem'
  },
  fonts: ['Roboto', 'sans-serif'],
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em'
  },
  headerHeight: 80,
  bannerHeight: 120,
  searchbarHeight: 35
};

export default theme;
