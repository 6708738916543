import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import useCustomQuery from '../hooks/useCustomQuery';
import { GET_SMART_OUTLET } from '../graphql/outlets/queries';
import BookRestaurantContent from '../components/reservations/BookRestaurantContent';
import Banner from '../components/shared/Banner';
import FixedContainer from '../components/shared/FixedContainer';
import Spinner from '../components/shared/Spinner';
import { getPrimaryImagePath } from '../utils/outlet';
import { ROUTE_BOOK_FB_RESERVATION, ROUTE_BOOK_SERVICES } from '../router/routes';
import BookServicesContent from '../components/reservations/BookServicesContent';

const BookContainer = () => {
  const { uuid } = useParams();

  const getSmartOutlet = useCustomQuery(GET_SMART_OUTLET, {
    variables: { outletUUID: uuid },
    fetchPolicy: 'network-only',
    skip: !uuid
  });

  return (
    <div>
      {getSmartOutlet?.loading ? (
        <Spinner containerStyle={{ paddingTop: 100 }} />
      ) : (
        <>
          <FixedContainer>
            <Banner
              text={getSmartOutlet?.data?.getSmartOutlet?.name ?? ''}
              background={`${process.env.REACT_APP_SERVER_URL}${getPrimaryImagePath(getSmartOutlet?.data?.getSmartOutlet?.images)}`}
            />
          </FixedContainer>
          <Switch>
            <Route path={ROUTE_BOOK_FB_RESERVATION}>
              <BookRestaurantContent outlet={getSmartOutlet?.data?.getSmartOutlet} />
            </Route>
            <Route path={ROUTE_BOOK_SERVICES}>
              <BookServicesContent outlet={getSmartOutlet?.data?.getSmartOutlet} />
            </Route>
          </Switch>
        </>
      )}
    </div>
  );
};

export default BookContainer;
