import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import useCustomQuery from '../hooks/useCustomQuery';
import { GET_SMART_OUTLETS } from '../graphql/outlets/queries';
import { OutletCategoriesContext } from '../contexts/OutletCategoriesContext';
import FixedContainer from '../components/shared/FixedContainer';
import OutletsList from '../components/outlets/OutletsList';
import Banner from '../components/shared/Banner';
import SearchBar from '../components/shared/SearchBar';
import Spinner from '../components/shared/Spinner';

const initialQueryFilters = {
  keyword: null
};

const OutletsContainer = () => {
  const { uuid } = useParams();
  const [queryFilters, setQueryFilters] = useState(initialQueryFilters);

  const getSmartOutlets = useCustomQuery(GET_SMART_OUTLETS, {
    variables: {
      ...queryFilters,
      outletCategoryUUID: uuid
    },
    fetchPolicy: 'network-only'
  });

  const updateQueryFilters = (key, value) => {
    setQueryFilters({ ...queryFilters, [key]: value });
  };
  const { getCategoryByUUID } = useContext(OutletCategoriesContext);
  const selectedCategory = getCategoryByUUID(uuid);

  return (
    <div>
      {getSmartOutlets?.loading ? (
        <Spinner containerStyle={{ paddingTop: 100 }} />
      ) : (
        <>
          <FixedContainer>
            <Banner text={selectedCategory?.name ?? ''} background={`${process.env.REACT_APP_SERVER_URL}${selectedCategory.imagePath}`} />
            <SearchBar placeholder="search-here" onChange={(e) => updateQueryFilters('keyword', e.target.value)} />
          </FixedContainer>
          <OutletsList outlets={getSmartOutlets?.data?.getSmartOutlets?.data} />
        </>
      )}
    </div>
  );
};

export default OutletsContainer;
