import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageContext';
import SettingButton from '../components/settings/SettingButton';
import BoldLabel from '../components/shared/BoldLabel';
import Container from '../components/shared/Container';
import { ROUTE_SETTINGS_LANGUAGES, ROUTE_SETTINGS_ACCOUNT, ROUTE_SETTINGS_LEGAL, ROUTE_SETTINGS_CHANGE_PASSWORD } from '../router/routes';

const SettingsContainer = () => {
  const history = useHistory();
  const { language } = useContext(LanguageContext);

  return (
    <Container style={{ paddingTop: 20 }}>
      <BoldLabel label="settings" />
      <div style={{ marginTop: 10 }}>
        <SettingButton text="language" onClick={() => history.push(ROUTE_SETTINGS_LANGUAGES)} rightText={language} />

        <SettingButton text="account-details" onClick={() => history.push(ROUTE_SETTINGS_ACCOUNT)} />

        <SettingButton text="legal" onClick={() => history.push(ROUTE_SETTINGS_LEGAL)} />

        <SettingButton text="change-password" onClick={() => history.push(ROUTE_SETTINGS_CHANGE_PASSWORD)} />
      </div>
    </Container>
  );
};

export default SettingsContainer;
