/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';

export const CREATE_STAFF_PROFILE_SMART_SERVICE_ORDER = gql`
  mutation createStaffProfileSmartServiceOrder(
    $notes: String
    $time: String
    $date: String!
    $items: [SmartServiceInputDto!]!
    $fbOutletUUID: String!
    $guestRoom: String!
    $profileUUID: String
    $createProfile: CreateIndividualProfileDto
  ) {
    createStaffProfileSmartServiceOrder(
      notes: $notes
      time: $time
      date: $date
      items: $items
      fbOutletUUID: $fbOutletUUID
      guestRoom: $guestRoom
      profileUUID: $profileUUID
      createProfile: $createProfile
    ) {
      uuid
      isFullAvailable
      availability {
        smartServiceUUID
        isAvailable
        maxQuantity
      }
    }
  }
`;
