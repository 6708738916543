import React from 'react';
import styled from 'styled-components/macro';

const Card = styled.div`
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.16);
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isSelected, theme }) => (isSelected ? theme.colors.primaryColor : 'white')};
  padding: 10px;
  display: flex;
  margin-top: 25px;
  align-items: center;
  cursor: pointer;
  @media (min-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    padding: 20px;
  }
`;

const StyledRadio = styled.div`
  height: 15px;
  width: 15px;
  border-width: ${({ isSelected }) => (isSelected ? 5 : 1)}px;
  border-color: ${({ theme }) => theme.colors.primaryColor};
  border-style: solid;
  border-radius: 50px;
  margin-right: 5px;
`;

const Text = styled.span`
  font-family: Optima;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
`;

const Line = styled.div`
  padding: 10px 0px;
  @media (min-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    padding: 15px 0px;
  }
`;

const RadioCard = ({
  isSelected,
  primaryColor,
  title,
  line1,
  line2,
  titleStyle,
  lineStyle,
  onClick,
  cardStyle,
  topButton,
  bottomButton,
  disabled
}) => {
  console.log('isSelected', isSelected);
  return (
    <Card disabled={disabled} onClick={onClick} isSelected={isSelected} style={{ ...cardStyle }}>
      <StyledRadio isSelected={isSelected} primaryColor={primaryColor} />
      <div style={{ flex: 1, justifyContent: 'center', marginLeft: 10 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Text style={{ ...titleStyle }} fontFamily={titleStyle?.fontFamily}>
            {title}
          </Text>
          {topButton}
        </div>
        {line1 && (
          <Line>
            <Text style={{ flexWrap: 'wrap', flex: 1, fontWeight: 400, ...lineStyle }} fontFamily={lineStyle?.fontFamily}>
              {line1}
            </Text>
          </Line>
        )}
        {line2 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Text style={{ flexWrap: 'wrap', flex: 1, fontWeight: 400, ...lineStyle }} fontFamily={lineStyle?.fontFamily}>
              {line2}
            </Text>
            {bottomButton}
          </div>
        )}
      </div>
    </Card>
  );
};

export default RadioCard;
