import React, { useState, createContext } from 'react';
import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const AuthContext = createContext();

const userData = localStorage.getItem('user');

export const AuthProvider = (props) => {
  const [user, setUser] = useState(userData ? JSON.parse(userData) : null);
  const [errorMessage, setErrorMessage] = useState(null);
  const isLoggedIn = () => user !== null && user.access_token !== null;

  const onSetUser = (data) => {
    setUser(data);
  };

  const login = (username, password) => {
    return axios
      .post(`${SERVER_URL}/graphql`, {
        operationName: null,
        variable: {},
        query: `{
          login(loginInput: {
            email: "${username}"
            password: "${password}"
          }) {
            access_token
            username
            id
            firstName
            lastName
            phoneNumber
            email
            outlets {
              id
              uuid
              name
              fiscalPrinterIpAddress
            }
            roles {
              uuid
              name
            }
          }
        }`
      })
      .then((res) => {
        const response = res && res.data;
        if (response.data?.login?.access_token) {
          setErrorMessage(null);
          localStorage.setItem('user', JSON.stringify(response.data.login));
          setUser(response.data.login);
        } else if (response?.errors) {
          setErrorMessage(response.errors);
        }
        return res;
      })
      .catch((error) => {
        console.log('auth error message', error.response.data);
        console.log('auth error message', error.response.data.error);
        setErrorMessage(error.response.data);
        return error;
      });
  };

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('appOutletUUID');
    localStorage.removeItem('appOutlet');
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        isLoggedIn,
        errorMessage,
        onSetUser
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export const withAuthContext = (Component) => (props) => (
  <AuthConsumer>{(providerProps) => <Component {...props} sessionContextProps={providerProps} />}</AuthConsumer>
);
