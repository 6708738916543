/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';

export const GET_SMART_SERVICES = gql`
  query getSmartServices($fbOutletUUID: String!, $page: Int, $pageSize: Int, $orderBy: String, $orderType: String, $date: String) {
    getSmartServices(fbOutletUUID: $fbOutletUUID, page: $page, pageSize: $pageSize, orderBy: $orderBy, orderType: $orderType, date: $date) {
      totalCount
      data {
        id
        uuid
        name
        description
        dateStart
        dateEnd
        isActive
        price
        dailyQuantity
        days
      }
    }
  }
`;

export const IS_SMART_SERVICE_AVAILABLE = gql`
  query isSmartServiceAvailable($smartServiceUUID: String!, $date: String!, $quantity: Int!) {
    isSmartServiceAvailable(smartServiceUUID: $smartServiceUUID, date: $date, quantity: $quantity) {
      isAvailable
      maxQuantity
    }
  }
`;
