import { createGlobalStyle } from 'styled-components';
import RobotoBlack from '../assets/fonts/Roboto-Black.ttf';
import RobotoBlackItalic from '../assets/fonts/Roboto-BlackItalic.ttf';
import RobotoBold from '../assets/fonts/Roboto-Bold.ttf';
import RobotoBoldCondensed from '../assets/fonts/Roboto-BoldCondensed.ttf';
import RobotoBoldCondensedItalic from '../assets/fonts/Roboto-BoldCondensedItalic.ttf';
import RobotoBoldItalic from '../assets/fonts/Roboto-BoldItalic.ttf';
import RobotoCondensed from '../assets/fonts/Roboto-Condensed.ttf';
import RobotoCondensedItalic from '../assets/fonts/Roboto-CondensedItalic.ttf';
import RobotoItalic from '../assets/fonts/Roboto-Italic.ttf';
import RobotoLight from '../assets/fonts/Roboto-Light.ttf';
import RobotoLightItalic from '../assets/fonts/Roboto-LightItalic.ttf';
import RobotoMedium from '../assets/fonts/Roboto-Medium.ttf';
import RobotoMediumItalic from '../assets/fonts/Roboto-MediumItalic.ttf';
import RobotoRegular from '../assets/fonts/Roboto-Regular.ttf';
import RobotoThin from '../assets/fonts/Roboto-Thin.ttf';
import RobotoThinItalic from '../assets/fonts/Roboto-ThinItalic.ttf';
import Optima from '../assets/fonts/Optima_Regular.ttf';
import OptimaBold from '../assets/fonts/Optima_Nova_B.ttf';
import OptimaItalic from '../assets/fonts/Optima_Italic.ttf';
import OptimaMedium from '../assets/fonts/Optima_Medium.ttf';
import { theme } from './theme';

export default createGlobalStyle`
    /* ROBOTO */
    @font-face {
      font-family: 'roboto-black';
      src: url(${RobotoBlack}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-black-italic';
      src: url(${RobotoBlackItalic}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-bold';
      src: url(${RobotoBold}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-bold-condensed';
      src: url(${RobotoBoldCondensed}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-condensed-italic';
      src: url(${RobotoBoldCondensedItalic}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-bold-italic';
      src: url(${RobotoBoldItalic}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-condensed';
      src: url(${RobotoCondensed}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-condensed-italic';
      src: url(${RobotoCondensedItalic}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-italic';
      src: url(${RobotoItalic}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-light';
      src: url(${RobotoLight}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-light-italic';
      src: url(${RobotoLightItalic}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-medium';
      src: url(${RobotoMedium}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-medium-italic';
      src: url(${RobotoMediumItalic}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-regular';
      src: url(${RobotoRegular}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-thin';
      src: url(${RobotoThin}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'roboto-thin-italic';
      src: url(${RobotoThinItalic}) format('truetype');
      font-style: normal;
    };

    /* OPTIMA */
    @font-face {
      font-family: 'optima';
      src: url(${Optima}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'optima-regular';
      src: url(${Optima}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'optima-bold';
      src: url(${OptimaBold}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'optima-medium';
      src: url(${OptimaMedium}) format('truetype');
      font-style: normal;
    };

    @font-face {
      font-family: 'optima-italic';
      src: url(${OptimaItalic}) format('truetype');
      font-style: normal;
    };

    html {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    };

    body {
      width: 100%;
      height: 100%;
      line-height: inherit;
      overflow: auto;
      min-width: 0;
      font-family: optima;
      color:${({ theme: { colors } }) => colors.primaryColor};
    };

    #root {
      height: 100%;

      & > div:first-child {
        display: flex;
        flex-flow: column;
        height: 100%;
      }
    }

    .main-content-container {
      flex: 1;
      margin-top: ${theme.headerHeight}px;
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    };

    .ui.container {
      @media only screen and (max-width: 767px) {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    };

    .ui.visible.bottom.overlay.sidebar.bottom-sheet {
      border-top-right-radius: 8px !important;
      border-top-left-radius: 8px !important;
    }

    /** Semantic Tab */
    .ui.secondary.pointing.menu .item {
      font-family: optima;
      font-size: 20px;
      text-transform: capitalize;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;

    }
    .ui.secondary.pointing.menu a.item:active{
        border-color: rgba(0, 0, 0, 0);
    }
    .ui.secondary.pointing.menu .active.item {
      border-color: ${theme.colors.primaryColor};
      color: ${theme.colors.primaryColor};
      &:hover{
        color: unset;
        border-color: unset;
      }
    }
    .ui.secondary.pointing.menu .inactive.item {
      border-color: ${theme.colors.secondaryColor};
      color: ${theme.colors.secondaryColor};
    }
    .full-width.ui.secondary.pointing.menu{
      margin-bottom: 0px;
    }
    .full-width.ui.secondary.pointing.menu .item {
      flex: 1;
      display:flex;
      justify-content:center;
    }

    .ui.fluid.input>input {
      border-radius: 0px;
    }
`;
