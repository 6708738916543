import React from 'react';
import { Container as SUIContainer } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import Container from './Container';
import { TranslateTextComponent } from '../../translator';

const Wrapper = styled(SUIContainer)`
  display: flex !important;
  align-items: center;
  width: 100%;
  height: ${({ theme }) => theme.bannerHeight}px;
  background: ${({ background }) => `url(${background})`}};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: saturation;
  background-color: #00000040;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Text = styled(TranslateTextComponent)`
  color: white;
  font-family: optima-bold;
  font-size: 25px;
  text-transform: capitalize;
`;

const Banner = ({ background, text }) => {
  return (
    <Wrapper fluid background={background}>
      <Container>
        <RowContainer>
          <Text bold>{text}</Text>
        </RowContainer>
      </Container>
    </Wrapper>
  );
};

export default Banner;
