import React, { useContext, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import useCustomQuery from '../../hooks/useCustomQuery';
import { GET_FB_MENU_CATEGORIES } from '../../graphql/outlets/queries';
import { LanguageContext } from '../../contexts/LanguageContext';
import { BookContext } from '../../contexts/BookContext';
import Tabs from '../shared/Tabs';
import InfoTab from './tabs/InfoTab';
import MenuTab from './tabs/MenuTab';
import FooterButton from '../shared/FooterButton';
import { capitalizeFirstLetter, translate } from '../../translator';
import { ROUTE_BOOK_FB_RESERVATION, ROUTE_BOOK_SERVICES, ROUTE_OUTLET, ROUTE_FB_OUTLET } from '../../router/routes';

const Container = styled.div`
  padding-top: ${({ theme }) => theme.bannerHeight}px;
`;

const OutletContent = ({ outlet }) => {
  const history = useHistory();
  const { resetBook } = useContext(BookContext);
  const { language } = useContext(LanguageContext);
  const [currentTab, setCurrentTab] = useState(0);

  const getMenuCategoriesRes = useCustomQuery(GET_FB_MENU_CATEGORIES, {
    variables: {
      fbOutletUUID: outlet?.uuid,
      orderBy: 'order',
      orderType: 'ASC'
    },
    fetchPolicy: 'network-only'
  });

  const tabs = [
    {
      menuItem: capitalizeFirstLetter(translate('info', language)),
      render: () => <InfoTab outlet={outlet ?? {}} />
    }
  ];

  if (outlet.isOnlineReservationAccepted && getMenuCategoriesRes?.data?.getFbMenuCategories?.data?.length > 0) {
    tabs.splice(1, 0, {
      menuItem: capitalizeFirstLetter(translate('menu', language)),
      render: () => <MenuTab fbOutletUUID={outlet?.uuid} menuCategories={getMenuCategoriesRes?.data?.getFbMenuCategories?.data} />
    });
  }
  return (
    <Container>
      <Tabs
        tabs={tabs}
        activeIndex={currentTab}
        onTabChange={(e, { activeIndex }) => {
          setCurrentTab(activeIndex);
        }}
        tabsStyle={{ flex: 1, display: 'flex', justifyContent: 'center' }}
        menu={{ secondary: true, pointing: true, className: 'full-width' }}
      />
      <Switch>
        <Route path={ROUTE_FB_OUTLET}>
          <FooterButton
            text="book-now"
            onClick={() => {
              resetBook();
              history.push(ROUTE_BOOK_FB_RESERVATION.replace(':uuid', outlet?.uuid));
            }}
          />
        </Route>
        <Route path={ROUTE_OUTLET}>
          <FooterButton
            text="book-now"
            onClick={() => {
              resetBook();
              history.push(ROUTE_BOOK_SERVICES.replace(':uuid', outlet?.uuid));
            }}
          />
        </Route>
      </Switch>
    </Container>
  );
};

export default OutletContent;
