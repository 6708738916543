import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ROUTE_RESERVATIONS_LIST, ROUTE_SERVICES_LIST } from '../../router/routes';
import { TranslateTextComponent } from '../../translator';

const ShowAllButton = styled.div`
  cursor: pointer;
`;

const PreviousReservations = () => {
  const history = useHistory();

  return (
    <div>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 15
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TranslateTextComponent capitalize style={{ fontFamily: 'optima-bold', fontSize: 25 }}>
              reservations
            </TranslateTextComponent>
          </div>

          <ShowAllButton onClick={() => history.push(ROUTE_RESERVATIONS_LIST)}>
            <TranslateTextComponent capitalize style={{ fontFamily: 'optima-medium', fontSize: 16 }}>
              show
            </TranslateTextComponent>
          </ShowAllButton>
        </div>
      </div>

      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 17
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TranslateTextComponent capitalize style={{ fontFamily: 'optima-bold', fontSize: 25 }}>
              services
            </TranslateTextComponent>
          </div>

          <ShowAllButton onClick={() => history.push(ROUTE_SERVICES_LIST)}>
            <TranslateTextComponent capitalize style={{ fontFamily: 'optima-medium', fontSize: 16 }}>
              show
            </TranslateTextComponent>
          </ShowAllButton>
        </div>
      </div>
    </div>
  );
};

export default PreviousReservations;
