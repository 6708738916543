import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import { TranslateTextComponent } from '../../../translator';
import { theme } from '../../../styles/theme';

const Container = styled.div`
  flex: 1;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: ${({ isLast }) => (isLast ? '0px' : `1px solid ${theme.colors.borderColor}`)};

  margin-bottom: ${({ isLast }) => (isLast ? '20px' : '0px')};
`;

const DaysRows = ({ outlet }) => {
  const currentSegment = outlet?.fbSegments?.find(
    (seg) =>
      moment(seg.startDate).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') &&
      moment(seg.endDate).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')
  );

  const getTimes = (index) => {
    const times = [];

    // eslint-disable-next-line no-unused-expressions
    currentSegment?.fbSegmentsServices?.forEach?.((segmentService) => {
      if (segmentService?.days?.includes(index) && segmentService?.fbService?.isActive) {
        times.push({
          startTime: segmentService.fbService.startTime,
          endTime: segmentService.fbService.endTime
        });
      }
    });

    return orderBy(times, ['startTime'], ['asc']);
  };

  return (
    <Container>
      {moment
        .weekdays()
        .slice(1)
        .map((weekday, index) => {
          return (
            <Row key={index}>
              <TranslateTextComponent style={{ fontFamily: 'optima-medium', fontSize: 15 }} capitalize>
                {weekday.toLowerCase()}
              </TranslateTextComponent>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {getTimes(index + 1).length <= 0 ? (
                  <TranslateTextComponent style={{ fontFamily: 'optima-medium', fontSize: 15, color: theme.colors.errorColor }} capitalize>
                    close
                  </TranslateTextComponent>
                ) : (
                  getTimes(index + 1).map((time, index) => (
                    <span key={index} style={{ fontFamily: 'optima-medium', fontSize: 15 }}>
                      {time.startTime} - {time.endTime}
                    </span>
                  ))
                )}
              </div>
            </Row>
          );
        })}
      <Row isLast>
        <TranslateTextComponent style={{ fontFamily: 'optima-medium', fontSize: 15 }} capitalize>
          {moment.weekdays()[0].toLowerCase()}
        </TranslateTextComponent>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {getTimes(0).length <= 0 ? (
            <TranslateTextComponent style={{ fontFamily: 'optima-medium', fontSize: 15, color: theme.colors.errorColor }} capitalize>
              close
            </TranslateTextComponent>
          ) : (
            getTimes(0).map((time, index) => (
              <span key={index} style={{ fontFamily: 'optima-medium', fontSize: 15 }}>
                {time.startTime} - {time.endTime}
              </span>
            ))
          )}
        </div>
      </Row>
    </Container>
  );
};

export default DaysRows;
