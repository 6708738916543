import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  width: 100%;
  z-index: 2;
`;

const FixedContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default FixedContainer;
