import React from 'react';
import styled from 'styled-components/macro';
import CategoryItem from '../../shared/CategoryItem';
import SUIContainer from '../../shared/Container';

const Container = styled(SUIContainer)`
  padding: 20px;
`;

const MenuTab = ({ menuCategories }) => {
  const foodCategories = menuCategories?.filter(
    (fbMenuCategory) => fbMenuCategory.fbMenuMacrocategory.name === 'food' && fbMenuCategory.fbMenuItems.length > 0
  );
  const drinkCategories = menuCategories?.filter(
    (fbMenuCategory) => fbMenuCategory.fbMenuMacrocategory.name === 'drink' && fbMenuCategory.fbMenuItems.length > 0
  );

  return (
    <Container>
      {foodCategories?.map((category, index) => (
        <CategoryItem key={index} expandedKey={`food-${category.id}`} category={category} disabled />
      ))}

      {drinkCategories?.map((category, index) => (
        <CategoryItem key={index} expandedKey={`drink-${category.id}`} category={category} disabled />
      ))}
    </Container>
  );
};

export default MenuTab;
