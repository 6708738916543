import React from 'react';
import styled from 'styled-components/macro';
import DaysRows from './DaysRows';
import BoldLabel from '../../shared/BoldLabel';
import SUIContainer from '../../shared/Container';

const Container = styled(SUIContainer)`
  padding: 20px;
`;

const Text = styled.span`
  font-family: optima-medium;
`;

const InfoTab = ({ outlet }) => {
  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <BoldLabel label="about-us" style={{ marginBottom: 20 }} />
        <Text>{outlet?.description}</Text>
        <BoldLabel label="contact-us" style={{ marginBottom: 20, marginTop: 20 }} />

        <Text>{outlet?.phoneNumber}</Text>

        <BoldLabel label="reservations-hours" style={{ marginBottom: 20, marginTop: 20 }} />
        <DaysRows outlet={outlet} />
      </div>
    </Container>
  );
};

export default InfoTab;
