import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';

const Tabs = ({ activeIndex, menuPosition, menu, tabs, onTabChange }) => {
  return <Tab activeIndex={activeIndex} menuPosition={menuPosition} menu={menu} panes={tabs} onTabChange={onTabChange} />;
};

Tabs.propTypes = {
  activeIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Left or right */
  menuPosition: PropTypes.oneOf(['left', 'right']),
  menu: PropTypes.shape({ primary: PropTypes.bool, secondary: PropTypes.bool, pointing: PropTypes.bool }),
  tabs: PropTypes.arrayOf(PropTypes.shape({ menuItem: PropTypes.string.isRequired, render: PropTypes.func.isRequired })).isRequired,
  onTabChange: PropTypes.func
};

Tabs.defaultProps = {
  activeIndex: undefined,
  menuPosition: 'left',
  menu: { secondary: true, pointing: true },
  onTabChange: () => {}
};

export default Tabs;
