/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Modal from '../../components/shared/Modal';
import Container from '../../components/shared/Container';
import { TranslateTextComponent } from '../../translator';
import { ReactComponent as CloseIcon } from '../../assets/images/action-icons/close-icon-modal.svg';

const Button = styled.div`
  cursor: pointer;
`;

const ResponsiveModal = styled(Modal).attrs(({ mobile }) => ({
  modalStyle: { padding: 20, overflow: mobile ? 'auto' : 'none' },
  contentStyle: { height: mobile ? '400px' : `${window.screen.height - 300}px`, overflow: 'auto' }
}))``;

const LegalAreaContainer = () => {
  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] = useState(false);
  const [isPrivacyTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] = useState(false);
  return (
    <Container style={{ paddingTop: 20 }}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setIsPrivacyPolicyModalOpen(true);
        }}
      >
        <TranslateTextComponent style={{ fontFamily: 'optima-bold', fontSize: 25 }} capitalize>
          privacy-policy
        </TranslateTextComponent>
      </Button>
      <Button
        style={{ marginTop: 18 }}
        onClick={(e) => {
          e.stopPropagation();
          setIsTermsAndConditionsModalOpen(true);
        }}
      >
        <TranslateTextComponent style={{ fontFamily: 'optima-bold', fontSize: 25 }} capitalize>
          terms-and-conditions
        </TranslateTextComponent>
      </Button>

      <ResponsiveModal
        mobile
        open={isPrivacyPolicyModalOpen}
        onClose={() => setIsPrivacyPolicyModalOpen(false)}
        closeIcon={<CloseIcon style={{ cursor: 'pointer', fill: '#464243', position: 'absolute', top: 16, right: 16 }} />}
      >
        <span>
          Informativa sulla Privacy ex art. 13 1. Titolare del trattamento e informazioni di contatto La seguente informativa privacy illustra come
          Red Wing Labs S.r.l. (“RWL”), p.iva 02595870748 con sede legale in FASANO (BR), C. DA PETTOLECCHIA SN CAP 72015, tratta i dati personali
          degli utenti dell’app “SMART!” (in seguito “Utente”, “Tu”, “Interessato”) in conformità con il Regolamento EU n°679/16 (“GDPR”) e del D.lgs.
          n° 196/2003 e s.m.i. (“Codice Privacy”). Come meglio specificato in seguito, questa policy illustra come RWL tratta i Tuoi dati personali al
          fine di darti accesso ai servizi offerti mediante l’app “SMART!”, nonché i dettagli dei soggetti che possono accedere ai suddetti dati, le
          basi giuridiche per il trattamento, la conservazione dei tuoi dati personali e i diritti a te garantiti. Tutti i dati personali saranno
          trattati esclusivamente per gli scopi specificati di seguito. 2. Quali dati personali sono trattati e come vengono raccolti? RWL tratta i
          seguenti dati personali: 1. Dati anagrafici e di contatto dell’interessato (nome, cognome, indirizzo, numero di telefono, indirizzo email);
          2. Dati personali finanziari (dati di pagamento); 3. Dati personali generati dall’interessato (nome utente, password); 4. Dati di posizione
          (coordinate GPS); I dati personali trattati da RWL sono raccolti: 1. Direttamente mediante le diverse sezioni dell’app. 2. Direttamente
          mediante l’attivazione di specifiche funzioni (es. GPS) RWL, inoltre, utilizza tecnologie di tracciamento, quali cookies e similari, tramite
          le quali tratta dati analitici per misurare e analizzare l'utilizzo e l'efficacia dei nostri Servizi; 3. Finalità e Basi Giuridiche del
          trattamento dei dati personali RWL tratta i Tuoi dati personali per le seguenti finalità: a. generare il tuo account al fine di consentirti
          di effettuare Ordini secondo le nostre Condizioni generali; b. identificarti quando accedi al tuo account; c. informarti di modifiche e
          aggiornamenti dei nostri servizi; d. elaborare il tuo ordine ed emettere i necessari documenti di pagamento; e. comunicare i tuoi dati
          all’esercente per permettere il completamento dell’ordine; f. rispondere alle richieste di informazioni; g. pubblicare le tue recensioni
          relative ai servizi degli esercenti presenti sull’APP; h. inviarti pubblicità mirata; Per quanto riguarda le finalità da a) a g) le stesse
          sono perseguite ai sensi dell’art. 6 comma 1 lett. b) GDPR al fine di dare esecuzione al contratto stipulato ai sensi delle condizioni
          generali, nonché per garantire l’esecuzione di tutte quelle attività prodromiche alla sua conclusione. La finalità di cui al punto h)
          (“Marketing”) sarà perseguita ai sensi dell’art. 6 comma 1 lett. a) GDPR, quindi esclusivamente tuo previo consenso espresso al trattamento,
          liberamente revocabile senza che la revoca comporti conseguenze per te pregiudizievoli. 4. Con chi condividiamo i tuoi dati? I dati
          personali di cui al punto 1, saranno comunicati ai seguenti destinatari che li tratteranno in qualità di autonomi titolari del trattamento
          al fine di dare esecuzione al contratto d’acquisto: 1. I ristoranti presso i quali hai effettuato un Ordine; 2. Gli esercizi commerciali
          presso i quali hai riservato un servizio; 3. Stripe Inc. che elaborerà il pagamento effettuato tramite l’APP; Tutti i summenzionati
          soggetti, tratteranno i tuoi dati personali al sol fine di dare esecuzione al servizio ai sensi delle rispettive informative privacy e
          comunque per il tempo necessario a dare esecuzione al servizio. 5. Sicurezza RWL pone la massima attenzione alla sicurezza dei tuoi dati
          personali, per questo ha implementato robusti sistemi di sicurezza in conformità a quanto previsto dall’art. 32 GDPR. 6. Conservazione dei
          dati RWL conserverà i tuoi dati personali per tutto il tempo necessario a perseguire le finalità di cui al punto 2. In particolare, i tuoi
          dati personali saranno conservati per: 1. 5 anni per finalità fiscali e di legge; 2. 24 mesi per finalità di marketing e profilazione; Nel
          caso in cui tu richieda la cancellazione del tuo account, i tuoi dati personali saranno immediatamente cancellati, ma RWL conserverà alcuni
          di questi (dati di fatturazione di cui al punto 5.1) per garantire il rispetto delle normative applicabili. 7. I tuoi diritti Sei titolare
          dei diritti di cui agli articoli 16-21 GDPR (diritto di conferma, diritto all’oblio, diritto alla limitazione del trattamento, diritto alla
          portabilità dei dati, diritto di opposizione) nonché del diritto di proporre reclamo all’Autorità di controllo. Può esercitare i suoi
          diritti contattandoci all’indirizzo di cui al punto 1, o scrivendo una mail all’indirizzo info@redwinglabs.com. Ciascun utente registrato
          all’APP può accedere alle proprie informazioni personali e aggiornarle (es. attraverso l’account dell’utente). Gli utenti possono inoltre
          modificare e aggiornare le proprie preferenze su come intendono ricevere e-mail o altre comunicazioni da parte del Titolare.
        </span>
      </ResponsiveModal>

      <ResponsiveModal
        mobile
        open={isPrivacyTermsAndConditionsModalOpen}
        onClose={() => setIsTermsAndConditionsModalOpen(false)}
        closeIcon={<CloseIcon style={{ cursor: 'pointer', fill: '#464243', position: 'absolute', top: 16, right: 16 }} />}
      >
        <span>
          TERMINI E CONDIZIONI DELL’APP SMART IMPORTANTI INFORMAZIONI LEGALI I presenti termini e condizioni (le "Condizioni dell’APP") individuano le
          regole sulla base delle quali la società Red Wings Lab S.r.l. (di seguito "noi" o "RWL") fornisce accesso all’APP per dispositivi mobili
          “SMART!” e a qualsiasi applicazione mobile RWL che consenta agli utenti di ordinare prodotti ("APP"). Si prega di leggere attentamente le
          presenti Condizioni dell’APP prima di ordinare eventuali prodotti dall’APP. L'inoltro di ordini tramite APP (ora o in futuro) comporta
          l'accettazione da parte degli utenti delle presenti Condizioni dell’APP che disciplinano l'utilizzo dell’APP stessa. RWL si riserva il
          diritto di modificare nel tempo le presenti Condizioni dell’APP mediante modifica delle previsioni riportate su questa pagina. Nel caso in
          cui l'utente non accetti le nuove Condizioni dell’APP, è tenuto a non utilizzare i servizi offerti dall’APP e a non ordinare prodotti
          tramite la stessa. Si raccomanda di stampare una copia delle presenti Condizioni dell’APP per futura consultazione. L'uso di dati personali
          trasmessi tramite l’APP SMART! è disciplinato dalla Informativa RWL in materia di Privacy. Per chiarezza, si specifica che qualsiasi
          riferimento all’APP contenuto nelle presenti Condizioni comprende tutte le versioni attuali o future dell’applicazione mobile SMART! tramite
          la quale si abbia accesso al, e si utilizzi l’APP di RWL e ciò indipendentemente dal fatto che, sia nell'uno che nell'altro caso, l'accesso
          avvenga tramite una piattaforma o un dispositivo attualmente esistenti oppure tramite una piattaforma o un dispositivo futuri (ivi inclusi,
          a titolo esemplificativo e non esaustivo, qualsiasi sito mobile, applicazione mobile, sito affiliato o correlato per l'accesso al, e
          l'utilizzo dell’APP RWL eventualmente sviluppato nel corso del tempo). La visita all’APP, anche solo di alcune sue parti, costituisce
          accettazione da parte degli utenti delle presenti Condizioni. Gli utenti dell’APP che non accettano le presenti Condizioni dell’APP sono
          tenuti a non utilizzare i servizi offerti dall’APP e a non ordinare prodotti tramite l’APP stessa. I. CONDIZIONI GENERALI DI UTILIZZO E DI
          VENDITA 1. NORME INTRODUTTIVE E RUOLO DI RWL 1.1. RWL è una società di diritto italiano, con sede in Fasano (BR)_____________-,
          P.IVA_________ 1.2. Ordini dei Prodotti: RWL fornisce agli utenti un sistema per la comunicazione dei propri ordini ai ristoranti/servizi
          che compaiono sull’APP. Il contratto per la fornitura e l'acquisto di Prodotti è concluso tra l'utente e il Ristorante al quale l'utente
          inoltra l'Ordine e RWL concluderà la vendita dei Prodotti per conto dei Ristoranti in tutti i casi. In ogni caso, RWL non ha alcun obbligo
          di promuovere la conclusione di contratti tra i Ristoranti e l'utente. 2. ACCESSO AL SITO E CONDIZIONI DEL SITO 2.1. Accesso allAPP: E'
          possibile avere accesso ad alcune aree dell’APP anche senza effettuare un Ordine o senza procedere alla registrazione dei propri dati. La
          maggior parte delle aree dell’APP è accessibile a chiunque. 2.2. Accettazione delle Condizioni dell’APP: La navigazione in qualunque area
          dell’APP comporta l'accettazione delle Condizioni dell’APP. Gli utenti dell’APP che non accettano le Condizioni dell’APP sono tenuti a non
          utilizzare i servizi offerti dall’APP e a non ordinare prodotti tramite l’APP stesso. 2.3. Modifiche alle Condizioni dell’APP: RWL ha
          facoltà di modificare le presente Condizioni dell’APP in qualsiasi momento. L'utente è pertanto tenuto a controllare periodicamente le
          Condizioni dell’APP in vigore al momento dell'effettuazione dell'Ordine dei prodotti poiché la versione pubblicata sul APP a tale data
          troverà applicazione per gli utenti dell’APP. Gli utenti dell’APP sono tenuti al rispetto delle regole in vigore al momento in cui
          effettuano un Ordine tramite RWL. 2.4. Responsabilità: Spetta ai visitatori e utenti dell’APP predisporre quanto necessario per avere
          accesso all’APP stessa. Inoltre, i visitatori e utenti dell’APP sono tenuti a garantire che tutti coloro che hanno accesso all’APP tramite
          il loro account e la loro connessione ad Internet siano a conoscenza delle presenti Condizioni dell’APP e le rispettino. 3. STATUS
          DELL'UTENTE DEL SITO 3.1. Capacità giuridica ed età: Effettuando un Ordine tramite l’APP, l'utente garantisce: 3.1.1. di aver la capacità di
          agire e concludere contratti giuridicamente vincolanti con i Ristoranti/esercenti; 3.1.2. di avere compiuto 18 anni. 3.2. Tu confermi e ti
          impegni, qualora tu abbia una specifica allergia o intolleranza alimentare, a contattare direttamente il Ristorante per verificare che il
          cibo sia indicato alle tue esigenze, prima di effettuare l'ordine direttamente con il Ristorante stesso. 3.3 Bevande alcoliche, sigarette e
          altri prodotti relativi al fumo: 3.3.1 L'utente accetta e dichiara che costituisce reato per qualsiasi soggetto di età minore di 18 anni
          acquistare, o tentare di acquistare, bevande alcoliche o per qualsiasi soggetto acquistare, o tentare di acquistare, bevande alcoliche,
          tabacco o altri prodotti relativi al fumo per conto di un soggetto minore di 18 anni. 3.3.2 Qualora l'Ordine dell'utente includa qualsiasi
          bevanda alcolica, sigarette o altro prodotto relativo al fumo, all'utente sarà richiesto di fornire prova della propria età al ricevimento o
          alla consegna del suo Ordine. Se l'utente non è in grado di fornire la prova di avere 18 anni o più a discrezione del Ristorante prescelto,
          o laddove il Ristorante ritenga ragionevolmente che le bevande alcoliche, le sigarette o gli altri prodotti relativi al fumo ordinati
          dall'utente siano stati acquistati dall'utente per conto di una persona minore di 18 anni, il Ristorante si riserva il diritto di non
          completare la consegna all'utente delle bevande alcoliche, delle sigarette o degli altri prodotti relativi al fumo. 4. MODALITÀ DI INVIO E
          DI ELABORAZIONE DELL'ORDINE 4.1. Compilazione dell'Ordine: Dopo aver selezionato i prodotti che intende ordinare dal menu del Ristorante
          prescelto e/o aver effettuato la scelta del servizio cui intende accedere ed aver fornito le informazioni richieste, l'utente ha facoltà di
          effettuare l'Ordine cliccando sul, o selezionando il, pulsante "paga ora" o "procedi al pagamento" o altro simile pulsante. Si ricorda che è
          importante controllare tutte le informazioni immesse e correggere eventuali errori prima di cliccare sul pulsante o di selezionarlo poiché,
          dopo averlo fatto, l'utente concluderà un contratto di vendita con il Ristorante/Esercente non sarà più possibile correggere eventuali
          errori o recedere dall'Ordine (fatto salvo quanto previsto nel paragrafo 4.2 di seguito). 4.2. Modifica o recesso dall'Ordine: Ai sensi
          dell'art. 59 del Codice del Consumo, il diritto di recesso è escluso per gli Ordini effettuati presso ristoranti in quanto trattasi di
          prodotti lavorati su ordinazione e soggetti a rapido deterioramento. Una volta che l'Ordine è stato inoltrato l'utente conclude un contratto
          di vendita di prodotti con il Ristorante e, pertanto, non può modificare o recedere dall'Ordine e non ha diritto ad alcun rimborso (si prega
          di consultare gli articoli 4.4 e 5.8 per ulteriori informazioni relative al procedimento applicabile al rifiuto di esecuzione degli Ordini e
          al rimborso del pagamento). Tuttavia, ove desideri comunque tentare di modificare o recedere dall'Ordine, l'utente può rivolgersi al
          Servizio Clienti, con le modalità descritte all'articolo 6.3, il quale potrà tentare di contattare il Ristorante al fine di comunicargli la
          richiesta dell'utente. L'utente riconosce espressamente che non vi è alcun obbligo né assicurazione, tuttavia, riguardo al fatto che il
          Servizio Clienti sarà in grado di contattare il Ristorante o che il Ristorante accetterà le richieste di modifica o recesso dell'utente. Il
          diritto di recesso è sempre garantito, ai sensi della normativa applicabile, per tutti i servizi di prenotazione. 4.3. Autorizzazione al
          pagamento: In caso di mancata autorizzazione relativa ad un qualsiasi pagamento, l'Ordine non viene elaborato o non viene inviato al
          Ristorante interessato. 4.4. Elaborazione dell'Ordine e rifiuto di esecuzione degli Ordini da parte del Ristorante: Alla ricezione
          dell'Ordine, RWL trasmette lo stesso al Ristorante interessato e notifica all'utente, tramite e-mail, che l'Ordine è stato ricevuto ed è in
          corso di elaborazione. Si specifica che qualsiasi pagina di conferma che l'utente possa visualizzare sul APP e qualsiasi conferma di Ordine
          che l'utente stesso riceva tramite e-mail confermano ulteriormente la avvenuta conclusione del contratto di vendita di prodotti con un
          Ristorante, ma ciò non implica necessariamente che i prodotti ordinati saranno consegnati dal Ristorante. RWL incoraggia fermamente tutti i
          Ristoranti, per conto dei quali opera come mandatario, a eseguire tutti gli Ordini e a comunicare tempestivamente eventuali rifiuti di
          esecuzione degli Ordini e comunica all'utente (generalmente tramite e-mail), non appena sia ragionevolmente possibile, ogni eventuale
          rifiuto di esecuzione dell'Ordine da parte del Ristorante. In ogni caso, RWL non può escludere che i Ristoranti rifiutino di eseguire gli
          Ordini in qualsiasi momento per il fatto di essere eccessivamente impegnati, a causa delle condizioni atmosferiche o per qualsiasi altra
          ragione. In tali casi, qualsiasi pagamento già effettuato in relazione all'Ordine sarà restituito all'utente ai sensi del successivo
          articolo 5.7. 4.5. Consegna dell'Ordine: I tempi stimati per le consegne e i ritiri sono forniti dai Ristoranti e sono solo indicativi. RWL
          e i Ristoranti non garantiscono che gli Ordini verranno consegnati o saranno disponibili per il ritiro entro i tempi stimati. 5. PREZZO E
          PAGAMENTO 5.1. IVA e costi di consegna: I prezzi dei prodotti sono quelli indicati sull’APP. I prezzi sono comprensivi di IVA ma potrebbero
          non comprendere i costi di consegna (qualora si scelga la "consegna" in luogo del "ritiro"). Tali costi vengono aggiunti all'importo totale
          dovuto, se applicabili. 5.2. Errata indicazione del prezzo: la presente APP contiene un vasto numero di menu e servizi ed è sempre possibile
          che alcuni di essi possano recare una indicazione inesatta del prezzo. Qualora il prezzo esatto di un Ordine sia più elevato del prezzo
          indicato sul APP, RWL normalmente provvede a contattare l'utente prima della spedizione dell'Ordine. In tal caso, né RWL né il Ristorante
          interessato hanno alcun obbligo di assicurare che l'Ordine venga fornito all'utente al prezzo inferiore erroneamente indicato né di
          rimborsare all'utente la differenza rispetto al prezzo errato. Nel caso in cui il prezzo sia eccessivamente elevato rispetto al prezzo
          originariamente convenuto e l'utente sia un consumatore, ai sensi del Codice del Consumo l'utente avrà facoltà di recedere dall'Ordine. 5.3.
          Modalità di pagamento: Il pagamento degli Ordini può essere effettuato mediante una carta di credito/debito o altro strumento di pagamento
          elettronico tramite l’APP oppure in contanti all’esercente in caso di prenotazione di servizi diversi. 5.4. Pagamenti con carta di credito:
          Se l'utente paga con carta di credito o di debito, può essergli chiesto di mostrare la carta al Ristorante al momento della consegna o del
          ritiro come prova dell'identità consentendo al tempo stesso di controllare che la carta corrisponda ai dati della ricevuta relativa
          all'Ordine. Di tanto in tanto possono verificarsi ritardi nell'elaborazione dei pagamenti e delle transazioni mediante carta; questo può
          avere come risultato che taluni pagamenti possono impiegare sino a sessanta (60) giorni per essere addebitati sul conto corrente o sulla
          carta di credito o debito dell'utente. 5.5 Nel caso di pagamento tramite carta di credito o debito o altro strumento di pagamento
          elettronico, RWL è autorizzato dal Ristorante a ricevere il relativo pagamento da parte dell'utente per conto del Ristorante stesso. Il
          pagamento in favore di RWL ha effetto liberatorio per l'utente. 5.6. Buoni di accredito e di sconto: All'Ordine può essere applicato un
          accredito o uno sconto se l'utente si avvale di un buono o di un codice promozionale riconosciuto dall’APP e avvalorati da RWL per conto dei
          Ristoranti e/o degli altri Esercenti, e l'utente procede al pagamento del saldo mediante carta di credito debito o altro strumento di
          pagamento elettronico. In base alle procedure bancarie ordinarie, la banca o la società che ha emesso la carta di credito inizialmente
          riservano (cd "ring fence") l'intero importo relativo all'Ordine (prima dell'accredito o dello sconto) nel conto corrente dell'utente per un
          periodo compreso tra i 3 e i 5 giorni lavorativi (o più, a seconda della banca o della società che ha emesso la carta di credito), importo
          che diviene pertanto non disponibile nel conto per la durata del suddetto periodo. L'accredito o lo sconto si applicano nel momento in cui
          la banca o la società che ha emesso la carta trasferisce a RWL i fondi relativi all'Ordine: in tale momento, l'importo dell'accredito o
          dello sconto non viene trasferito a RWL e viene invece reso nuovamente disponibile per l'utente dalla banca o dalla società che ha emesso la
          carta. L'utente prende atto del fatto, e lo accetta, che né RWL né il Ristorante interessato sono responsabili nei confronti dell'utente del
          ritardo dovuto al periodo sopra indicato con cui la banca o la società che ha emesso la carta provvede al rilascio dei fondi nel conto
          corrente dell'utente. 5.7. Mancata esecuzione o annullamento degli Ordini: In base alle procedure bancarie ordinarie, una volta presentato
          un Ordine per cui l'utente paga mediante carta di credito e che il pagamento è stato autorizzato, la banca o la società che ha emesso la
          carta riservano (cd "ring fence") l'intero importo relativo all'Ordine. Se l'esecuzione dell'Ordine viene successivamente rifiutata dal
          Ristorante (come descritto nel precedente articolo 4.4) o se l'Ordine viene annullato per qualsiasi altra ragione, la banca o la società che
          ha emesso la carta non effettua il trasferimento a RWL dei fondi relativi all'Ordine e procede invece a rendere nuovamente disponibile il
          relativo importo nel conto dell'utente. Tuttavia, tale processo potrebbe richiedere 10 giorni lavorativi (o più, a seconda della banca o
          della società che ha emesso la carta). L'utente prende atto del fatto, e lo accetta, che né RWL né il Ristorante interessato sono
          responsabili nei confronti dell'utente del ritardo dovuto al periodo sopra indicato con cui la banca o la società che ha emesso la carta
          provvede al rilascio dei fondi nel conto corrente dell'utente. 6. SERVIZIO CLIENTI 6.1. Disposizioni Generali: RWL considera il Servizio
          Clienti estremamente importante. Nel rispetto dell'articolo 6.5 e dell'articolo 11, il Servizio Clienti cerca dunque di prestare assistenza
          all'utente, per conto del Ristorante, quando possibile, in caso di problemi con l'Ordine. L'utente può entrare in contatto con il Servizio
          Clienti cliccando su, o selezionando il, pulsante "Serve aiuto?", "Aiuto" o altro pulsante simile ovvero telefonando al numero indicato nel
          APP. 6.2. Domande relative all'Ordine: Se l'Ordine richiede più tempo del previsto o in caso di qualsiasi altro problema con l'Ordine,
          l'utente può rivolgersi al Servizio Clienti con le modalità sopra descritte e uno dei Consulenti del Servizio Clienti RWL tenterà di
          contattare il Ristorante per dare corso alla richiesta dell'utente. 6.3. Modifica o recesso dall'Ordine: Come indicato nel precedente
          articolo 4.2, ove l'utente desideri modificare o recedere dal proprio Ordine dopo l'invio, l'utente ha facoltà di rivolgersi al Servizio
          Clienti RWL, con le modalità sopra descritte, il quale potrà tentare di entrare in contatto con il Ristorante al fine di comunicare le
          richieste dell'utente. L'utente riconosce espressamente che non vi è alcun obbligo né assicurazione, tuttavia, riguardo al fatto che il
          Servizio Clienti RWL sarà in grado di contattare il Ristorante o che il Ristorante accetterà le richieste di modifica o recesso dell'utente.
          6.4. Reclami e feedback da parte degli utenti: Nell'eventualità che l'utente non sia soddisfatto della qualità di un qualsiasi Prodotto o
          del servizio fornito da un Ristorante, RWL invita l'utente a far conoscere la propria opinione tramite l’APP, in forma di valutazioni,
          commenti e recensioni (congiuntamente indicati come le "Recensioni") che riflettano la propria esperienza. Le Recensioni sono una parte
          importante del processo di controllo qualità di RWL. 6.5. Indennizzo: Nell'eventualità che l'utente non sia soddisfatto della qualità di un
          qualsiasi Prodotto o del servizio fornito da un Ristorante e desideri richiedere un rimborso, una riduzione proporzionale del prezzo o
          qualsiasi altra forma di indennizzo, è tenuto a contattare direttamente il Ristorante al fine di presentare il proprio reclamo e, se del
          caso, ad osservare le procedure di reclamo previste dal Ristorante stesso. Qualora l'utente non sia in grado di contattare il Ristorante, o
          qualora il Ristorante rifiuti di occuparsi del reclamo dell'utente, quest'ultimo può rivolgersi al Servizio Clienti RWL con le modalità
          sopra descritte entro 48 ore dall'inoltro dell'Ordine e uno dei Consulenti del Servizio Clienti di RWL, agendo in qualità di mandatario del
          Ristorante, tenterà di contattare il Ristorante al fine di richiedere un indennizzo per conto dell'utente. Si fa presente, tuttavia, che il
          contratto per la fornitura e l'acquisto di Prodotti è tra l'utente e il Ristorante al quale l'utente inoltra l'Ordine. RWL non ha alcun
          controllo sui Ristoranti né sulla qualità dei Prodotti o sul servizio fornito dai Ristoranti stessi e non è in grado di fornire, né assume
          alcuna responsabilità o impegno di fornire, alcun indennizzo o risarcimento in favore dell'utente per conto di qualsiasi Ristorante. 7.
          LICENZA D'USO 7.1. Uso consentito: E' consentito all'utente utilizzare l’APP nonché stampare e scaricare dall’APP estratti dell’APP stesso
          per uso personale non avente finalità commerciali alle seguenti condizioni: 7.1.1. l'utente non deve utilizzare l’APP in modo improprio, ivi
          incluso mediante tecniche di pirateria informatica (hacking) o di estrazione informatizzata di dati (scraping). 7.1.2. Salvo che sia
          diversamente previsto, il diritto d'autore e gli altri diritti di proprietà intellettuale relativi al APP ed al materiale in esso pubblicato
          (ivi incluso, a titolo esemplificativo e non esaustivo, fotografie ed immagini grafiche) sono di proprietà di RWL o dei soggetti da cui RWL
          ha ottenuto la relativa licenza. Le opere suddette sono protette a livello mondiale in base alle leggi ed ai trattati in materia di diritto
          d'autore e tutti i diritti sono riservati. Ai fini del presente APP, è vietato qualsiasi eventuale uso di estratti del presente APP con
          modalità diverse da quanto previsto dal presente articolo 7.1. 7.1.3. Non è consentito modificare le copie digitali o cartacee di eventuale
          materiale stampato in conformità a quanto previsto nell'articolo 7.1 né utilizzare alcun disegno, fotografia o altra immagine grafica,
          sequenza video o audio separatamente dal testo che l'accompagna. 7.1.4. E' necessario accertarsi che a RWL venga sempre riconosciuto la
          qualità di autore del materiale presente sul APP. 7.1.5. Non è consentito l'utilizzo di materiale presente sul APP o dell’APP stesso per
          finalità commerciali senza aver ottenuto da RWL una licenza in tal senso. 7.1.6. Restrizioni d'uso: Salvo per quanto previsto all'articolo
          7.1, la presente APP non può essere utilizzato e nessuna sua parte può essere riprodotta o archiviata in alcun altro sito web né può essere
          inserito in alcun sistema o servizio, pubblico o privato, per il recupero elettronico delle informazioni senza la preventiva autorizzazione
          scritta di RWL. 7.1.7. Riserva di Diritti: I diritti non esplicitamente concessi nel presente APP sono riservati. 8. ACCESSO ALL’APP 8.1.
          Disponibilità dell’APP: Sebbene RWL si adoperi per far sì che l'accesso al APP possa di norma avvenire ventiquattro ore su ventiquattro
          (24h/24), RWL non assume alcun obbligo a riguardo e non è responsabile nei confronti dell'utente nel caso in cui l’APP, in qualsiasi momento
          o per periodi di qualsiasi durata, non sia disponibile. 8.2. Sospensione dell'accesso e recesso: L'accesso al presente APP può essere
          temporaneamente sospeso in qualsiasi momento, anche senza preavviso, in caso di manutenzione dell’APP e/o per motivi che sono fuori dal
          controllo di RWL. RWL e l'utente possono recedere dalle presenti Condizioni dell’APP con un preavviso di due settimane (da comunicarsi via
          email). In caso di recesso, RWL provvederà a cancellare l'account dell'utente, fatti salvi gli obblighi di legge. 8.3. Sicurezza
          informatica: Purtroppo, la trasmissione di informazioni via internet non è del tutto sicura. Sebbene RWL adotti le misure prescritte per
          legge per la tutela delle informazioni immesse, essa non è in grado di garantire la sicurezza dei dati trasmessi al APP; la trasmissione
          viene pertanto effettuata a rischio dell'utente. 9. MATERIALE DEGLI UTENTI E RECENSIONI 9.1. Disposizioni Generali: 9.1.1. Eventuale
          materiale diverso dalle informazioni di identificazione personale, le quali sono disciplinate dalla Informativa RWL in materia di Privacy,
          che venga trasmesso o pubblicato (post) o caricato (upload) dall'utente sul presente APP (compreso, a titolo esemplificativo e non
          esaustivo, eventuali Recensioni) si considera non riservato (di seguito il "Materiale degli Utenti"). Con la pubblicazione, l'upload o la
          trasmissione di Materiale degli Utenti l'utente dichiara e garantisce di avere la titolarità o altrimenti il controllo di tutti i diritti
          relativi al suddetto Materiale degli Utenti e di essere l'unico soggetto responsabile di tali contenuti. L'utente accetta e conviene che RWL
          non ha alcun obbligo (a mero titolo esemplificativo, di controllo o revisione prima o dopo la pubblicazione da parte dell'utente) in
          relazione al Materiale degli Utenti e concede gratuitamente e in maniera perpetua alla stessa RWL, o a qualsiasi persona da essa
          eventualmente incaricata, la licenza di poter liberamente copiare, comunicare, distribuire, incorporare o utilizzare in qualsiasi altro modo
          il Materiale degli Utenti nonché qualsiasi dato, immagine, suono, testo e quant'altro sia in esso incorporato per qualsivoglia finalità, sia
          commerciale che non commerciale. 9.1.2. L'utente dichiara e garantisce che il Materiale degli Utenti che l'utente stesso pubblica (post),
          carica sul APP (upload) o comunque trasmette all’APP non viola, e non violerà in futuro, alcuna delle limitazioni previste nei successivi
          articoli 9.2 e 9.3. 9.2. Policy relativa al Materiale degli Utenti: E' vietato pubblicare (post), caricare (upload) o trasmettere al, o dal,
          APP Materiale degli Utenti (ivi comprese le Recensioni) che: 9.2.1. sia in violazione di eventuali leggi applicabili a livello locale,
          nazionale o internazionale; 9.2.2. sia illecito o ingannevole; 9.2.3. equivalga a pubblicità non autorizzata; o 9.2.4. contenga virus o
          qualsiasi altro programma dannoso. 9.3. Policy relative alle Recensioni degli Utenti: In particolare, a titolo esemplificativo e non
          esaustivo, è vietato immettere sul APP Recensioni che: 9.3.1. contengano materiale diffamatorio, osceno o offensivo; 9.3.2. promuovano
          violenza o discriminazione; 9.3.3. violino diritti di proprietà intellettuale di terzi; 9.3.4. siano in violazione di un eventuale obbligo
          giuridico nei confronti di soggetti terzi (quale, esemplificativamente, un obbligo di fiducia); 9.3.5. promuovano attività illecite o siano
          in violazione dei diritti di riservatezza di soggetti terzi; 9.3.6. diano l'impressione di provenire da RWL; e/o 9.3.7. vengano utilizzati
          per impersonare altri o travisare la realtà riguardo ad un rapporto di parentela stretta (maternità/paternità) con un'altra persona. 9.4.
          Rimozione delle Recensioni: L'elenco degli atti vietati di cui ai precedenti articoli 9.2 e 9.3 è esclusivamente esemplificativo e non è
          esaustivo. RWL si riserva il diritto (senza assumere a riguardo alcuna obbligazione, se non limitatamente a quanto sia necessario per legge)
          di procedere su base assolutamente discrezionale alla rimozione o correzione in qualsiasi momento di qualsiasi Recensione o altro Materiale
          degli Utenti pubblicato (post), caricato (upload) o trasmesso sul APP che RWL ritenga violare uno dei divieti di cui ai precedenti articoli
          9.2 o 9.3, che sia altrimenti discutibile o possa esporre RWL o qualsiasi altro soggetto terzo ad un pregiudizio o responsabilità di
          qualsiasi tipo o per qualsiasi altra ragione. 9.5. Uso delle Recensioni: Le Recensioni e il Materiale degli Utenti contenuti nell’APP hanno
          finalità esclusivamente informativa e non rappresentano un suggerimento da parte di RWL. Le Recensioni e il Materiale degli Utenti
          riflettono le opinioni dei clienti che hanno ordinato tramite l’APP o altri soggetti terzi e qualsiasi dichiarazione, consiglio o opinione
          fornita da tali soggetti pertiene esclusivamente ad essi. Di conseguenza, nella misura massima consentita dalla legge, RWL non assume alcuna
          responsabilità né impegno di risarcimento nei confronti di alcun soggetto in relazione ad alcuna Recensione o altro Materiale degli Utenti,
          ivi incluso, a titolo esemplificativo e non esaustivo, in relazione a errori, diffamazione, oscenità omissioni o falsità che possano essere
          riscontrati in tali contenuti. 9.6. Responsabilità dell'utente: L'utente si impegna a risarcire RWL per qualsiasi perdita, danno o reclamo
          (e per tutti i costi ad essi correlati) sostenuti dalla stessa RWL o fatti valere nei suoi confronti da un Ristorante o altro soggetto terzo
          e che siano derivanti da, o in connessione con, una qualsiasi Recensione o altro Materiale degli Utenti forniti dall'utente in violazione
          delle dichiarazioni, garanzie, impegni o restrizioni previsti nel presente articolo 9. 9.7. Comunicazioni all'autorità amministrativa o
          giudiziaria: RWL fornisce piena collaborazione ad ogni autorità competente che richieda o imponga di rivelare l'identità o la posizione di
          chiunque pubblichi o trasmetta Recensioni o Materiale degli Utenti in violazione degli articoli 9.2 e 9.3. 10. LINK VERSO E DA ALTRI SITI
          10.1. Siti di terzi: Eventuali link verso siti di soggetti terzi presenti sul APP vengono forniti esclusivamente per comodità dei visitatori
          e degli utenti. L'utilizzo di tali link comporta l'abbandono dell’APP. RWL non ha esaminato i suddetti siti di soggetti terzi e non ha alcun
          controllo su di essi o sul relativo contenuto o accessibilità (e non è responsabile degli stessi). RWL non approva né rilascia garanzia
          alcuna in relazione ai suddetti siti, al materiale in essi presente o ai risultati derivanti dall'utilizzo dei medesimi. Chiunque decida di
          accedere ad un sito di soggetti terzi di cui sia presente il link sul APP lo fa esclusivamente a proprio rischio. 11. ESONERO DA
          RESPONSABILITÀ 11.1. Informazioni fornite sul APP: Sebbene RWL si sforzi di garantire che l'informazione fornita sul APP sia corretta, essa
          non promette che tale informazione sia accurata o completa. RWL può apportare qualsivoglia modifica al materiale presente sul APP o al
          funzionamento dello stesso ed ai prezzi in esso descritti, in qualsiasi momento e senza preavviso, comunicando tali modifiche agli utenti
          nelle medesime modalità con le quali le erano stati comunicati i servizi e i prezzi. Il materiale presente sul APP potrebbe non essere
          aggiornato e RWL non assume alcun impegno di aggiornarlo. 11.2. Informazioni relative alle allergie, informazioni di carattere dietetico e
          altre informazioni sul menu: Quando un Ristorante si registra con noi, deve fornirci informazioni aggiornate sul menu. Dopodiché noi
          includiamo queste informazioni nella pagina a loro dedicata sul APP Web. Se queste informazioni includono informazioni relative alle
          allergie e informazioni di carattere dietetico, faremo del nostro meglio per ripubblicare queste informazioni sul APP Web o sull’app
          esattamente come appaiono nel menu del Ristorante. Se tu, o qualcuno per il quale stai ordinando, avete dei timori relativamente ad allergie
          alimentari, intolleranze o altre preferenze alimentari, dovresti sempre contattare direttamente il Ristorante prima di effettuare il tuo
          ordine. Ti chiediamo, per favore, di non utilizzare il campo “lascia una nota per il ristorante” in caso di allergie o intolleranze
          alimentari, per favore contatta direttamente il Ristorante. È importante che tu parli direttamente con il Ristorante che preparerà il cibo
          per assicurarti che il tuo ordine sia indicato alle tue esigenze. 11.3. Azioni ed omissioni del Ristorante: Il contratto per la fornitura e
          l'acquisto di Prodotti intercorre tra l'utente ed il Ristorante cui l'utente inoltra l'Ordine. RWL non ha alcun controllo sulle azioni o
          omissioni di alcuno dei Ristoranti. Senza che ciò costituisca un limite alla validità generale di quanto sopra, e fatto salvo il caso di
          dolo e colpa grave di RWL, con l'utilizzo dell’APP l'utente accetta quanto segue: 11.3.1. RWL non assume alcun impegno di garantire che la
          qualità dei Prodotti ordinati da uno dei Ristoranti risulti soddisfacente o che i Prodotti siano idonei per i fini dell'utente ed
          espressamente esclude ogni garanzia di questo tipo. 11.3.2. I tempi stimati di consegna e ritiro sono forniti dai Ristoranti e sono
          esclusivamente indicativi. Non sussiste alcuna garanzia, né da parte di RWL né dei Ristoranti, riguardo al fatto che gli Ordini verranno
          consegnati o messi a disposizione per essere ritirati entro i tempi stimati. 11.3.3. RWL incoraggia fermamente tutti i Ristoranti, per conto
          dei quali agisce come mandatario, a eseguire tutti gli Ordini e a comunicare tempestivamente eventuali rifiuti, e notifica all'utente
          (generalmente tramite e-mail) non appena sia ragionevolmente possibile, se il Ristorante abbia rifiutato di eseguire un Ordine. In ogni
          caso, RWL non garantisce che i Ristoranti eseguano tutti gli Ordini e non può escludere che i Ristoranti rifiutino gli Ordini in qualsiasi
          momento per il fatto di essere eccessivamente impegnati, se l'utente non fornisce prova dell'età per l'acquisto di bevande alcoliche,
          sigarette o altri prodotti relativi al fumo quando richiesto, a causa delle condizioni atmosferiche o per qualsiasi altra ragione. 11.3.4.
          Le precedenti clausole di esonero da responsabilità lasciano impregiudicati i diritti degli utenti previsti dalla legge e/o dal contratto di
          vendita con i Ristoranti nonché la normativa del Codice del Consumo a tutela dei consumatori. 11.4. Esclusione di altre previsioni: RWL
          fornisce all'utente accesso al APP e ne consente l'utilizzo sul presupposto che, nella misura massima consentita dalla legge, resti esclusa
          ogni e qualsiasi garanzia, dichiarazione, condizione, impegno ed ogni altra clausola in relazione al APP e all'utilizzo dello stesso da
          parte dell'utente (ivi inclusa qualsiasi dichiarazione, garanzia, condizione, impegno ed altra previsione che possa altrimenti applicarsi al
          APP o all'utilizzo dello stesso da parte dell'utente, o che sia implicitamente resa applicabile o inclusa nelle presenti Condizioni dell’APP
          ai sensi della legge applicabile o altrimenti). 12. RESPONSABILITÀ 12.1. Disposizioni generali: Nulla nel presente APP esclude o limita la
          responsabilità di RWL in caso di morte o lesioni personali derivanti da colpa di RWL, né la responsabilità di RWL in caso di dolo o colpa
          grave, né alcuna ulteriore responsabilità che non possa essere esclusa o limitata ai sensi della normativa vigente. Nulla nelle presenti
          Condizioni dell’APP influisce sui diritti inderogabili che la legge concede agli utenti dell’APP quali, a titolo esemplificativo e non
          esaustivo, i diritti garantiti ai consumatori ai sensi del Codice del Consumo. 12.2. Esclusione di responsabilità: Fatto salvo quanto
          previsto al precedente articolo 12.1, RWL non risponde nei confronti degli utenti in nessun caso, né a titolo contrattuale né
          extracontrattuale (ivi incluso per negligenza), né per violazione di obblighi imposti dalla legge né ad alcun altro titolo, anche se si
          tratti di circostanza prevedibile, derivante da, o in connessione con l’APP (ivi incluso l'uso, l'impossibilità di utilizzare o gli effetti
          dell'uso dell’APP) in relazione a: 12.2.1. eventuale perdita di profitti, vendite, affari o reddito; 12.2.2. perdita o distruzione di dati,
          informazioni o software; 12.2.3. perdita di opportunità commerciali; 12.2.4. perdita di risparmi previsti; 12.2.5. perdita di avviamento; o
          12.2.6. qualsiasi perdita indiretta o conseguente. 12.3. Limitazione di responsabilità: Ferme restando le previsioni di cui all'articolo 11,
          all'articolo 12.1 e all'articolo 12.2 e fatta sempre salva la normativa applicabile a tutela dei consumatori, la responsabilità totale di
          RWL nei confronti degli utenti in relazione a qualsiasi altra perdita derivante da, o in connessione con l’APP o l'utilizzo dello stesso da
          parte dell'utente, sia essa a titolo contrattuale, extracontrattuale, per violazione di obblighi previsti dalla legge o a qualsiasi altro
          titolo, non può superare la somma inferiore tra il doppio del valore dell'Ordine e un importo pari a Euro 100. 12.4. Costi supplementari:
          L'utente risponde integralmente e in via esclusiva per qualsiasi costo supplementare o correlato in cui possa incorrere durante l'uso
          dell’APP o in conseguenza di esso, ivi incluso, a titolo esemplificativo e non esaustivo, i costi relativi all'assistenza, riparazione o
          adattamento di eventuali dispositivi, software o dati di proprietà dell'utente o di cui l'utente abbia la proprietà o il possesso per
          leasing o licenza o che siano ad altro titolo utilizzati dall'utente. 13. RISOLUZIONE 13.1. Cause di risoluzione: ai sensi dell'art. 1456
          del Codice Civile, RWL ha facoltà di risolvere il rapporto contrattuale con l'utente e sospendere il diritto dell'utente di avvalersi
          dell’APP, immediatamente, mediante comunicazione scritta (ivi compreso la comunicazione via e-mail), nel caso in cui RWL ritenga, a suo
          esclusivo giudizio: 13.1.1. che l'utente abbia utilizzato l’APP in violazione dell'articolo 7.1 (Licenza d'uso); 13.1.2. che l'utente abbia
          pubblicato sul APP Recensioni o altro Materiale degli Utenti che viola le previsioni di cui agli articoli 9.2 o 9.3 (Materiale dei
          Visitatori e Recensioni); 13.1.3. che l'utente abbia violato l'articolo 10.2 (Link Verso e da Altri Siti); o 13.1.4. che l'utente abbia
          omesso di effettuare il pagamento di un Ordine i cui prodotti siano stati consegnati dal Ristorante o abbia violato qualsiasi altra
          previsione essenziale delle presente Condizioni dell’APP. 13.2. Obblighi dell'utente in caso di risoluzione: All'atto della risoluzione o
          sospensione l'utente è tenuto a distruggere immediatamente qualsiasi estratto dell’APP che sia stato eventualmente scaricato o stampato. 14.
          COMUNICAZIONI SCRITTE 14.1. La normativa vigente può richiedere che alcune informazioni ed avvisi, tra quelli che RWL fornisce all'utente,
          debbano essere comunicati per iscritto. Con l'utilizzo dell’APP o l'effettuazione di Ordine di Prodotti tramite l’APP, l'utente accetta che
          la comunicazione con RWL avvenga principalmente per via elettronica. In particolare, l'utente accetta di poter essere contattato tramite
          e-mail o che gli vengono fornite informazioni tramite pubblicazione di post di avviso sul APP. Per le finalità contrattuali, l'utente
          accetta tali modalità elettroniche di comunicazione. La presente clausola non inficia i diritti che la legge prevede in favore degli utenti
          dell’APP. 15. EVENTI NON CONTROLLABILI DA RWL 15.1. RWL non risponde di, e non è tenuta a risarcire, alcun eventuale inadempimento o
          adempimento tardivo di proprie obbligazioni contrattuali previste dalle presenti Condizioni dell’APP che sia provocato da eventi al di fuori
          della ragionevole possibilità di controllo da parte di RWL ("Eventi di Forza Maggiore"). 15.2. Un Evento di Forza Maggiore include qualsiasi
          atto, evento, mancato avveramento, omissione o incidente che vada oltre le ragionevoli possibilità di controllo da parte di RWL ed include
          in particolare (a titolo esemplificativo e non esaustivo) quanto segue: 15.2.1. scioperi, serrate o altre azioni nell'ambito di rapporti tra
          dipendenti e aziende; 15.2.2. tumulti civili, sommosse, invasioni, attacchi terroristici o minacce di attacco terroristico, guerre (anche in
          assenza di apposita dichiarazione) o minacce di guerra nonché preparativi per la guerra; 15.2.3. incendi, esplosioni, tempeste, inondazioni,
          terremoti, cedimenti del terreno (inclusa la subsidenza), epidemie o altri disastri naturali; 15.2.4. impossibilità di servirsi della rete
          ferroviaria, navale, aerea, del trasporto su gomma o di altri mezzi di trasporto pubblico o privato; 15.2.5. impossibilità di servirsi delle
          reti di comunicazione pubblica o privata; e 15.2.6. leggi, decreti, normativa, regolamentazione o disciplina restrittiva di un qualsiasi
          governo. 15.3. L'esecuzione da parte di RWL delle proprie obbligazioni contrattuali previste dalle presenti Condizioni dell’APP si considera
          sospesa per tutta la durata dell'Evento di Forza Maggiore e RWL usufruisce di una proroga della durata prevista per l'adempimento pari alla
          durata del suddetto periodo. RWL si adopera con ogni sforzo ragionevole per giungere al termine dell'Evento di Forza Maggiore o trovare una
          soluzione per cui le obbligazioni contrattuali di RWL possano essere adempiute nonostante l'Evento di Forza Maggiore. 16. CLAUSOLE VARIE ED
          ULTERIORI 16.1. Informativa in materia di Privacy: RWL si obbliga a proteggere la riservatezza e sicurezza degli utenti. Tutti i dati
          personali degli utenti raccolti da RWL vengono elaborati in conformità alla Informativa di RWL in materia di Privacy. L'utente dell’APP è
          tenuto ad esaminare la Informativa in materia di Privacy, la quale costituisce, in forza del presente richiamo, parte integrante delle
          Condizioni to dell’APP ed è disponibile al seguente link. 16.2. Invalidità parziale: Qualora uno o più termini o condizioni delle presenti
          Condizioni dell’APP vengano dichiarati invalidi, nulli o inefficaci, in tutto o per una parte qualsiasi, l'invalidità, la nullità o
          l'inefficacia riguarderà solo tali termini, condizioni o previsioni e restanti termini resteranno validi ed efficaci nella misura massima
          consentita dalla legge. 16.3. Unicità dell'accordo: le presenti Condizioni dell’APP ed ogni altro documento in esse esplicitamente
          richiamato costituisce l'intero accordo tra RWL e l'utente e sostituisce tutte le precedenti discussioni, la corrispondenza, le trattative,
          qualsiasi accordo precedente, intesa o contratto tra le parti in relazione all'oggetto del contratto. 16.4. Rinunce: L'eventuale mancata o
          tardiva attuazione (in tutto o in parte) di una qualsiasi previsione delle presenti Condizioni dell’APP non può essere interpretata come
          rinuncia dell'una o dell'altra parte a far valere i propri diritti o rimedi. 16.5. Cessione: L'utente dell’APP non può cedere alcun diritto
          o obbligo previsto dalle presenti Condizioni dell’APP senza il preventivo consenso scritto di RWL, neanche in caso di cessione di azienda o
          di ramo d'azienda. RWL può cedere qualsiasi diritto o obbligo previsto dalle presenti Condizioni dell’APP ad una società sua affiliata o ad
          una qualsiasi impresa con cui essa si associ per svolgere l'attività imprenditoriale, che essa acquisisca o cui essa sia venduta, anche in
          caso di cessione di azienda o di ramo d'azienda e purché a seguito della cessione non risulti diminuita la tutela dei diritti dei
          consumatori. 16.6. Rubriche: Le rubriche degli articoli del presente Regolamento dell’APP sono previste esclusivamente per comodità e non
          influiscono in alcun modo sulla loro interpretazione. 17. LEGGE APPLICABILE E FOTO COMPETENTE 17.1 Legge Applicabile: tutti i reclami e le
          controversie che dovessero sorgere in relazione a o ai sensi delle presenti Condizioni dell’APP saranno regolati e interpretate ai sensi
          della legge italiana. Per i consumatori residenti in Italia, troverà applicazione la normativa inderogabile a tutela del consumatore
          prevista dalla legge italiana (Codice del Consumo). 17.2 Foro competente: qualsiasi controversia derivante dalle presenti Condizioni
          dell’APP riguardante utenti non consumatori, sarà devoluta alla competenza esclusiva del foro di Brindisi. In caso di controversia derivante
          dalle presenti Condizioni dell’APP riguardante utenti consumatori, i consumatori potranno adire i tribunali (i) del foro di Brindisi o (ii)
          del luogo dove hanno la loro residenza o domicilio in Italia. RWL e i Ristoranti/Esercenti potranno instaurare una causa nei confronti di un
          consumatore residente in Italia solamente presso il foro di residenza o domicilio del consumatore. In caso di controversia, l'utente è
          incoraggiato a contattare in primo luogo il Servizio Clienti per trovare una soluzione. 17.3 Risoluzione delle controversie online:
          Alternativamente alla risoluzione giudiziale delle controversie, il consumatore può inoltrare il suo reclamo presso la Piattaforma di
          Risoluzione Online per la risoluzione delle Controversie in via stragiudiziale (Piattaforma ODR Europea) (Art. 14, par. 1 del Regolamento UE
          524/2013). Per ulteriori informazioni in merito alle Piattaforma ODR Europea o per inoltrare un reclamo e iniziare procedure alternative per
          controversie relative al presente contratto, è possibile utilizzare il seguente link: https://ec.europa.eu/odr.
        </span>
      </ResponsiveModal>
    </Container>
  );
};

export default LegalAreaContainer;
