import React, { useContext } from 'react';
import { Input } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import { LanguageContext } from '../../contexts/LanguageContext';
import InputWithLabel from '../shared/InputWithLabel';
import Button from '../shared/Button';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../translator';
import { textTranslateUppercase } from '../../utils/functions';
import { ROUTE_FORGOT_PASSWORD } from '../../router/routes';
import { theme } from '../../styles/theme';
import PasswordInput from '../shared/PasswordInput';

const Container = styled.form`
  margin: 0px 20px;
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.16);
  background-color: white;
  font-family: 'Optima';
  margin-top: 50%;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-top: 45%;
  }
`;

const SignIn = styled.div`
  display: flex;
  margin-top: 33px;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.primaryColor};
`;

const FormContainer = styled.div`
  margin: 0px 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 36px;
`;

const LoginBox = ({ onSignInSubmit }) => {
  const history = useHistory();
  const { language } = useContext(LanguageContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(capitalizeFirstLetter(translate('invalid-email', language)))
      .required(capitalizeFirstLetter(translate('required', language))),
    password: Yup.string().required('required')
  });

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSignInSubmit(values.email, values.password);
        setSubmitting(false);
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, touched }) => (
        <Container onSubmit={handleSubmit}>
          <SignIn>{textTranslateUppercase('sign-in')}</SignIn>
          <FormContainer>
            <InputWithLabel label="email" error={touched.email && errors.email} labelStyle={{ fontWeight: 'bold', fontFamily: 'Optima' }}>
              <Input
                fluid
                name="email"
                value={values.email}
                error={touched.email && !!errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={capitalizeFirstLetter(translate('email', language))}
              />
            </InputWithLabel>
            <InputWithLabel
              label="password"
              error={touched.password && errors.password}
              containerStyle={{ marginTop: 15 }}
              labelStyle={{ fontWeight: 'bold', fontFamily: 'Optima' }}
            >
              <PasswordInput
                fluid
                name="password"
                error={touched.password && !!errors.password}
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={capitalizeFirstLetter(translate('password', language))}
              />

              {/* <Input
                fluid
                name="password"
                type="password"
                error={touched.password && !!errors.password}
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={capitalizeFirstLetter(translate('password', language))}
              /> */}
            </InputWithLabel>
          </FormContainer>
          <ButtonsContainer>
            <TranslateTextComponent
              capitalize
              onClick={() => history.push(ROUTE_FORGOT_PASSWORD)}
              style={{ textDecoration: 'underline', fontSize: 12, fontWeight: 'bold', color: `${theme.colors.darkGray}` }}
            >
              forgot-password
            </TranslateTextComponent>
            <Button style={{ width: 163, marginTop: 20 }} type="submit">
              login
            </Button>
          </ButtonsContainer>
        </Container>
      )}
    </Formik>
  );
};

export default LoginBox;
