import React, { useContext, useEffect, useState } from 'react';
import { Divider, Input, TextArea } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import useCustomQuery from '../../hooks/useCustomQuery';
import { GET_GUEST_PROFILES } from '../../graphql/profiles/queries';
import { GET_ROOMS } from '../../graphql/roombuilding/queries';
import { LanguageContext } from '../../contexts/LanguageContext';
import { ServiceCartContext } from '../../contexts/ServiceCartContext';
import BoldLabel from '../shared/BoldLabel';
import InputWithLabel from '../shared/InputWithLabel';
import SearchBar from '../shared/SearchBar';
import Container from '../shared/Container';
import { capitalizeFirstLetter, translate, TranslateTextComponent } from '../../translator';
import { theme } from '../../styles/theme';

const InfoContainer = styled.div`
  padding: 9.5px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  margin-top: 10px;
  height: 38px;
`;

const BookServicesCheckOutContent = () => {
  const { serviceCart, onSetProfile, onSetNewProfile, onSetServiceCartByKey } = useContext(ServiceCartContext);
  const { language } = useContext(LanguageContext);

  const [guestProfilesQueryFilters, setGuestProfilesQueryFilters] = useState({ keyword: '', roomNumber: '' });
  const [roomSearchValue, setRoomSearchValue] = useState('');

  const getGuestProfiles = useCustomQuery(GET_GUEST_PROFILES, {
    variables: guestProfilesQueryFilters,
    skip: !guestProfilesQueryFilters?.keyword
  });
  const getRooms = useCustomQuery(GET_ROOMS, { variables: { page: 1, pageSize: 500, keyword: roomSearchValue }, skip: !roomSearchValue });

  const onSetGuestProfilesQueryFilters = (key, value) => {
    setGuestProfilesQueryFilters((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    if (serviceCart?.profile) {
      onSetGuestProfilesQueryFilters('keyword', serviceCart?.profile?.title);
    }
    if (serviceCart?.room) {
      setRoomSearchValue(`${serviceCart?.room?.number} - ${serviceCart?.room?.name}`);
      onSetGuestProfilesQueryFilters('roomNumber', serviceCart?.room?.number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container style={{ paddingTop: 20, paddingBottom: 20 }}>
      <BoldLabel label="booking-details" style={{ fontSize: 25 }} />
      <div style={{ marginTop: 20 }}>
        <BoldLabel label="booking-date" />
        <InfoContainer>{serviceCart?.date}</InfoContainer>

        <InputWithLabel label="select-room" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold', marginTop: 20 }}>
          <SearchBar
            fluid
            placeholder="room"
            noShadow
            containerStyle={{ width: '100%' }}
            results={
              getRooms.data?.getRooms?.data
                ?.sort((a, b) => a.number - b.number)
                ?.filter(
                  (room) =>
                    room?.number.toLowerCase().includes(roomSearchValue.toLowerCase()) ||
                    room?.name.toLowerCase().includes(roomSearchValue?.replace(/\s/g, '')?.toLowerCase())
                )
                ?.map((room) => ({
                  key: room.uuid,
                  title: `${room.number} - ${room.name}  `,
                  ...room
                })) ?? []
            }
            loading={getRooms.loading}
            value={roomSearchValue}
            onResultSelect={(e, { result }) => {
              onSetServiceCartByKey('room', result);
              setRoomSearchValue(result.title);
              onSetGuestProfilesQueryFilters('roomNumber', result.number);
            }}
            onChange={(e, { value }) => {
              setRoomSearchValue(value);
              if (value.length === 0) onSetGuestProfilesQueryFilters('roomNumber', '');
            }}
          />
        </InputWithLabel>

        <InputWithLabel label="select-profile" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold', marginTop: 20 }}>
          <SearchBar
            fluid
            placeholder="profile"
            noShadow
            containerStyle={{ width: '100%' }}
            results={
              getGuestProfiles.data?.getGuestProfiles?.data?.map((p) => ({
                key: p.uuid,
                title: `${p.firstName} ${p.lastName} ${p?.currentCheckin?.room?.number ? ` - ${p?.currentCheckin?.room?.number}` : ''}`,
                uuid: p.uuid,
                currentcheckin: p?.currentCheckin,
                arrangement: p?.currentCheckin?.arrangement
              })) ?? []
            }
            loading={getGuestProfiles.loading}
            value={guestProfilesQueryFilters?.keyword}
            onResultSelect={(e, { result }) => {
              if (result?.currentcheckin) {
                setRoomSearchValue(`${result.currentcheckin.room.number} - ${result.currentcheckin.room.name}`);
              } else {
                setRoomSearchValue('');
              }
              onSetGuestProfilesQueryFilters('keyword', result.title);
              onSetProfile(result);
            }}
            onChange={(e, { value }) => {
              onSetGuestProfilesQueryFilters('keyword', value);
            }}
          />
        </InputWithLabel>

        <Divider horizontal>
          <TranslateTextComponent>or</TranslateTextComponent>
        </Divider>

        <BoldLabel label="create-guest-profile" />

        <Input
          fluid
          value={serviceCart?.newProfile?.firstName ?? ''}
          placeholder={capitalizeFirstLetter(translate('firstName', language))}
          style={{ marginTop: 10 }}
          onChange={(e) => {
            onSetNewProfile('firstName', e.target.value);
            onSetGuestProfilesQueryFilters('keyword', '');
          }}
        />

        <Input
          fluid
          value={serviceCart?.newProfile?.lastName ?? ''}
          placeholder={capitalizeFirstLetter(translate('lastName', language))}
          style={{ marginTop: 20 }}
          onChange={(e) => {
            onSetNewProfile('lastName', e.target.value);
            onSetGuestProfilesQueryFilters('keyword', '');
          }}
        />
        {serviceCart?.profile?.currentcheckin?.arrangement && (
          <div style={{ marginTop: 20 }}>
            <BoldLabel label="arrangement" />
            <InfoContainer>{serviceCart?.profile?.currentcheckin?.arrangement}</InfoContainer>
          </div>
        )}

        <InputWithLabel label="notes" labelStyle={{ fontSize: 20, fontFamily: 'optima-bold', marginTop: 20 }}>
          <TextArea
            value={serviceCart?.notes ?? ''}
            placeholder={capitalizeFirstLetter(translate('notes', language))}
            onChange={(e) => {
              onSetServiceCartByKey('notes', e.target.value);
            }}
            style={{
              width: '100%',
              border: '1px solid rgba(34, 36, 38, 0.15)',
              padding: 9.5,
              minHeight: 72,
              color: theme.colors.primaryColor,
              fontSize: 13
            }}
          />
        </InputWithLabel>
      </div>
    </Container>
  );
};

export default BookServicesCheckOutContent;
