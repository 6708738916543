import React from 'react';
import { TranslateTextComponent } from '../../translator';

const BoldLabel = ({ label, color, capitalize = true, uppercase, style, fontSize = 20, fontFamily = 'optima-bold' }) => {
  return (
    <TranslateTextComponent style={{ fontSize, fontFamily, color, ...style }} capitalize={capitalize} uppercase={uppercase}>
      {label}
    </TranslateTextComponent>
  );
};

export default BoldLabel;
