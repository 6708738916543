import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';
import { OutletCategoriesContext } from '../../contexts/OutletCategoriesContext';
import ImageCard from '../shared/ImageCard';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../translator';
import { ROUTE_OUTLETS, ROUTE_RESERVATIONS_SUMMARY } from '../../router/routes';

const OutletCategories = () => {
  const history = useHistory();
  const { language } = useContext(LanguageContext);
  const { categories } = useContext(OutletCategoriesContext);

  return (
    <div style={{ marginTop: 15 }}>
      <TranslateTextComponent capitalize style={{ fontFamily: 'optima-bold', fontSize: 25 }}>
        categories
      </TranslateTextComponent>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          marginTop: 10
        }}
      >
        {categories?.length > 0 &&
          categories
            .sort((prev, next) => prev.order > next.order)
            .map((category, index) => (
              <ImageCard
                key={index}
                backgroundImage={category?.imagePath ? `${process.env.REACT_APP_SERVER_URL}${category?.imagePath}` : category?.image}
                text={capitalizeFirstLetter(translate(category.name, language))}
                subText={
                  category.description !== null &&
                  category.description !== '' && (
                    <TranslateTextComponent style={{ color: 'white', fontSize: 13, fontFamily: 'optima-regular' }}>
                      {category.description}
                    </TranslateTextComponent>
                  )
                }
                cardStyle={{
                  marginBottom: 15,
                  width: category?.isFullWidthInApp ? '100%' : 'calc(50% - 8px)',
                  marginRight: !category?.isFullWidthInApp && index % 2 !== 0 ? 15 : 0
                }}
                onClick={() =>
                  category?.uuid ? history.push(ROUTE_OUTLETS.replace(':uuid', category?.uuid)) : history.push(ROUTE_RESERVATIONS_SUMMARY)
                }
              />
            ))}
      </div>
    </div>
  );
};

export default OutletCategories;
