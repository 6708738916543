/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';
import { FB_ORDERS_FRAGMENT, ORDER_SMART_SERVICE_FRAGMENT, PROFILE_FRAGMENT } from '../fragments';

export const GET_STAFF_USERS_SMART_ORDERS = gql`
  query getStaffUserSmartOrders(
    $page: Int
    $pageSize: Int
    $orderBy: String
    $orderType: String
    $fbOutletUUID: String
    $createdByCurrentUser: Boolean
    $keyword: String
    $date: String
    $type: OrderTypes
    $types: [OrderTypes!]
    $status: FbOrdersStatuses
    $statuses: [FbOrdersStatuses!]
    $profileUUID: String
  ) {
    getStaffUserSmartOrders(
      page: $page
      pageSize: $pageSize
      orderBy: $orderBy
      orderType: $orderType
      fbOutletUUID: $fbOutletUUID
      createdByCurrentUser: $createdByCurrentUser
      keyword: $keyword
      date: $date
      type: $type
      types: $types
      status: $status
      statuses: $statuses
      profileUUID: $profileUUID
    ) {
      totalCount
      data {
        ...fbOrdersFragment
        profile {
          ...profileFragment
        }
      }
    }
  }
  ${FB_ORDERS_FRAGMENT}
  ${PROFILE_FRAGMENT}
`;

export const GET_SMART_ORDER_AVAILABLE_SLOTS = gql`
  query getSmartOrderAvailableSlots($fbOutletUUID: String!, $type: OrderTypes!, $date: String!) {
    getSmartOrderAvailableSlots(fbOutletUUID: $fbOutletUUID, type: $type, date: $date) {
      time
      isAvailable
    }
  }
`;

export const GET_FB_ORDER = gql`
  query getFbOrder($fbOrderUUID: String!) {
    getFbOrder(fbOrderUUID: $fbOrderUUID) {
      ...fbOrdersFragment
      profile {
        ...profileFragment
      }
      orderSmartServices {
        ...orderSmartServiceFragment
      }
    }
  }
  ${FB_ORDERS_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${ORDER_SMART_SERVICE_FRAGMENT}
`;
