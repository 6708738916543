import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import orderBy from 'lodash/orderBy';
import { TranslateTextComponent } from '../../translator';
import { getOutletStatus } from '../../utils/outlet';
import { ROUTE_FB_OUTLET } from '../../router/routes';
import { theme } from '../../styles/theme';
import icons from '../../assets/images/physical-categories';

const Container = styled.div`
  padding: 10px 18px;
  margin-bottom: 15px;
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.16);
  cursor: pointer;
`;

const OutletName = styled.span`
  font-family: optima-bold;
  font-size: 24px;
  margin-bottom: 10px;
`;

const Status = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50px;
  background-color: ${({ status }) => (status === 'open' ? theme.colors.green : theme.colors.errorColor)};
`;

const RowContainer = styled.div`
  flex: 1;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  font-size: 12px;
  align-items: center;
`;

const CategoryName = styled(TranslateTextComponent)`
  padding: 0px 5px;
`;

const Text = styled.span`
  font-family: optima-regular;
`;

const getPhysicalCategoryPax = ({ physicalCategories, categoryName }) => {
  return physicalCategories?.find(({ profilePhysicalCategory }) => profilePhysicalCategory?.name === categoryName)?.totalPax ?? 0;
};

const ReservationSummaryCard = ({ outlet, fbServices }) => {
  const history = useHistory();
  return (
    <Container style={{ ...theme.shadowStyle }} onClick={() => history.push(ROUTE_FB_OUTLET.replace(':uuid', outlet?.uuid))}>
      <Row style={{ justifyContent: 'space-between' }}>
        <OutletName>{outlet?.name}</OutletName>
        <Status status={getOutletStatus(outlet)} />
      </Row>

      {orderBy(fbServices, ['fbService.startTime'], ['ASC'])?.map((fbService, index) => (
        <RowContainer key={index}>
          <Text style={{ flex: 0.5 }}>
            {fbService?.fbService?.name} ({fbService?.totalPax})
          </Text>

          <Row style={{ justifyContent: 'space-between' }}>
            <Row style={{ justifyContent: 'center' }}>
              <img src={icons.person} alt="person" />
              <CategoryName>adults</CategoryName>
              <Text>{getPhysicalCategoryPax({ physicalCategories: fbService?.profilePhysicalCategories, categoryName: 'adults' })}</Text>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              <img src={icons.child_friendly} alt="child_friendly" />
              <CategoryName>kids</CategoryName>
              <Text>{getPhysicalCategoryPax({ physicalCategories: fbService?.profilePhysicalCategories, categoryName: 'kids' })}</Text>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              <img src={icons.child_care} alt="child_care" />
              <CategoryName>infants</CategoryName>
              <Text>{getPhysicalCategoryPax({ physicalCategories: fbService?.profilePhysicalCategories, categoryName: 'enfants' })}</Text>
            </Row>
          </Row>
        </RowContainer>
      ))}
    </Container>
  );
};

export default ReservationSummaryCard;
