/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';

export const LOGIN = gql`
  query login($loginInput: LoginInputDto!) {
    login(loginInput: $loginInput) {
      id
      username
      firstName
      lastName
      email
      access_token
      errors {
        message
      }
      roles {
        id
        uuid
        name
        description
      }
    }
  }
`;

export const FORGOT_PASSWORD_REQUEST = gql`
  query forgotPasswordRequest($email: String!) {
    forgotPasswordRequest(email: $email) {
      id
    }
  }
`;
