/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';
import { TICKET_FRAGMENT } from '../fragments';

export const SAVE_TICKET = gql`
  mutation saveTicket($ticketData: TicketInputDto!, $buildingUUID: String, $ticketTypeUUID: String!, $ticketUUID: String) {
    saveTicket(ticketData: $ticketData, buildingUUID: $buildingUUID, ticketTypeUUID: $ticketTypeUUID, ticketUUID: $ticketUUID) {
      ...ticketFragment
    }
  }
  ${TICKET_FRAGMENT}
`;
