import { useMutation } from '@apollo/react-hooks';
import React, { useEffect, createContext, useState } from 'react';
import { ADD_FB_RESERVATION } from '../graphql/outlets/mutations';

export const BookContext = createContext();

const initialState = {
  date: null,
  fbGuestList: { adults: [], kids: [], enfants: [] },
  fbService: null,
  profile: null,
  room: null,
  newProfile: null
};

export const BookProvider = ({ children }) => {
  const [addFbReservation] = useMutation(ADD_FB_RESERVATION);

  const [booking, setBooking] = useState(JSON.parse(localStorage.getItem('booking')) || initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    localStorage.setItem('booking', JSON.stringify(booking));
  }, [booking]);

  const onSetBooking = (book) => {
    setBooking(book);
  };

  const onSetBookingByKey = (key, value) => {
    const book = { ...booking, [key]: value };
    setBooking(book);
  };

  const onSetProfile = (profile) => {
    onSetBooking({
      ...booking,
      profile,
      newProfile: null,
      room: profile?.currentcheckin?.room
    });
  };

  const onSetNewProfile = (key, value) => {
    const updatedNewProfile = { ...booking?.newProfile };
    updatedNewProfile[key] = value;
    onSetBooking({
      ...booking,
      newProfile: updatedNewProfile,
      profile: null
    });
  };

  const resetBook = () => {
    setBooking(initialState);
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    return addFbReservation({
      variables: {
        fbServiceUUID: booking?.fbService?.fbServiceUUID,
        fbReservationsData: {
          date: booking.date,
          reservationTime: booking?.fbService?.time,
          notes: booking?.notes,
          fbGuestList: Array.prototype.concat.apply(
            [],
            Object.keys(booking.fbGuestList).map((gpcKEY) => booking.fbGuestList[gpcKEY].map((fbGuest) => ({ guestPhysicalCategoryId: fbGuest })))
          ),
          guestRoom: booking?.room?.number
        },
        fbGuestUUID: booking?.profile?.uuid,
        createProfile: booking?.newProfile
      }
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <BookContext.Provider
      value={{
        booking,
        onSetBooking,
        onSetBookingByKey,
        onSetProfile,
        onSetNewProfile,
        resetBook,
        isSubmitting,
        onSubmit
      }}
    >
      {children}
    </BookContext.Provider>
  );
};

export const BookConsumer = BookContext.Consumer;
