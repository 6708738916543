import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import moment from 'moment';
import useCustomQuery from '../../hooks/useCustomQuery';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import { IS_SMART_SERVICE_AVAILABLE } from '../../graphql/services/queries';
import { LanguageContext } from '../../contexts/LanguageContext';
import { ServiceCartContext } from '../../contexts/ServiceCartContext';
import { ModalContext } from '../../contexts/ModalContext';
import FooterButton from '../shared/FooterButton';
import Tabs from '../shared/Tabs';
import ServicesTab from '../outlets/tabs/ServicesTab';
import BottomSheet from '../shared/BottomSheet';
import { capitalizeFirstLetter, translate, TranslateTextComponent } from '../../translator';
import { ROUTE_CART_INFO } from '../../router/routes';

const Container = styled.div`
  padding-top: ${({ theme }) => theme.bannerHeight}px;
  position: relative;
`;

const BookServicesContent = ({ outlet }) => {
  const history = useHistory();

  const isSmartServiceAvailableRes = useCustomQuery(IS_SMART_SERVICE_AVAILABLE, {
    skip: true
  });

  const modalContext = useContext(ModalContext);
  const { language } = useContext(LanguageContext);
  const { serviceCart, checkIfCartItemQuantityIsMinorOfMaxQuantity, addService, isSameOutlet } = useContext(ServiceCartContext);

  const [currentTab, setCurrentTab] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [queryFilters, setQueryFilters] = useState({ date: moment().format('YYYY-MM-DD'), fbOutletUUID: outlet?.uuid });

  const onSetQueryFilters = (key, value) => {
    setQueryFilters((prev) => ({ ...prev, [key]: value }));
  };

  const updateSelectedItem = (item) => {
    if (item.id === selectedItem?.id) {
      setSelectedItem(null);
    } else {
      setSelectedItem({ ...item, quantity: 1 });
    }
  };
  const tabs = [
    {
      menuItem: capitalizeFirstLetter(translate('services', language)),
      render: () => <ServicesTab updateSelectedItem={updateSelectedItem} queryFilters={queryFilters} onSetQueryFilters={onSetQueryFilters} />
    }
  ];

  const updateQuantity = (operator) => {
    if (operator === '+') {
      setSelectedItem({
        ...selectedItem,
        quantity: selectedItem?.quantity + 1
      });
    } else {
      setSelectedItem({
        ...selectedItem,
        quantity: selectedItem?.quantity - 1
      });
    }
  };

  const getServiceAvailability = (currentOutlet, reset) => {
    isSmartServiceAvailableRes
      .refetch({
        date: queryFilters?.date,
        quantity: selectedItem?.quantity,
        smartServiceUUID: selectedItem?.uuid
      })
      .then((res) => {
        const { maxQuantity } = res.data.isSmartServiceAvailable;
        if (maxQuantity > 0 && selectedItem?.quantity <= maxQuantity && checkIfCartItemQuantityIsMinorOfMaxQuantity(selectedItem?.id, maxQuantity)) {
          addService(selectedItem, currentOutlet, queryFilters?.date, reset);
          setSelectedItem(null);
        } else {
          modalContext.openModal({
            title: 'error',
            text: <TranslateTextComponent message="max-quantity-message" vars={{ maxQuantity }} />
          });
        }
      })
      .catch((err) => {
        modalContext.openModal({
          title: 'error',
          text: getApolloErrors(err)?.[0]?.toLowerCase().replace(/\s/g, '-') ?? ''
        });
      });
  };

  const checkCart = (currentOutlet) => {
    if (!isSameOutlet(currentOutlet) || (serviceCart?.date && queryFilters?.date !== serviceCart?.date && serviceCart?.services.length > 0)) {
      modalContext.openModal({
        title: 'do-you-want-create-a-new-order-title',
        text: 'empty-cart-message',
        buttons: [
          {
            label: 'cancel',
            onClick: () => modalContext.closeModal()
          },
          {
            label: 'confirm',
            onClick: () => {
              modalContext.closeModal();
              getServiceAvailability(currentOutlet, true);
            }
          }
        ]
      });
    } else {
      getServiceAvailability(currentOutlet);
    }
  };

  const onAddServiceToCart = () => {
    const currentOutlet = {
      name: outlet.name,
      uuid: outlet.uuid,
      phoneNumber: outlet.phoneNumber
    };
    checkCart(currentOutlet);
  };

  return (
    <Container>
      <Tabs
        tabs={tabs}
        activeIndex={currentTab}
        onTabChange={(e, { activeIndex }) => {
          setCurrentTab(activeIndex);
        }}
        menu={{ secondary: true, pointing: true, className: 'full-width' }}
      />

      <FooterButton
        text="go-to-cart"
        disabled={!serviceCart?.date || serviceCart?.service?.length <= 0}
        onClick={() => history.push(ROUTE_CART_INFO)}
      />
      <BottomSheet
        item={selectedItem}
        closeBottomSheet={() => setSelectedItem(null)}
        updateQuantity={(operator) => updateQuantity(operator)}
        onClick={onAddServiceToCart}
      />
    </Container>
  );
};

export default BookServicesContent;
