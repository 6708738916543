import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as AddIcon } from '../../assets/images/action-icons/add.svg';
import { ReactComponent as LessIcon } from '../../assets/images/action-icons/less.svg';

const Container = styled.div`
  border-bottom: 1px solid #ecebec;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const ItemCount = styled.span`
  font-family: optima-medium;
  font-size: 20px;
`;

const OperatorButton = styled.div`
  cursor: pointer;
`;

const StepperCounter = ({ quantity, containerStyle, onAdd, onRemove }) => {
  return (
    <Container style={containerStyle}>
      <OperatorButton onClick={onRemove}>
        <LessIcon />
      </OperatorButton>
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <ItemCount>{quantity}</ItemCount>
      </div>
      <OperatorButton onClick={onAdd}>
        <AddIcon />
      </OperatorButton>
    </Container>
  );
};

export default StepperCounter;
