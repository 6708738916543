/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';
import { COMPLAINT_FRAGMENT, PROFILE_FRAGMENT } from '../fragments';

export const GET_COMPLAINT_TYPES = gql`
  query getComplaintTypes($orderBy: String, $orderType: String, $page: Int, $pageSize: Int) {
    getComplaintTypes(orderBy: $orderBy, orderType: $orderType, page: $page, pageSize: $pageSize) {
      totalCount
      data {
        id
        uuid
        name
        complaints {
          id
          uuid
          status
          priority
        }
      }
    }
  }
`;

export const GET_COMPLAINTS = gql`
  query getComplaints(
    $orderBy: String
    $orderType: String
    $page: Int
    $pageSize: Int
    $profileUUID: String
    $roomUUID: String
    $priority: PriorityLevels
    $status: GenericStatuses
    $complaintTypeUUID: String
    $startDate: String
    $endDate: String
    $createdByUUID: String
    $keyword: String
  ) {
    getComplaints(
      orderBy: $orderBy
      orderType: $orderType
      page: $page
      pageSize: $pageSize
      profileUUID: $profileUUID
      roomUUID: $roomUUID
      priority: $priority
      status: $status
      complaintTypeUUID: $complaintTypeUUID
      startDate: $startDate
      endDate: $endDate
      createdByUUID: $createdByUUID
      keyword: $keyword
    ) {
      totalCount
      data {
        ...complaintFragment
        profile {
          ...profileFragment
        }
      }
    }
  }
  ${COMPLAINT_FRAGMENT}
  ${PROFILE_FRAGMENT}
`;

export const GET_COMPLAINT = gql`
  query getComplaint($complaintUUID: String) {
    getComplaint(complaintUUID: $complaintUUID) {
      ...complaintFragment
    }
  }
  ${COMPLAINT_FRAGMENT}
`;
