/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';

export const ADD_FB_RESERVATION = gql`
  mutation addFbReservation(
    $fbReservationsData: FbReservationInput!
    $createProfile: CreateIndividualProfileDto
    $channelUUID: String
    $packageUUID: String
    $fbGuestUUID: String
    $fbServiceUUID: String!
  ) {
    addFbReservation(
      fbReservationsData: $fbReservationsData
      createProfile: $createProfile
      channelUUID: $channelUUID
      packageUUID: $packageUUID
      fbGuestUUID: $fbGuestUUID
      fbServiceUUID: $fbServiceUUID
      channelName: BE_GUEST
    ) {
      id
      uuid
      date
      reservationTime
      status
      bookedBy {
        id
        uuid
        firstname
        lastname
        gender
      }
      fbService {
        id
        uuid
        name
        isActive
        fbOutlet {
          id
          uuid
          name
        }
      }
      fbGuestList {
        id
        uuid
        guestPhysicalCategoryId
      }
    }
  }
`;
