import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { LanguageContext } from '../../contexts/LanguageContext';
import { theme } from '../../styles/theme';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../translator';
import { textTranslateCapitalize } from '../../utils/functions';

const Container = styled.div``;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${({ theme: { colors } }) => colors.borderColor};
`;

const Label = styled(TranslateTextComponent)`
  color: ${({ theme: { colors } }) => colors.darkGray};
  font-family: optima-bold;
  font-size: 20px;

  @media (min-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    font-size: 16px;
  }
`;

const ErrorMessage = styled(TranslateTextComponent).attrs({ capitalize: true })`
  color: ${({ theme: { colors } }) => colors.errorColor};
  font-size: 10px;
  margin-top: 3px;
`;

const CellStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 33%;
  font-size: 15px;
  padding: 10px;
  min-height: 40px;
  border-right: ${({ lastItem }) => (lastItem ? `1px solid #ECEBEC` : null)};
  background-color: ${({ selected }) => (selected ? `${theme.colors.primaryColor}` : 'white')};
  color: ${({ selected }) => (selected ? `white` : `${theme.colors.primaryColor}`)};
`;

const Selector = ({ name, containerStyle, labelStyle, options, cellStyle, selectedItem, onChange, error }) => {
  const [selectedValue, setSelectedValue] = useState(selectedItem || null);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (selectedItem) setSelectedValue(selectedItem);
    onChange(selectedValue);
  }, [selectedValue, selectedItem, onChange]);

  return (
    <Container style={containerStyle}>
      <Label capitalize style={labelStyle}>
        {name}
      </Label>
      <Grid>
        {options?.map((option, key) => {
          return (
            <CellStyle
              key={key}
              lastItem={options?.length - 1 !== key}
              selected={selectedValue === option?.value}
              style={cellStyle}
              onClick={() => {
                setSelectedValue(option?.value);
                onChange(option?.value);
              }}
            >
              {textTranslateCapitalize(option?.name ?? '')}
            </CellStyle>
          );
        })}
      </Grid>
      {error && <ErrorMessage>{capitalizeFirstLetter(translate(error, language))}</ErrorMessage>}
    </Container>
  );
};

export default Selector;
