import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { GET_ALL_AVAILABLE_SLOTS } from '../../../graphql/outlets/queries';
import { BookContext } from '../../../contexts/BookContext';
import BoldLabel from '../../shared/BoldLabel';
import SUIContainer from '../../shared/Container';
import HorizontalDateTimePicker from '../../shared/HorizontalDateTimePicker';
import GuestsPhysicalCategoriesCounter from '../../shared/GuestsPhysicalCategoriesCounter';

const Container = styled(SUIContainer)`
  padding: 20px;
`;

const BookingTab = ({ outlet }) => {
  const { booking, onSetBookingByKey } = useContext(BookContext);

  // Queries;
  const getAllAvailableSlots = useCustomQuery(GET_ALL_AVAILABLE_SLOTS, {
    variables: {
      fbOutletUUID: outlet?.uuid,
      date: booking?.date,
      fbGuestList: Object.keys(booking?.fbGuestList ?? {})
        .map((key) => booking?.fbGuestList[key])
        .flat()
        .map((id) => ({ guestPhysicalCategoryId: id }))
    },
    skip: !booking?.date || booking?.fbGuestList?.adults?.length <= 0
  });

  const getTimes = () => {
    return (
      getAllAvailableSlots.data?.getAllAvailableSlots
        ?.map((service) => service.availableSlots.map((s) => ({ ...s, fbServiceUUID: service.fbServiceUUID })))
        ?.map((availableSlots) => availableSlots)
        ?.flat()
        ?.sort((prev, next) => (prev.time < next.time ? -1 : 1))
        ?.filter(({ time }) => {
          const formattedTime = moment(time, 'HH:mm');
          const dateAndTime = moment(booking?.date, 'YYYY-MM-DD').set('hours', formattedTime.hours()).set('minutes', formattedTime.minutes());
          return moment(dateAndTime).subtract(50, 'minutes').isAfter();
        }) ?? []
    );
  };

  return (
    <Container>
      <div style={{ marginBottom: 10 }}>
        <BoldLabel label="booking-date" fontSize={25} />
      </div>
      <HorizontalDateTimePicker date={booking?.date} onChange={(value) => onSetBookingByKey('date', value)} />
      <div style={{ marginTop: 18, marginBottom: 18 }}>
        <BoldLabel label="pax" fontSize={25} />

        <GuestsPhysicalCategoriesCounter guests={booking?.fbGuestList} onChange={(value) => onSetBookingByKey('fbGuestList', value)} />
      </div>
      <HorizontalDateTimePicker
        type="time"
        date={booking?.fbService}
        times={getTimes()}
        message={getTimes().length <= 0 && booking?.fbGuestList?.adults?.length > 0 ? 'no-slots-available' : null}
        loading={getAllAvailableSlots.loading}
        onChange={(value) => onSetBookingByKey('fbService', value)}
      />
    </Container>
  );
};

export default BookingTab;
