import React from 'react';
import { theme } from '../../styles/theme';

const RoundedWarning = () => {
  return (
    <div
      style={{
        position: 'absolute',
        backgroundColor: theme.colors.errorColor,
        borderRadius: '50%',
        height: 15,
        width: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: -2,
        right: 0,
        paddingTop: 2
      }}
    >
      <span style={{ fontFamily: 'optima-bold', color: 'white' }}>!</span>
    </div>
  );
};

export default RoundedWarning;
