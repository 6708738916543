import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { TranslateTextComponent } from '../../translator';
import ImageCard from '../shared/ImageCard';
import { getOutletStatus, getPrimaryImagePath } from '../../utils/outlet';
import { ROUTE_OUTLET } from '../../router/routes';
import { theme } from '../../styles/theme';
import Spinner from '../shared/Spinner';
import Container from '../shared/Container';
import BoldLabel from '../shared/BoldLabel';

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 38px;
  background-color: white;
`;

const OutletsList = ({ outlets, loading }) => {
  const history = useHistory();

  const Footer = ({ outlet }) => {
    return (
      <FooterContainer>
        <TranslateTextComponent
          capitalize
          style={{
            fontFamily: 'optima-medium',
            fontSize: 15,
            color: getOutletStatus(outlet) === 'open' ? theme.colors.green : theme.colors.errorColor
          }}
        >
          {getOutletStatus(outlet)}
        </TranslateTextComponent>
      </FooterContainer>
    );
  };
  return (
    <Container style={{ paddingTop: theme.bannerHeight + theme.searchbarHeight + 20 }}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div style={{ marginBottom: 10 }}>
            <BoldLabel label="outlets" fontSize={25} />
          </div>
          {outlets?.map((outlet, index) => {
            return (
              <ImageCard
                key={index}
                text={outlet?.name}
                backgroundImage={`${process.env.REACT_APP_SERVER_URL}${getPrimaryImagePath(outlet.images)}`}
                footer={<Footer outlet={outlet} />}
                cardStyle={{ height: 160, marginBottom: 10 }}
                textStyle={{ justifyContent: 'flex-end', alignItems: 'flex-start' }}
                onClick={() => history.push(ROUTE_OUTLET.replace(':uuid', outlet?.uuid))}
              />
            );
          })}
        </>
      )}
    </Container>
  );
};

export default OutletsList;
