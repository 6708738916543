import React, { useState, createContext } from 'react';
import { theme } from '../styles/theme';

export const ModalContext = createContext();

const initialState = {
  open: false,
  isLoading: false,
  title: '',
  text: null,
  contentStyle: { fontFamily: 'optima-medium', fontSize: '1.3rem', color: theme.colors.darkGray },
  buttons: null
};

export const ModalProvider = (props) => {
  const [state, setState] = useState(initialState);

  const openModal = (settings) => {
    setState((prev) => ({ ...prev, ...settings, open: true }));
  };

  const setModalLoading = (isLoading) => {
    setState((prev) => ({ ...prev, isLoading }));
  };

  const closeModal = () => {
    setState(initialState);
  };

  return (
    <ModalContext.Provider
      value={{
        ...state,
        setModalLoading,
        openModal,
        closeModal
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export const ModalConsumer = ModalContext.Consumer;

export const withModalContext = (Component) => (props) => (
  <ModalConsumer>{(providerProps) => <Component {...props} modalContextProps={providerProps} />}</ModalConsumer>
);
