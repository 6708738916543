/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';
import { GUEST_PROFILES_FRAGMENT } from '../fragments';

export const GET_PROFILES = gql`
  query getProfiles($orderBy: String, $orderType: String, $page: Int, $pageSize: Int, $keyword: String) {
    getProfiles(orderBy: $orderBy, orderType: $orderType, page: $page, pageSize: $pageSize, keyword: $keyword) {
      totalCount
      data {
        id
        uuid
        firstName
        lastName
        dateOfBirth
      }
    }
  }
`;

export const GET_GUEST_PROFILES = gql`
  query getGuestProfiles($orderBy: String, $orderType: String, $page: Int, $pageSize: Int, $keyword: String, $roomNumber: String) {
    getGuestProfiles(orderBy: $orderBy, orderType: $orderType, page: $page, pageSize: $pageSize, keyword: $keyword, roomNumber: $roomNumber) {
      totalCount
      data {
        ...guestProfilesFragment
      }
    }
  }
  ${GUEST_PROFILES_FRAGMENT}
`;
