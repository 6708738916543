import React, { useState } from 'react';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import { ReactComponent as EyeCloseIcon } from '../../assets/images/login/eye-close.svg';
import { ReactComponent as EyeOpenIcon } from '../../assets/images/login/eye-open.svg';

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const EyeContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${({ isShown }) => (isShown ? 12 : 9)}px;
  right: 14px;
`;

const PasswordInput = ({ name, error, value, onBlur, onChange, placeholder }) => {
  const [inputType, setInputType] = useState('password');

  const hideShowPass = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  return (
    <Container>
      <Input fluid name={name} type={inputType} error={error} value={value} onBlur={onBlur} onChange={onChange} placeholder={placeholder} />
      <EyeContainer isShown={inputType === 'password'} onClick={hideShowPass}>
        {inputType === 'password' ? <EyeOpenIcon /> : <EyeCloseIcon />}
      </EyeContainer>
    </Container>
  );
};

export default PasswordInput;
