import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import Button from './Button';

const Container = styled.div`
  padding: 15px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.borderColor}`};
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;

  button {
    width: 100%;
  }
`;

const FooterButton = ({ text, parentMarginBottom = 67, type, children, disabled, style, onClick }) => {
  const footerRef = useRef(null);

  useEffect(() => {
    footerRef.current.parentElement.style.marginBottom = `${parentMarginBottom}px`;
  }, [parentMarginBottom]);

  return (
    <Container ref={footerRef}>
      <Button type={type} style={style} disabled={disabled} text={text} onClick={onClick}>
        {children}
      </Button>
    </Container>
  );
};

export default FooterButton;
