import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Modal as SUIModal, Button } from 'semantic-ui-react';
import { TranslateTextComponent } from '../../translator';
import { theme } from '../../styles/theme';

const HeaderTitle = styled(TranslateTextComponent).attrs({ capitalize: true })`
  font-family: roboto-bold;
  font-size: 32px;
  color: ${({ theme: { colors } }) => colors.darkGray};
`;

const getButtonStyle = (type) => {
  switch (type) {
    case 'cancel':
      return {
        backgroundColor: 'white',
        color: theme.colors.primaryColor,
        textTransform: 'uppercase',
        border: `1px solid ${theme.colors.primaryColor}`
      };
    case 'confirm':
      return {
        backgroundColor: theme.colors.primaryColor,
        color: 'white',
        textTransform: 'uppercase'
      };
    case 'submit':
      return {
        backgroundColor: theme.colors.primaryColor,
        color: 'white',
        textTransform: 'uppercase'
      };
    default:
      return { backgroundColor: theme.colors.primaryColor, color: 'white', textTransform: 'uppercase' };
  }
};

const Modal = ({
  headerTitle,
  title,
  children,
  text,
  footer,
  buttons,
  modalStyle,
  headerStyle,
  headerTitleStyle,
  contentStyle,
  footerStyle,
  params,
  closeIcon,
  dimmer,
  open,
  size,
  trigger,
  onOpen,
  onClose
}) => {
  return (
    <SUIModal
      trigger={trigger}
      {...params}
      closeIcon={closeIcon}
      dimmer={dimmer}
      open={open}
      size={size}
      style={{ borderRadius: 0, padding: '10px 0', ...modalStyle }}
      onOpen={onOpen}
      onClose={onClose}
    >
      {(headerTitle || title) && (
        <SUIModal.Header
          style={{ borderBottom: 'none', textAlign: 'center', ...headerStyle }}
          content={
            <HeaderTitle capitalize style={headerTitleStyle}>
              {headerTitle || title}
            </HeaderTitle>
          }
        />
      )}
      <SUIModal.Content>
        <div style={contentStyle}>
          {(typeof text === 'string' && <TranslateTextComponent capitalize>{text}</TranslateTextComponent>) || text}
          {children}
        </div>
      </SUIModal.Content>
      {buttons?.length > 0 ? (
        <SUIModal.Actions
          style={{
            borderTop: 'none',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: buttons.length > 1 ? 'space-between' : 'flex-end',
            ...footerStyle
          }}
        >
          {buttons.map((button, index) => (
            <Button key={index} style={{ ...getButtonStyle(button.styleType), ...button.style }} onClick={button.onClick}>
              <TranslateTextComponent>{button.label}</TranslateTextComponent>
            </Button>
          ))}
        </SUIModal.Actions>
      ) : null}
      {footer && (
        <SUIModal.Actions style={{ borderTop: 'none', backgroundColor: 'white', textAlign: 'left', display: 'flex', ...footerStyle }}>
          {footer}
        </SUIModal.Actions>
      )}
    </SUIModal>
  );
};

Modal.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'error', null]),
  headerTitle: PropTypes.string,
  footer: PropTypes.element,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      buttonType: PropTypes.oneOf(['button', 'submit', 'reset']),
      styleType: PropTypes.oneOf(['cancel', 'confirm', 'submit']),
      label: PropTypes.string,
      backgroundColor: PropTypes.string,
      labelColor: PropTypes.string,
      style: PropTypes.object,
      disabled: PropTypes.bool,
      onClick: PropTypes.func
    })
  ),
  /** If you're not using a modal context, use "headerTitle" props instead */
  title: PropTypes.string,
  /** If you're not using a modal context, just pass a children element */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  params: PropTypes.shape(),
  closeIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  dimmer: PropTypes.oneOf([true, 'inverted', 'blurring']),
  open: PropTypes.bool,
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'large', 'fullscren']),
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

Modal.defaultProps = {
  type: null,
  headerTitle: '',
  footer: null,
  buttons: [],
  title: '',
  text: '',
  params: {},
  closeIcon: false,
  dimmer: true,
  open: false,
  size: 'small',
  onOpen: () => {},
  onClose: () => {}
};

export default Modal;
