/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import useCustomQuery from '../hooks/useCustomQuery';
import { GET_TICKETS } from '../graphql/tickets/queries';
import { ROUTE_TICKET } from '../router/routes';
import { theme } from '../styles/theme';
import SUIContainer from '../components/shared/Container';
import SearchBar from '../components/shared/SearchBar';
import Tabs from '../components/shared/Tabs';
import Card from '../components/shared/Card';
import Spinner from '../components/shared/Spinner';
import WelcomeHeader from '../components/shared/WelcomeHeader';
import MultipleFilters from '../components/shared/MultipleFilters';
import FooterButton from '../components/shared/FooterButton';
import RoundedWarning from '../components/shared/RoundedWarning';

const Container = styled(SUIContainer)`
  position: relative;
  margin-top: 12px;
  overflow: hidden;
  height: 100%;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-flow: column;

  & > div > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const WelcomeHeaderContainer = styled.div`
  @media (min-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    display: none;
  }
`;

const DescriptionContainer = styled.div`
  @media (max-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    display: none;
  }
`;

const initialFilters = {
  keyword: '',
  date: null,
  ticketTypeUUID: null,
  buildingUUID: null,
  priority: null,
  pageSize: 10,
  page: 1,
  orderType: 'DESC',
  orderBy: 'createdAt'
};

const TABS = { 0: 'OPEN', 1: 'WORKING', 2: 'CLOSED' };

const TicketsListContainer = () => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [totalCounts, setTotalCounts] = useState({ OPEN: 0, WORKING: 0, CLOSED: 0 });
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem('ticketFilters'));
    setFilters(storedFilter || initialFilters);
  }, []);

  // Queries
  const getTickets = useCustomQuery(GET_TICKETS, {
    variables: { ...filters, status: TABS[0], orderType: 'DESC', orderBy: 'createdAt' }
  });

  const openTickets = useCustomQuery(GET_TICKETS, {
    variables: { status: TABS[0] },
    onCompleted: ({ getTickets }) => setTotalCounts((prev) => ({ ...prev, OPEN: getTickets?.totalCount }))
  });
  const workingTickets = useCustomQuery(GET_TICKETS, {
    variables: { status: TABS[1] },
    onCompleted: ({ getTickets }) => setTotalCounts((prev) => ({ ...prev, WORKING: getTickets?.totalCount }))
  });
  const closedTickets = useCustomQuery(GET_TICKETS, {
    variables: { status: TABS[2] },
    onCompleted: ({ getTickets }) => setTotalCounts((prev) => ({ ...prev, CLOSED: getTickets?.totalCount }))
  });

  useEffect(() => {
    getTickets
      .refetch({ ...filters, orderType: 'DESC', orderBy: 'createdAt', status: TABS[currentTab] })
      .then(({ data }) => setTickets([...(data?.getTickets?.data ?? [])]));
  }, [currentTab, filters]);

  useEffect(() => {
    localStorage.setItem('ticketFilters', JSON.stringify(filters));
    openTickets
      .refetch({ ...filters, orderType: 'DESC', orderBy: 'createdAt', status: TABS[0] })
      .then(({ data }) => setTotalCounts((prev) => ({ ...prev, OPEN: data?.getTickets?.totalCount })));
    workingTickets
      .refetch({ ...filters, orderType: 'DESC', orderBy: 'createdAt', status: TABS[1] })
      .then(({ data }) => setTotalCounts((prev) => ({ ...prev, WORKING: data?.getTickets?.totalCount })));
    closedTickets
      .refetch({ ...filters, orderType: 'DESC', orderBy: 'createdAt', status: TABS[2] })
      .then(({ data }) => setTotalCounts((prev) => ({ ...prev, CLOSED: data?.getTickets?.totalCount })));
  }, [filters]);

  const onFilterChange = ({ key, value }) => {
    switch (key) {
      case 'keyword':
        setFilters((prev) => ({ ...prev, keyword: value }));
        break;
      case 'apply-filters':
        setFilters({ ...value });
        setShowFilters(false);
        break;
      default:
        break;
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'open':
        return theme.colors.green;
      case 'working':
        return theme.colors.orange;
      case 'closed':
        return theme.colors.errorColor;
      default:
        return theme.colors.errorColor;
    }
  };

  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current) {
      ref.current.pageLoaded = ref.current.props.pageStart;
    }
  }, [currentTab]);

  const renderTab = () => {
    if (getTickets.loading && tickets.length <= 0) return <Spinner />;
    return (
      <CardsContainer style={{ marginBottom: 40 }}>
        <InfiniteScroll
          ref={ref}
          pageStart={0}
          hasMore={tickets.length < getTickets.data?.getTickets?.totalCount && !loading}
          loadMore={(page) => {
            setLoading(true);
            return getTickets.refetch({ ...filters, page }).then(({ data }) => {
              setLoading(false);
              setTickets((prev) => [...prev, ...(data?.getTickets?.data ?? [])]);
            });
          }}
          loader={<Spinner key={0} />}
        >
          {tickets.map((ticket, index) => (
            <Card
              key={index}
              data={[
                {
                  left: { element: `Ticket #${ticket.id}` },
                  right: { element: ticket.status, style: { color: getStatusColor(ticket.status) } }
                },
                { left: { element: `${ticket.building?.number ?? ''} ${ticket.building?.name ?? ''}`.trim() } },
                {
                  left: { element: ticket.ticketType?.name ?? '' },
                  right: {
                    element: ticket.description ? (
                      <DescriptionContainer>
                        Description: {ticket.description?.length > 100 ? ticket.description?.substring(0, 100) + '...' : ticket.description}
                      </DescriptionContainer>
                    ) : null
                  }
                },
                {
                  left: { element: `Created ${moment(ticket.createdAt).format('DD-MM-YYYY HH:mm')} | Updated ${ticket.updatedAt ? moment(ticket.updatedAt).format('DD-MM-YYYY HH:mm') : "-"}` },
                  right: { element: ticket.createdBy ? `Created By: ${ticket.createdBy?.firstName} ${ticket.createdBy?.lastName}` : null }
                }
              ]}
              onClick={() => history.push(ROUTE_TICKET.replace(':uuid?', ticket.uuid))}
            />
          ))}
        </InfiniteScroll>
      </CardsContainer>
    );
  };

  return (
    <Container>
      <WelcomeHeaderContainer className="welcome-header">
        <WelcomeHeader containerStyle={{ marginRight: 10 }} />
      </WelcomeHeaderContainer>
      <FiltersContainer>
        <SearchBar
          fluid
          placeholder="search-here"
          containerStyle={{ width: '100%', marginRight: 10, marginTop: 10 }}
          loading={getTickets.loading}
          open={false}
          onChange={(e, { value }) => onFilterChange({ key: 'keyword', value })}
        />
        <div style={{ position: 'relative' }}>
          <Icon name="options" style={{ cursor: 'pointer', fontSize: '1.5em' }} onClick={() => setShowFilters(true)} />
          {(filters?.keyword || filters?.startDate || filters?.endDate || filters?.ticketTypeUUID || filters?.buildingUUID || filters?.priority) && (
            <RoundedWarning />
          )}
        </div>
      </FiltersContainer>
      <MultipleFilters
        type="tickets"
        showFilters={showFilters}
        initialFilters={filters}
        onFilterChange={onFilterChange}
        onClear={() => localStorage.setItem('ticketFilters', JSON.stringify(initialFilters))}
      />
      {!showFilters && (
        <>
          <Tabs
            activeIndex={currentTab}
            tabs={[
              {
                menuItem: `open ${totalCounts.OPEN}`,
                render: () => renderTab()
              },
              {
                menuItem: `working ${totalCounts.WORKING}`,
                render: () => renderTab()
              },
              {
                menuItem: `closed ${totalCounts.CLOSED}`,
                render: () => renderTab()
              }
            ]}
            onTabChange={(e, { activeIndex }) => {
              setCurrentTab(activeIndex);
            }}
          />
          <FooterButton text="new-ticket" parentMarginBottom={35} onClick={() => history.push(ROUTE_TICKET.replace('/:uuid', ''))} />
        </>
      )}
    </Container>
  );
};

export default TicketsListContainer;
