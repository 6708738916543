/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import useCustomQuery from '../hooks/useCustomQuery';
import { GET_COMPLAINTS } from '../graphql/complaints/queries';
import { theme } from '../styles/theme';
import { ROUTE_NEW_COMPLAINT } from '../router/routes';
import SUIContainer from '../components/shared/Container';
import SearchBar from '../components/shared/SearchBar';
import Tabs from '../components/shared/Tabs';
import Card from '../components/shared/Card';
import Spinner from '../components/shared/Spinner';
import WelcomeHeader from '../components/shared/WelcomeHeader';
import FooterButton from '../components/shared/FooterButton';
import MultipleFilters from '../components/shared/MultipleFilters';
import RoundedWarning from '../components/shared/RoundedWarning';

const Container = styled(SUIContainer)`
  position: relative;
  margin-top: 12px;
  overflow: hidden;
  height: 100%;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-flow: column;

  & > div > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const WelcomeHeaderContainer = styled.div`
  @media (min-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    display: none;
  }
`;

const initialFilters = {
  keyword: '',
  date: null,
  profileUUID: null,
  complaintTypeUUID: null,
  roomUUID: null,
  priority: null,
  pageSize: 10,
  page: 1,
  orderType: 'DESC',
  orderBy: 'createdAt'
};

const TABS = { 0: 'OPEN', 1: 'WORKING', 2: 'CLOSED' };

const ComplaintsListContainer = () => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);
  const [complaints, setComplaints] = useState([]);
  const [totalCounts, setTotalCounts] = useState({ OPEN: 0, WORKING: 0, CLOSED: 0 });
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState(initialFilters);

  // Queries
  const getComplaints = useCustomQuery(GET_COMPLAINTS, {
    variables: { ...filters, status: TABS[0], orderType: 'DESC', orderBy: 'createdAt' }
  });

  const openComplaints = useCustomQuery(GET_COMPLAINTS, {
    variables: { status: TABS[0] },
    onCompleted: ({ getComplaints }) => setTotalCounts((prev) => ({ ...prev, OPEN: getComplaints?.totalCount }))
  });
  const workingComplaints = useCustomQuery(GET_COMPLAINTS, {
    variables: { status: TABS[1] },
    onCompleted: ({ getComplaints }) => setTotalCounts((prev) => ({ ...prev, WORKING: getComplaints?.totalCount }))
  });
  const closedComplaints = useCustomQuery(GET_COMPLAINTS, {
    variables: { status: TABS[2] },
    onCompleted: ({ getComplaints }) => setTotalCounts((prev) => ({ ...prev, CLOSED: getComplaints?.totalCount }))
  });

  useEffect(() => {
    getComplaints
      .refetch({ ...filters, orderType: 'DESC', orderBy: 'createdAt', status: TABS[currentTab] })
      .then(({ data }) => setComplaints([...(data?.getComplaints?.data ?? [])]));
  }, [currentTab, filters]);

  useEffect(() => {
    const storedFilter = JSON.parse(localStorage.getItem('complaintFilters'));
    setFilters(storedFilter || initialFilters);
  }, []);

  useEffect(() => {
    localStorage.setItem('complaintFilters', JSON.stringify(filters));
    openComplaints
      .refetch({ ...filters, orderType: 'DESC', orderBy: 'createdAt', status: TABS[0] })
      .then(({ data }) => setTotalCounts((prev) => ({ ...prev, OPEN: data?.getComplaints?.totalCount })));
    workingComplaints
      .refetch({ ...filters, orderType: 'DESC', orderBy: 'createdAt', status: TABS[1] })
      .then(({ data }) => setTotalCounts((prev) => ({ ...prev, WORKING: data?.getComplaints?.totalCount })));
    closedComplaints
      .refetch({ ...filters, orderType: 'DESC', orderBy: 'createdAt', status: TABS[2] })
      .then(({ data }) => setTotalCounts((prev) => ({ ...prev, CLOSED: data?.getComplaints?.totalCount })));
  }, [filters]);

  const onFilterChange = ({ key, value }) => {
    switch (key) {
      case 'keyword':
        setFilters((prev) => ({ ...prev, keyword: value }));
        break;
      case 'apply-filters':
        setFilters({ ...value });
        setShowFilters(false);
        break;
      default:
        break;
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'open':
        return theme.colors.green;
      case 'working':
        return theme.colors.orange;
      case 'closed':
        return theme.colors.errorColor;
      default:
        return theme.colors.errorColor;
    }
  };

  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current) {
      ref.current.pageLoaded = ref.current.props.pageStart;
    }
  }, [currentTab]);

  const renderTab = () => {
    if (getComplaints.loading && complaints.length <= 0) return <Spinner />;
    return (
      <CardsContainer>
        <InfiniteScroll
          ref={ref}
          pageStart={0}
          hasMore={complaints.length < getComplaints.data?.getComplaints?.totalCount && !loading}
          loadMore={(page) => {
            setLoading(true);
            return getComplaints.refetch({ ...filters, page }).then(({ data }) => {
              setLoading(false);
              setComplaints((prev) => [...prev, ...(data?.getComplaints?.data ?? [])]);
            });
          }}
          loader={<Spinner key={0} />}
        >
          {complaints?.map((complaint, index) => (
            <Card
              key={index}
              data={[
                {
                  left: { element: `Complaint #${complaint.id}` },
                  right: { element: complaint.status, style: { color: getStatusColor(complaint.status) } }
                },
                { left: { element: `${complaint.profile?.firstName ?? ''} ${complaint.profile?.lastName ?? ''}`.trim() } },
                { left: { element: complaint.complaintType?.name ?? '' } },
                { left: { element: `${complaint.room?.number ?? ''} ${complaint.room?.name ?? ''}`.trim() } },
                {
                  left: { element: `Created ${moment(complaint.createdAt).format('DD-MM-YYYY HH:mm')} | Updated ${complaint.updatedAt ? moment(complaint.updatedAt).format('DD-MM-YYYY HH:mm') : "-"}` },
                  right: { element: `${complaint.createdBy?.firstName ?? ''} ${complaint.createdBy?.lastName ?? ''}`.trim() }
                }
              ]}
              onClick={() => history.push(ROUTE_NEW_COMPLAINT.replace(':uuid?', complaint.uuid))}
            />
          ))}
        </InfiniteScroll>
      </CardsContainer>
    );
  };

  return (
    <Container>
      <WelcomeHeaderContainer className="welcome-header">
        <WelcomeHeader containerStyle={{ marginRight: 10 }} />
      </WelcomeHeaderContainer>

      <FiltersContainer>
        <SearchBar
          fluid
          placeholder="search-here"
          containerStyle={{ width: '100%', marginRight: 10, marginTop: 10 }}
          loading={getComplaints.loading}
          open={false}
          onChange={(e, { value }) => onFilterChange({ key: 'keyword', value })}
        />
        <div style={{ position: 'relative' }}>
          <Icon name="options" style={{ cursor: 'pointer', fontSize: '1.5em' }} onClick={() => setShowFilters(true)} />
          {(filters?.keyword ||
            filters?.startDate ||
            filters?.endDate ||
            filters?.profileUUID ||
            filters?.complaintTypeUUID ||
            filters?.roomUUID ||
            filters?.priority) && <RoundedWarning />}
        </div>
      </FiltersContainer>
      <MultipleFilters
        type="complaints"
        showFilters={showFilters}
        initialFilters={filters}
        onFilterChange={onFilterChange}
        onClear={() => localStorage.setItem('complaintFilters', JSON.stringify(initialFilters))}
      />
      {!showFilters && (
        <>
          <Tabs
            activeIndex={currentTab}
            tabs={[
              {
                menuItem: `open ${totalCounts.OPEN}`,
                render: () => renderTab()
              },
              {
                menuItem: `working ${totalCounts.WORKING}`,
                render: () => renderTab()
              },
              {
                menuItem: `closed ${totalCounts.CLOSED}`,
                render: () => renderTab()
              }
            ]}
            onTabChange={(e, { activeIndex }) => {
              setCurrentTab(activeIndex);
            }}
          />
          <FooterButton text="new-complaint" parentMarginBottom={35} onClick={() => history.push(ROUTE_NEW_COMPLAINT.replace('/:uuid', ''))} />
        </>
      )}
    </Container>
  );
};

export default ComplaintsListContainer;
