/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';
import { COMPLAINT_FRAGMENT } from '../fragments';

export const SAVE_COMPLAINT = gql`
  mutation saveComplaint(
    $complaintData: ComplaintInputDto!
    $profileUUID: String
    $roomUUID: String
    $complaintTypeUUID: String!
    $complaintUUID: String
  ) {
    saveComplaint(
      complaintData: $complaintData
      profileUUID: $profileUUID
      roomUUID: $roomUUID
      complaintTypeUUID: $complaintTypeUUID
      complaintUUID: $complaintUUID
    ) {
      ...complaintFragment
    }
  }
  ${COMPLAINT_FRAGMENT}
`;
