import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import OutsideClickHandler from 'react-outside-click-handler';
import { useHistory } from 'react-router-dom';
import SideDrawerItem from './SideDrawerItem';
import SideDrawerContext from '../../../contexts/SideDrawerContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { ReactComponent as BorgoEgnaziaLogo } from '../../../assets/images/borgo-egnazia-logo-with-text.svg';
import { ROUTE_COMPLAINTS, ROUTE_RESERVATIONS, ROUTE_SETTINGS, ROUTE_TICKETS } from '../../../router/routes';

const ITEMS_PADDING = '20px 15px';

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0px;
  width: 90%;
  height: 100%;
  background-color: white;
  box-shadow: 2px 5px 15px black;
  z-index: 10000;
  transform: ${({ isSideDrawerOpened }) => (isSideDrawerOpened ? 'translateX(0)' : 'translateX(-120%)')};
  transition: transform 0.3s ease-out;

  & > div {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    height: 100%;
  }

  & > div > div:not(:last-child):not(:first-child) {
    border-bottom: ${({ theme: { colors } }) => `1px solid ${colors.primaryColor}`};
  }

  @media (min-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    width: 416px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: ${({ theme: { colors } }) => `2px solid ${colors.primaryColor}`};
  padding: ${() => ITEMS_PADDING};

  & > svg:first-child {
    width: 97px;
    height: auto;
  }
`;

const SideDrawer = () => {
  const { isSideDrawerOpened, closeSideDrawer } = useContext(SideDrawerContext);
  const { logout } = useContext(AuthContext);

  const history = useHistory();

  return (
    <Container isSideDrawerOpened={isSideDrawerOpened}>
      <OutsideClickHandler onOutsideClick={() => closeSideDrawer()}>
        <Header>
          <BorgoEgnaziaLogo />
        </Header>
        <SideDrawerItem
          padding={ITEMS_PADDING}
          onClick={() => {
            closeSideDrawer();
            history.push(ROUTE_RESERVATIONS);
          }}
        >
          reservations
        </SideDrawerItem>
        <SideDrawerItem
          padding={ITEMS_PADDING}
          onClick={() => {
            closeSideDrawer();
            history.push(ROUTE_COMPLAINTS);
          }}
        >
          complaints
        </SideDrawerItem>
        <SideDrawerItem
          padding={ITEMS_PADDING}
          onClick={() => {
            closeSideDrawer();
            history.push(ROUTE_TICKETS);
          }}
        >
          tickets
        </SideDrawerItem>
        <SideDrawerItem
          padding={ITEMS_PADDING}
          onClick={() => {
            closeSideDrawer();
            history.push(ROUTE_SETTINGS);
          }}
        >
          settings
        </SideDrawerItem>
        <SideDrawerItem
          padding={ITEMS_PADDING}
          onClick={() => {
            closeSideDrawer();
            logout();
          }}
        >
          logout
        </SideDrawerItem>
      </OutsideClickHandler>
    </Container>
  );
};

export default SideDrawer;
