/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';
import { SMART_OUTLETS_FRAGMENT, OUTLET_CATEGORIES_FRAGMENT, FB_MENU_CATEGORIES_FRAGMENT } from '../fragments';

export const GET_SMART_OUTLETS = gql`
  query getSmartOutlets(
    $page: Int
    $pageSize: Int
    $isDeliveryEnabled: Boolean
    $isTakeAwayEnabled: Boolean
    $isOnlineReservationAccepted: Boolean
    $isBookingEnabled: Boolean
    $outletCategoryUUID: String
    $maxDistanceKm: Float
    $geolocation: PointInput
    $keyword: String
    $outletCategoryType: OutletCategoryTypes
  ) {
    getSmartOutlets(
      page: $page
      pageSize: $pageSize
      isDeliveryEnabled: $isDeliveryEnabled
      isTakeAwayEnabled: $isTakeAwayEnabled
      isOnlineReservationAccepted: $isOnlineReservationAccepted
      isBookingEnabled: $isBookingEnabled
      outletCategoryUUID: $outletCategoryUUID
      maxDistanceKm: $maxDistanceKm
      geolocation: $geolocation
      keyword: $keyword
      outletCategoryType: $outletCategoryType
    ) {
      totalCount
      data {
        ...smartOutletsFragment
      }
    }
  }
  ${SMART_OUTLETS_FRAGMENT}
`;

export const GET_SMART_OUTLET = gql`
  query getSmartOutlet($outletUUID: String!) {
    getSmartOutlet(outletUUID: $outletUUID) {
      ...smartOutletsFragment
    }
  }
  ${SMART_OUTLETS_FRAGMENT}
`;

export const GET_FB_MENU_CATEGORIES = gql`
  query getFbMenuCategories($fbOutletUUID: String!, $page: Int, $pageSize: Int, $orderBy: String, $orderType: String) {
    getFbMenuCategories(fbOutletUUID: $fbOutletUUID, page: $page, pageSize: $pageSize, orderBy: $orderBy, orderType: $orderType) {
      totalCount
      data {
        ...fbMenuCategoriesFragment
      }
    }
  }
  ${FB_MENU_CATEGORIES_FRAGMENT}
`;

export const GET_ALL_AVAILABLE_SLOTS = gql`
  query getAllAvailableSlots($fbOutletUUID: String!, $date: String!, $fbGuestList: [FbGuestListDtoInput!]!) {
    getAllAvailableSlots(fbOutletUUID: $fbOutletUUID, date: $date, fbGuestList: $fbGuestList) {
      fbServiceUUID
      name
      availableSlots {
        time
        isAvailable
        overbooking
      }
    }
  }
`;

export const GET_OUTLET_CATEGORIES = gql`
  query getOutletCategories($type: OutletCategoryTypes) {
    getOutletCategories(type: $type) {
      totalCount
      data {
        ...outletCategoriesFragment
      }
    }
  }
  ${OUTLET_CATEGORIES_FRAGMENT}
`;

export const GET_MINIMUM_ORDER_AMOUNT = gql`
  query getMinimumOrderAmount($fbOutletUUID: String!, $orderType: OrderTypes!) {
    getMinimumOrderAmount(fbOutletUUID: $fbOutletUUID, orderType: $orderType)
  }
`;

export const CHECK_OUTLET_DELIVERY_DISTANCE = gql`
  query checkOutletDeliveryDistance($geolocation: PointInput, $fbOutletUUID: String!) {
    checkOutletDeliveryDistance(geolocation: $geolocation, fbOutletUUID: $fbOutletUUID) {
      distanceKm
      isDeliveryAvailable
    }
  }
`;

export const GET_FB_SERVICES = gql`
  query getFbServices($fbOutletUUID: String!) {
    getFbServices(fbOutletUUID: $fbOutletUUID) {
      totalCount
      data {
        id
        uuid
        name
        startTime
        endTime
        isActive
      }
    }
  }
`;

export const GET_GUEST_PHYSICAL_CATEGORIES = gql`
  query getGuestPhysicalCategories {
    getGuestPhysicalCategories {
      totalCount
      data {
        id
        uuid
        name
        description
        ageRange
        ageMin
        ageMax
        hasAgeInput
        icon
      }
    }
  }
`;
