import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import SideDrawerContext from '../../contexts/SideDrawerContext';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ isSideDrawerOpened }) => (isSideDrawerOpened ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.0)')};
  z-index: ${({ isSideDrawerOpened }) => (isSideDrawerOpened ? 999 : -10)};

  transition: background-color 0.3s ease-in;
`;

const Backdrop = () => {
  const { isSideDrawerOpened } = useContext(SideDrawerContext);

  return <Container isSideDrawerOpened={isSideDrawerOpened} />;
};

export default Backdrop;
