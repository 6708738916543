import React, { useState, createContext, useContext } from 'react';
import { AuthContext } from './AuthContext';
import restaurantImage from '../assets/images/reservations/restaurants.png';
import { GET_OUTLET_CATEGORIES } from '../graphql/outlets/queries';
import useCustomQuery from '../hooks/useCustomQuery';

export const OutletCategoriesContext = createContext();

const restaurantCategory = {
  name: 'restaurants & bar',
  description: 'Discover',
  isFullWidthInApp: true,
  order: -1,
  image: restaurantImage
};

export const OutletCategoriesProvider = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext);

  const [categories, setCategories] = useState([]);

  useCustomQuery(GET_OUTLET_CATEGORIES, {
    variables: { type: 'service' },
    skip: !isLoggedIn(),
    onCompleted: (res) => {
      setCategories([restaurantCategory, ...res?.getOutletCategories?.data]);
    }
  });

  const getCategoryByUUID = (uuid) => {
    let category = categories.find((ctg) => ctg?.uuid === uuid);
    if (!category) {
      category = restaurantCategory;
    }
    return category;
  };

  return (
    <OutletCategoriesContext.Provider
      value={{
        categories,
        getCategoryByUUID
      }}
    >
      {children}
    </OutletCategoriesContext.Provider>
  );
};

export const OutletCategoriesConsumer = OutletCategoriesContext.Consumer;

export const withOutletCategoriesContext = (Component) => (props) => (
  <OutletCategoriesConsumer>{(providerProps) => <Component {...props} OutletCategoriesContextProps={providerProps} />}</OutletCategoriesConsumer>
);
