import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Item from './Item';
import { theme } from '../../styles/theme';
import { ReactComponent as ArrowUp } from '../../assets/images/arrows/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/images/arrows/arrow-down.svg';

const CategoryButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const CategoryItem = ({ category, onItemPress, disabled, expandedKey }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpansion = () => {
    if (isExpanded === expandedKey) {
      setIsExpanded(false);
    } else {
      setIsExpanded(expandedKey);
    }
  };
  return (
    <div style={{ flex: 1, marginBottom: 10 }}>
      <CategoryButton onClick={handleExpansion}>
        <span style={{ fontFamily: 'optima-bold', fontSize: 20, textTransform: 'capitalize' }}>{category.name}</span>

        {isExpanded === expandedKey ? <ArrowUp fill={theme.colors.primaryColor} /> : <ArrowDown fill={theme.colors.primaryColor} />}
      </CategoryButton>
      {isExpanded === expandedKey &&
        category?.fbMenuItems?.map((fbItem, index) => (
          <Item
            key={index}
            item={fbItem}
            isLast={index === category.fbMenuItems.length - 1}
            onItemPress={() => onItemPress(fbItem)}
            disabled={disabled}
          />
        ))}
    </div>
  );
};

export default CategoryItem;
