import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { BookContext } from '../../contexts/BookContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import Tabs from '../shared/Tabs';
import BookingTab from '../outlets/tabs/BookingTab';
import FooterButton from '../shared/FooterButton';
import { capitalizeFirstLetter, translate } from '../../translator';
import { ROUTE_BOOK_CHECKOUT_FB_RESERVATION } from '../../router/routes';

const Container = styled.div`
  padding-top: ${({ theme }) => theme.bannerHeight}px;
`;

const BookRestaurantContent = ({ outlet }) => {
  const history = useHistory();
  const { language } = useContext(LanguageContext);
  const { booking } = useContext(BookContext);
  const [currentTab, setCurrentTab] = useState(0);

  const tabs = [
    {
      menuItem: capitalizeFirstLetter(translate('bookings', language)),
      render: () => <BookingTab outlet={outlet} />
    }
  ];
  return (
    <Container>
      <Tabs
        tabs={tabs}
        activeIndex={currentTab}
        onTabChange={(e, { activeIndex }) => {
          setCurrentTab(activeIndex);
        }}
        menu={{ secondary: true, pointing: true, className: 'full-width' }}
      />
      {currentTab === 0 && (
        <FooterButton
          text="confirm"
          disabled={!booking?.date || booking?.fbGuestList?.adults?.length <= 0 || !booking?.fbService}
          onClick={() => history.push(ROUTE_BOOK_CHECKOUT_FB_RESERVATION)}
        />
      )}
      {currentTab === 1 && <FooterButton style={{ width: '100%' }} text="go-to-cart" />}
    </Container>
  );
};

export default BookRestaurantContent;
