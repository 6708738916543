import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useMutation } from '@apollo/react-hooks';
import { useFormik } from 'formik';
import { Dropdown, Icon, TextArea } from 'semantic-ui-react';
import * as Yup from 'yup';
import omit from 'lodash/omit';
import { capitalizeFirstLetter, translate, TranslateTextComponent } from '../translator';
import useCustomQuery from '../hooks/useCustomQuery';
import { getApolloErrors } from '../apollo/ApolloProvider';
import { ROUTE_TICKETS } from '../router/routes';
import { GET_BUILDINGS } from '../graphql/roombuilding/queries';
import { SAVE_TICKET } from '../graphql/tickets/mutations';
import { GET_TICKET, GET_TICKET_TYPES } from '../graphql/tickets/queries';
import { LanguageContext } from '../contexts/LanguageContext';
import { ModalContext } from '../contexts/ModalContext';
import { theme } from '../styles/theme';
import toBase64 from '../utils/toBase64';
import Container from '../components/shared/Container';
import InputWithLabel from '../components/shared/InputWithLabel';
import ButtonsSelector from '../components/shared/ButtonsSelector';
import SearchBar from '../components/shared/SearchBar';
import Button from '../components/shared/Button';
import FooterButton from '../components/shared/FooterButton';
import Spinner from '../components/shared/Spinner';

const Title = styled(TranslateTextComponent).attrs({ capitalize: true })`
  display: inline-block;
  font-family: optima-bold;
  font-size: 25px;
  margin: 10px 0px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-flow: column;

  & > div {
    margin-bottom: 20px;
  }
`;

const PhotoContainer = styled.div`
  position: relative;

  i {
    cursor: pointer;
    position: absolute;
    font-size: 2rem;
    top: -17px;
    right: -15px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const TicketContainer = () => {
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);
  const [buildingSearchValue, setBuildingSearchValue] = useState('');
  const history = useHistory();
  const { uuid } = useParams();

  // Queries
  const getTicket = useCustomQuery(GET_TICKET, {
    variables: { ticketUUID: uuid },
    onCompleted: ({ getTicket }) => {
      setBuildingSearchValue(`${getTicket?.building?.number ? `${getTicket?.building?.number} - ` : ''}${getTicket?.building?.name ?? ''}`.trim());
    },
    skip: !uuid
  });
  const getBuildings = useCustomQuery(GET_BUILDINGS);
  const getTicketTypes = useCustomQuery(GET_TICKET_TYPES);

  // Mutations
  const [saveTicket, { loading: saveTicketLoading }] = useMutation(SAVE_TICKET);

  const validationSchema = Yup.object().shape({
    ticketData: Yup.object().shape({
      priority: Yup.string()
        .typeError(capitalizeFirstLetter(translate('required', language)))
        .required(capitalizeFirstLetter(translate('required', language))),
      status: Yup.string()
        .typeError(capitalizeFirstLetter(translate('required', language)))
        .required(capitalizeFirstLetter(translate('required', language)))
    }),
    ticketTypeUUID: Yup.string()
      .typeError(capitalizeFirstLetter(translate('required', language)))
      .required(capitalizeFirstLetter(translate('required', language)))
  });
  const { values, errors, touched, setFieldValue, dirty, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ticketData: {
        description: getTicket.data?.getTicket?.description ?? '',
        priority: getTicket.data?.getTicket?.priority ?? null,
        status: getTicket.data?.getTicket?.status ?? null,
        notes: getTicket.data?.getTicket?.notes ?? '',
        pictureData: getTicket.data?.getTicket?.picture ?? null
      },
      buildingUUID: getTicket.data?.getTicket?.building?.uuid ?? null,
      ticketTypeUUID: getTicket.data?.getTicket?.ticketType?.uuid ?? null,
      ticketUUID: getTicket.data?.getTicket?.uuid ?? null
    },
    validationSchema,
    onSubmit: (values) => {
      let filteredValues;
      if (values.ticketData.pictureData && !values.ticketData.pictureData?.includes('data:image/')) {
        filteredValues = { ...omit(values, 'ticketData.pictureData') };
      } else filteredValues = { ...values };
      saveTicket({
        variables: { ...filteredValues }
      })
        .then(
          ({
            data: {
              saveTicket: { uuid: newUUID }
            }
          }) => {
            modalContext.openModal({
              title: !uuid ? 'new-ticket-successfully-created-title' : 'ticket-successfully-edited-title',
              text: (
                <TranslateTextComponent capitalize>
                  {!uuid ? 'new-ticket-successfully-created-description' : 'ticket-successfully-edited-description'}
                </TranslateTextComponent>
              ),
              buttons: [
                {
                  label: 'close-modal',
                  onClick: () => {
                    modalContext.closeModal();
                    history.push(ROUTE_TICKETS);
                  }
                }
              ]
            });
          }
        )
        .catch((err) => {
          modalContext.openModal({
            title: 'an-error-occurred',
            text: getApolloErrors(err)?.[0]?.toLowerCase().replace(/\s/g, '-') ?? '',
            buttons: [{ label: 'close-modal', onClick: () => modalContext.closeModal() }]
          });
        });
    }
  });

  return (
    <Container>
      <Title>{uuid ? `Ticket #${getTicket.data?.getTicket.id ?? ''}` : 'new-ticket'}</Title>
      <FormContainer>
        <InputWithLabel label="select-room-building">
          <SearchBar
            fluid
            placeholder={capitalizeFirstLetter(translate('room', language))}
            containerStyle={{ width: '100%' }}
            results={
              getBuildings.data?.getBuildings?.data
                ?.map((b) => ({ key: b.uuid, title: `${b.number ? `${b.number} - ` : ''}${b.name ?? ''}`.trim(), uuid: b.uuid }))
                ?.filter(
                  (b) =>
                    b.title?.toLowerCase().includes(buildingSearchValue?.toLowerCase()) || b.number?.().includes(buildingSearchValue?.toLowerCase())
                ) ?? []
            }
            loading={getBuildings.loading}
            value={buildingSearchValue}
            onResultSelect={(e, { result: { title, uuid } }) => {
              setBuildingSearchValue(title);
              setFieldValue('buildingUUID', uuid);
            }}
            onChange={(e, { value }) => {
              if (value?.length <= 0) {
                setBuildingSearchValue(null);
                setFieldValue('buildingUUID', null);
              }
              setBuildingSearchValue(value);
            }}
          />
        </InputWithLabel>
        <InputWithLabel label="ticket-type" error={touched.ticketTypeUUID && errors.ticketTypeUUID}>
          <Dropdown
            fluid
            placeholder={capitalizeFirstLetter(translate('ticket-type', language))}
            search
            selection
            clearable
            options={getTicketTypes.data?.getTicketTypes?.data?.map((o) => ({ text: o.name, value: o.uuid })) ?? []}
            value={values.ticketTypeUUID}
            onChange={(e, { value }) => setFieldValue('ticketTypeUUID', value)}
          />
        </InputWithLabel>
        <InputWithLabel label="priority" error={touched?.ticketData?.priority && errors?.ticketData?.priority}>
          <ButtonsSelector
            buttons={[
              { label: 'low', value: 'low', onClick: (value) => setFieldValue('ticketData.priority', value) },
              { label: 'medium', value: 'medium', onClick: (value) => setFieldValue('ticketData.priority', value) },
              { label: 'high', value: 'high', onClick: (value) => setFieldValue('ticketData.priority', value) }
            ]}
            activeValues={[values.ticketData.priority]}
          />
        </InputWithLabel>
        <InputWithLabel label="ticket-status" error={touched?.ticketData?.status && errors?.ticketData?.status}>
          <ButtonsSelector
            buttons={[
              { label: 'open', value: 'OPEN', onClick: (value) => setFieldValue('ticketData.status', value) },
              { label: 'working', value: 'WORKING', onClick: (value) => setFieldValue('ticketData.status', value) },
              { label: 'closed', value: 'CLOSED', onClick: (value) => setFieldValue('ticketData.status', value) }
            ]}
            activeValues={[values.ticketData.status]}
          />
        </InputWithLabel>
        <InputWithLabel label="description">
          <TextArea
            placeholder={capitalizeFirstLetter(translate('description', language))}
            style={{ width: '100%', minHeight: 107, padding: 10, fontSize: 14, border: `1px solid ${theme.colors.borderColor}` }}
            value={values.ticketData.description}
            onChange={(e, { value }) => setFieldValue('ticketData.description', value)}
          />
        </InputWithLabel>
        <InputWithLabel label="notes">
          <TextArea
            placeholder={capitalizeFirstLetter(translate('notes', language))}
            style={{ width: '100%', minHeight: 107, padding: 10, fontSize: 14, border: `1px solid ${theme.colors.borderColor}` }}
            value={values.ticketData.notes}
            onChange={(e, { value }) => setFieldValue('ticketData.notes', value)}
          />
        </InputWithLabel>
        <InputWithLabel label="photo">
          {!values.ticketData.pictureData && (
            <label htmlFor="upload-photo-input" style={{ width: '100%' }}>
              <input
                id="upload-photo-input"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={async ({ target: { files } }) => {
                  if (files[0]) {
                    const file = await toBase64(files[0]);
                    setFieldValue('ticketData.pictureData', file);
                  } else setFieldValue('ticketData.pictureData', null);
                }}
              />
              <Button
                type="button"
                text="upload-photo"
                style={{ width: '100%', background: 'white', color: theme.colors.tertiaryColor, border: `1px solid ${theme.colors.tertiaryColor}` }}
                onClick={() => document.getElementById('upload-photo-input').click()}
              />
            </label>
          )}
          {values.ticketData.pictureData && (
            <PhotoContainer>
              <Icon name="close" onClick={() => setFieldValue('ticketData.pictureData', null)} />
              <img
                src={
                  values.ticketUUID && !values.ticketData.pictureData?.includes('data:image/')
                    ? process.env.REACT_APP_SERVER_URL + values.ticketData.pictureData
                    : values.ticketData.pictureData
                }
                alt="Uploaded ticket"
              />
            </PhotoContainer>
          )}
        </InputWithLabel>
      </FormContainer>
      <FooterButton type="submit" text="save" disabled={saveTicketLoading || !dirty} onClick={handleSubmit}>
        {saveTicketLoading && <Spinner size={15} />}
      </FooterButton>
    </Container>
  );
};

export default TicketContainer;
