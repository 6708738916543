import React, { useContext, useState } from 'react';
import moment from 'moment';
import useCustomQuery from '../hooks/useCustomQuery';
import { GET_FB_RESERVATIONS_SUMMARY } from '../graphql/reservations/queries';
import { OutletCategoriesContext } from '../contexts/OutletCategoriesContext';
import FixedContainer from '../components/shared/FixedContainer';
import Spinner from '../components/shared/Spinner';
import Container from '../components/shared/Container';
import ReservationSummaryCard from '../components/outlets/ReservationSummaryCard';
import SearchBar from '../components/shared/SearchBar';
import Banner from '../components/shared/Banner';
import BoldLabel from '../components/shared/BoldLabel';
import { theme } from '../styles/theme';

const initialQueryFilters = {
  keyword: null,
  isBookingEnabled: true,
  outletCategoryType: 'restaurant'
};

const ReservationsSummaryContainer = () => {
  const [queryFilters, setQueryFilters] = useState(initialQueryFilters);

  const getFbReservationsSummary = useCustomQuery(GET_FB_RESERVATIONS_SUMMARY, {
    variables: { date: moment().format('YYYY-MM-DD'), toDate: moment().format('YYYY-MM-DD') },
    pollInterval: 10000
  });

  const updateQueryFilters = (key, value) => {
    setQueryFilters({ ...queryFilters, [key]: value });
  };

  const { getCategoryByUUID } = useContext(OutletCategoriesContext);
  const selectedCategory = getCategoryByUUID();
  return (
    <div>
      {getFbReservationsSummary?.loading ? (
        <Spinner containerStyle={{ paddingTop: 100 }} />
      ) : (
        <>
          <FixedContainer>
            <Banner text={selectedCategory?.name ?? ''} background={selectedCategory?.image} />
            <SearchBar placeholder="search-here" onChange={(e) => updateQueryFilters('keyword', e.target.value)} />
          </FixedContainer>

          <Container style={{ paddingTop: theme.bannerHeight + theme.searchbarHeight + 20 }}>
            <div style={{ marginBottom: 10 }}>
              <BoldLabel label="outlets" fontSize={25} />
            </div>
            {getFbReservationsSummary.data?.getFbReservationsSummary
              ?.filter(
                (fbReservationSummary) =>
                  fbReservationSummary?.outlet?.outletCategories?.length > 0 &&
                  fbReservationSummary?.outlet?.outletCategories?.some((outletCategory) => outletCategory?.type === 'restaurant')
              )
              ?.map(({ outlet, fbServices }) => (
                <ReservationSummaryCard key={outlet?.uuid} outlet={outlet} fbServices={fbServices} />
              ))}
          </Container>
        </>
      )}
    </div>
  );
};

export default ReservationsSummaryContainer;
