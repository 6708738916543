import React from 'react';
import { ThemeProvider } from 'styled-components';
import ApolloProvider from './apollo/ApolloProvider';
import Router from './router/router';
import { LanguageProvider } from './contexts/LanguageContext';
import { NotificationConsumer, NotificationProvider } from './contexts/NotificationContext';
import { ModalProvider, ModalConsumer } from './contexts/ModalContext';
import { AuthProvider } from './contexts/AuthContext';
import { ServiceCartProvider } from './contexts/ServiceCartContext';
import { OutletCategoriesProvider } from './contexts/OutletCategoriesContext';
import { BookProvider } from './contexts/BookContext';
import Modal from './components/shared/Modal';
import Notification from './components/shared/Notification';
import GlobalStyle from './styles/globalStyle';
import { theme } from './styles/theme';

const StoreProviders = ({ children }) => (
  <LanguageProvider>
    <NotificationProvider>
      <ModalProvider>
        <AuthProvider>
          <ApolloProvider>
            <OutletCategoriesProvider>
              <ServiceCartProvider>
                <BookProvider>{children}</BookProvider>
              </ServiceCartProvider>
            </OutletCategoriesProvider>
          </ApolloProvider>
        </AuthProvider>
      </ModalProvider>
    </NotificationProvider>
  </LanguageProvider>
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AuthProvider>
        <ApolloProvider>
          <StoreProviders>
            <Router />
            <ModalConsumer>
              {(value) =>
                value.open && (
                  <Modal
                    open={value.open}
                    isLoading={value.isLoading || false}
                    title={value.title}
                    text={value.text}
                    buttons={value.buttons}
                    contentStyle={value.contentStyle}
                    onClose={value.onClose || value.closeModal}
                  />
                )
              }
            </ModalConsumer>
            <NotificationConsumer>{(notificationValues) => <Notification notificationValues={notificationValues} />}</NotificationConsumer>
          </StoreProviders>
        </ApolloProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
