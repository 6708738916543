/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';
import { FB_RESERVATION_FRAGMENT } from '../fragments';

export const DELETE_FB_RESERVATION = gql`
  mutation deleteFbReservation($fbReservationUUID: String!) {
    deleteFbReservation(fbReservationUUID: $fbReservationUUID) {
      ...fbReservationFragment
    }
  }
  ${FB_RESERVATION_FRAGMENT}
`;
