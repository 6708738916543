import React, { useContext } from 'react';
import { Button as SUIButton } from 'semantic-ui-react';
import { LanguageContext } from '../../contexts/LanguageContext';
import { theme } from '../../styles/theme';
import { translate } from '../../translator';

const Button = ({ className, type, text, children, disabled, style, onClick }) => {
  const { language } = useContext(LanguageContext);

  return (
    <SUIButton
      className={className}
      type={type}
      disabled={disabled}
      style={{
        backgroundColor: theme.colors.tertiaryColor,
        color: 'white',
        fontFamily: 'optima-bold',
        fontSize: 14,
        textTransform: 'uppercase',
        borderRadius: 0,
        ...style
      }}
      onClick={onClick}
    >
      {children || translate(text, language)}
    </SUIButton>
  );
};

export default Button;
