import React from 'react';
import styled from 'styled-components/macro';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { GET_SMART_SERVICES } from '../../../graphql/services/queries';
import SUIContainer from '../../shared/Container';
import Item from '../../shared/Item';
import BoldLabel from '../../shared/BoldLabel';
import Spinner from '../../shared/Spinner';
import HorizontalDateTimePicker from '../../shared/HorizontalDateTimePicker';
import { TranslateTextComponent } from '../../../translator';

const Container = styled(SUIContainer)`
  padding: 20px;
`;
const ServicesTab = ({ queryFilters, onSetQueryFilters, updateSelectedItem }) => {
  const getSmartServicesRes = useCustomQuery(GET_SMART_SERVICES, {
    variables: queryFilters,

    skip: !queryFilters?.date,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  return getSmartServicesRes.loading ? (
    <Spinner />
  ) : (
    <Container>
      <div style={{ marginBottom: 10 }}>
        <BoldLabel label="booking-date" fontSize={25} />
      </div>
      <HorizontalDateTimePicker date={queryFilters?.date} onChange={(value) => onSetQueryFilters('date', value)} />

      <div style={{ marginTop: 15, display: 'flex', flexDirection: 'column' }}>
        <BoldLabel label="services" fontSize={25} />

        {getSmartServicesRes?.data?.getSmartServices?.data?.length > 0 ? (
          getSmartServicesRes?.data?.getSmartServices?.data?.map((service, index) => {
            return (
              <Item
                key={index}
                item={service}
                isLast={index === getSmartServicesRes?.data?.getSmartServices?.data.length - 1}
                onItemClick={() => updateSelectedItem(service)}
              />
            );
          })
        ) : (
          <TranslateTextComponent capitalize>no-available-services</TranslateTextComponent>
        )}
      </div>
    </Container>
  );
};

export default ServicesTab;
