import React from 'react';
import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components/macro';
import { theme } from '../../styles/theme';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Spinner = ({ size, color, containerStyle }) => {
  return (
    <Container style={containerStyle}>
      <ClipLoader size={size} color={color} />
    </Container>
  );
};

Spinner.propTypes = {
  /** Pixel size */
  size: PropTypes.number,
  color: PropTypes.string
};

Spinner.defaultProps = {
  size: 75,
  color: theme.colors.primaryColor
};

export default Spinner;
