export const ROUTE_HOME = '/';
export const ROUTE_RESERVATIONS = '/reservations';
export const ROUTE_RESERVATIONS_LIST = '/reservations_list';
export const ROUTE_RESERVATION_DETAIL = '/reservation/:uuid';
export const ROUTE_SERVICES_LIST = '/services_list';
export const ROUTE_COMPLAINTS = '/complaints';
export const ROUTE_NEW_COMPLAINT = '/complaint/:uuid?';
export const ROUTE_TICKETS = '/tickets';
export const ROUTE_TICKET = '/ticket/:uuid?';
export const ROUTE_SERVICE_DETAIL = '/service/:uuid';
export const ROUTE_LOGIN = '/login';
export const ROUTE_OUTLETS = '/outlets/:uuid';
export const ROUTE_RESERVATIONS_SUMMARY = '/reservations-summary';
export const ROUTE_FB_OUTLET = '/restaurant/:uuid';
export const ROUTE_OUTLET = '/outlet/:uuid';
export const ROUTE_BOOK_FB_RESERVATION = '/book-restaurant/:uuid';
export const ROUTE_BOOK_SERVICES = '/book-services/:uuid';
export const ROUTE_BOOK_CHECKOUT_FB_RESERVATION = '/book-checkout-reservation';
export const ROUTE_CART_INFO = '/cart-info';
export const ROUTE_BOOK_CHECKOUT_SERVICES = '/book-checkout-services';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_SETTINGS_LANGUAGES = '/settings/languages';
export const ROUTE_SETTINGS_ACCOUNT = '/settings/account';
export const ROUTE_SETTINGS_LEGAL = '/settings/legal';
export const ROUTE_SETTINGS_CHANGE_PASSWORD = '/settings/change-password';
