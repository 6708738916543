import React from 'react';
import styled from 'styled-components/macro';
import { Menu, Sidebar } from 'semantic-ui-react';
import Item from './Item';
import Button from './Button';
import StepperCounter from './StepperCounter';
import { theme } from '../../styles/theme';

const Container = styled.div`
  border: ${`2px solid ${theme.colors.borderColor}`};
  border-bottom: 0px;
  padding: 10px 20px;
  background-color: #f5f5f5;
  height: auto;
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

const BottomSheet = ({ closeBottomSheet, item, updateQuantity, disabled, onClick }) => {
  return (
    <Sidebar
      className="bottom-sheet"
      direction="bottom"
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      onHide={closeBottomSheet}
      vertical
      visible={item ? true : false}
      width="thin"
      style={{ backgroundColor: 'transparent', borderTopRightRadius: '8px !important' }}
    >
      <Container>
        <Item item={{ ...item, price: item?.quantity * item?.price }} disabled isLast />
        <StepperCounter
          quantity={item?.quantity}
          containerStyle={{ paddingTop: 24, paddingBottom: 24, borderTop: `1px solid ${theme.colors.borderColor}` }}
          onAdd={() => updateQuantity('+')}
          onRemove={() => item?.quantity - 1 > 0 && updateQuantity('-')}
        />
        <Button style={{ width: '100%' }} text="add-to-order" disabled={disabled} onClick={onClick} />
      </Container>
    </Sidebar>
  );
};

export default BottomSheet;
