import React from 'react';
import styled from 'styled-components/macro';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

const Container = styled.div`
  /* clndr-cell
    clndr-cell-today
    clndr-cell-inrange
    clndr-cell-disabled
    clndr-cell-selected
    clndr-cell-other-month */
  width: 100%;
  .field {
    width: 100%;
  }

  .ui.input {
    width: 100%;
  }
`;

const DatePicker = ({ clearIcon, clearable, datePickerOnly, format = 'DD-MM-YYYY', icon, value, style, onChange }) => {
  return (
    <Container>
      <SemanticDatepicker
        clearIcon={clearIcon}
        clearable={clearable}
        datePickerOnly={datePickerOnly}
        format={format}
        icon={icon}
        value={value}
        style={style}
        onChange={onChange}
      />
    </Container>
  );
};

export default DatePicker;
