import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { ModalContext } from '../../contexts/ModalContext';
import { AuthContext } from '../../contexts/AuthContext';
import LanguagesBox from './LanguagesBox';
import LoginBox from './LoginBox';
import { ROUTE_HOME } from '../../router/routes';
import loginBackground from '../../assets/images/login/homeLogin.svg';
import bigBackground from '../../assets/images/login/bigBackground2.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Optima';
  justify-content: center;

  @media (min-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    padding: 0px 25%;
    background: ${() => `url(${bigBackground})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  }

  ${({ customBackground }) => {
    if (customBackground) {
      return css`
        background: ${() => `url(${customBackground})`};
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
      `;
    }
    return css`
      background: white;
    `;
  }};
`;

const Login = () => {
  const modalContext = useContext(ModalContext);
  const { login } = useContext(AuthContext);
  const history = useHistory();

  const onSignInSubmit = (email, password) => {
    login(email, password).then((res) => {
      if (res?.data?.data?.login?.access_token) {
        history.push(ROUTE_HOME);
      } else if (res?.data?.errors?.length > 0) {
        const error = res?.data?.errors?.[0]?.message?.message;
        modalContext.openModal({
          title: 'sign-in-failed-title',
          text: error?.toLowerCase().replace(/\s/g, '-') ?? '',
          buttons: [{ label: 'close-modal', onClick: () => modalContext.closeModal() }]
        });
      }
    });
  };

  return (
    <Container customBackground={loginBackground}>
      <LanguagesBox />
      <LoginBox onSignInSubmit={onSignInSubmit} />
    </Container>
  );
};

export default Login;
