import React, { useContext } from 'react';
import { Input } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { LanguageContext } from '../../contexts/LanguageContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import InputWithLabel from '../../components/shared/InputWithLabel';
import Container from '../../components/shared/Container';
import BoldLabel from '../../components/shared/BoldLabel';
import FooterButton from '../../components/shared/FooterButton';
import { capitalizeFirstLetter, translate } from '../../translator';
import { CHANGE_PASSWORD } from '../../graphql/auth/mutations';

const ChangePasswordContainer = () => {
  const { language } = useContext(LanguageContext);
  const { showNotification } = useContext(NotificationContext);

  const [changePassword] = useMutation(CHANGE_PASSWORD);

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(capitalizeFirstLetter(translate('required', language))),
    newPassword: Yup.string()
      .required(capitalizeFirstLetter(translate('required', language)))
      .oneOf([Yup.ref('confirmNewPassword'), null], 'Passwords must match'),
    confirmNewPassword: Yup.string()
      .required(capitalizeFirstLetter(translate('required', language)))
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
  });

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        changePassword({
          variables: {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword
          }
        })
          .then(() => {
            showNotification({ message: 'password-changed-successfully' });
          })
          .catch((err) => {
            showNotification({ message: err?.graphQLErrors[0]?.message?.message, type: toast.TYPE.ERROR });
          });

        setSubmitting(false);
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, touched, isSubmitting }) => (
        <>
          <Container style={{ paddingTop: 20 }} onSubmit={handleSubmit}>
            <BoldLabel label="change-password" />
            <InputWithLabel
              label="old-password"
              error={touched.oldPassword && errors.oldPassword}
              labelStyle={{ fontWeight: 'bold', fontFamily: 'Optima', marginTop: 15 }}
            >
              <Input
                fluid
                name="oldPassword"
                value={values.oldPassword}
                error={touched.oldPassword && !!errors.oldPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={capitalizeFirstLetter(translate('old-password', language))}
              />
            </InputWithLabel>

            <InputWithLabel
              label="new-password"
              error={touched.newPassword && errors.newPassword}
              labelStyle={{ fontWeight: 'bold', fontFamily: 'Optima', marginTop: 15 }}
            >
              <Input
                fluid
                name="newPassword"
                value={values.newPassword}
                error={touched.newPassword && !!errors.newPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={capitalizeFirstLetter(translate('new-password', language))}
              />
            </InputWithLabel>

            <InputWithLabel
              label="confirm-new-password"
              error={touched.confirmNewPassword && errors.confirmNewPassword}
              labelStyle={{ fontWeight: 'bold', fontFamily: 'Optima', marginTop: 15 }}
            >
              <Input
                fluid
                name="confirmNewPassword"
                value={values.confirmNewPassword}
                error={touched.confirmNewPassword && !!errors.confirmNewPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={capitalizeFirstLetter(translate('confirm-new-password', language))}
              />
            </InputWithLabel>
          </Container>
          <FooterButton text="save-changes" disabled={isSubmitting} onClick={handleSubmit} />
        </>
      )}
    </Formik>
  );
};
export default ChangePasswordContainer;
