import { gql } from 'apollo-boost';

export const baseFragment = `
id
uuid
createdAt
updatedAt
`;

export const getImagesFragment = (type = '') => gql`
  fragment ${type}ImagesFragment on ${type}ImageDto {
    id
    uuid
    isPrimary
    name
    path
  }
`;

export const PROFILE_PHYSICAL_CATEGORIES_FRAGMENT = gql`
  fragment profilePhysicalCategoriesFragment on ProfilePhysicalCategoriesDto {
    id
    uuid
    name
    icon
    hasAgeInput
    ageRange
    isEligibleForAvailability
    ageMin
    ageMax
    description
  }
`;

export const FB_SERVICES_FRAGMENT = gql`
  fragment fbServicesFragment on FbServicesDto {
    id
    uuid
    name
    description
    icon
    startTime
    endTime
    isActive
    lastReservation
    slotSizeMinutes
    maxGuestsForSlot
  }
`;

export const FB_SEGMENTS_SERVICES_FRAGMENT = gql`
  fragment fbSegmentsServicesFragment on FbSegmentsServicesDto {
    days
    fbService {
      ...fbServicesFragment
    }
  }
  ${FB_SERVICES_FRAGMENT}
`;

export const FB_ROOMS_FRAGMENT = gql`
  fragment fbRoomsFragment on FbRoomsDto {
    id
    uuid
    name
    isActive
    maxPax
    description
    hasOnlineReservations
  }
`;

export const OUTLET_SMART_SETTINGS_FRAGMENT = gql`
  fragment outletSmartSettingsFragment on OutletSmartSettingsDto {
    deliveryStartTime
    deliveryEndTime
    takeAwayStartTime
    takeAwayEndTime
    sharedMaxOrdersForSlot
    sharedSlotSize
    deliveryMaxOrdersForSlot
    deliverySlotSize
    deliveryDays
    maxDeliveryDistance
    takeAwayMaxOrdersForSlot
    takeAwaySlotSize
    takeAwayDays
    smartServiceTermsConditions
    smartServiceCancellationPolicy
    smartServiceServicesDescription
    regulation
  }
`;

export const POINT_FRAGMENT = gql`
  fragment pointFragment on Point {
    type
    coordinates #[ longitude, latitude ]
  }
`;

export const FB_SEGMENTS_FRAGMENT = gql`
  fragment fbSegmentsFragment on FbSegmentsDto {
    id
    uuid
    name
    description
    startDate
    endDate
    isActive
    fbSegmentsServices {
      ...fbSegmentsServicesFragment
    }
  }
  ${FB_SEGMENTS_SERVICES_FRAGMENT}
`;

export const OUTLET_CATEGORIES_FRAGMENT = gql`
  fragment outletCategoriesFragment on OutletCategoriesDto {
    id
    uuid
    name
    description
    imagePath
    type
    order
    isFullWidthInApp
  }
`;

export const SMART_OUTLETS_FRAGMENT = gql`
  fragment smartOutletsFragment on SmartOutletsDto {
    id
    uuid
    uuid
    name
    email
    notificationEmail
    deliveryPrice
    description
    address
    addressNumber
    city
    zipCode
    country
    phoneNumber
    maxPax
    isDeliveryEnabled
    isTakeAwayEnabled
    isSmartServiceEnabled
    maxDaysBeforeReservation
    isOnlineReservationAccepted
    minPaxForReservation
    maxPaxForReservation
    isReservationEditableByGuest
    avoidEditingOrDeleteBeforeMinutes
    minimumDeliveryOrderAmount
    totalReviews
    totalRating
    images {
      ...ImagesFragment
    }
    fbRooms {
      ...fbRoomsFragment
    }
    fbServices {
      ...fbServicesFragment
    }
    currentSegment {
      ...fbSegmentsFragment
    }
    fbSegments {
      ...fbSegmentsFragment
    }
    guestPhysicalCategories {
      ...profilePhysicalCategoriesFragment
    }
    outletCategories {
      ...outletCategoriesFragment
    }
    deliveryPrice
    geolocation {
      ...pointFragment
    }
    deliveryAvailability {
      distanceKm
      isDeliveryAvailable
    }
    smartSettings {
      ...outletSmartSettingsFragment
    }
  }
  ${FB_ROOMS_FRAGMENT}
  ${FB_SERVICES_FRAGMENT}
  ${FB_SEGMENTS_FRAGMENT}
  ${PROFILE_PHYSICAL_CATEGORIES_FRAGMENT}
  ${OUTLET_CATEGORIES_FRAGMENT}
  ${POINT_FRAGMENT}
  ${OUTLET_SMART_SETTINGS_FRAGMENT}
  ${getImagesFragment()}
`;

export const FB_RESERVATION_FRAGMENT = gql`
  fragment fbReservationFragment on FbReservation {
    id
    uuid
    date
    reservationNumber
    reservationName
    reservationPhoneNumber
    reservationEmail
    reservationTime
    status
    nPax
    notes
    guestRoom
    bookedBy {
      id
      firstname
      lastname
      email
      phone
    }
    fbService {
      ...fbServicesFragment
      fbOutlet {
        id
        name
        phoneNumber
      }
    }
    fbGuestList {
      id
      uuid
      guestPhysicalCategoryId
      name
    }
    checkin {
      id
      uuid
      arrangement
    }
  }
  ${FB_SERVICES_FRAGMENT}
`;

export const FB_OUTLETS_FRAGMENT = gql`
  fragment fbOutletsFragment on FbOutletsDto {
    id
    uuid
    uuid
    name
    email
    notificationEmail
    deliveryPrice
    description
    address
    addressNumber
    city
    zipCode
    country
    phoneNumber
    maxPax
    isDeliveryEnabled
    isTakeAwayEnabled
    isSmartServiceEnabled
    maxDaysBeforeReservation
    minimumDeliveryOrderAmount
    isOnlineReservationAccepted
    minPaxForReservation
    maxPaxForReservation
    isReservationEditableByGuest
    fiscalPrinterIpAddress
    useFiscalPrinter
    avoidEditingOrDeleteBeforeMinutes
    fbRooms {
      ...fbRoomsFragment
    }
    fbServices {
      ...fbServicesFragment
    }
    guestPhysicalCategories {
      ...profilePhysicalCategoriesFragment
    }
    outletCategories {
      ...outletCategoriesFragment
    }
    deliveryPrice
    geolocation {
      ...pointFragment
    }
    smartSettings {
      ...outletSmartSettingsFragment
    }
  }
  ${FB_ROOMS_FRAGMENT}
  ${FB_SERVICES_FRAGMENT}
  ${PROFILE_PHYSICAL_CATEGORIES_FRAGMENT}
  ${POINT_FRAGMENT}
  ${OUTLET_SMART_SETTINGS_FRAGMENT}
  ${OUTLET_CATEGORIES_FRAGMENT}
`;

export const USER_FRAGMENT = gql`
  fragment userFragment on UserDto {
    id
    uuid
    createdAt
    updatedAt
    firstName
    lastName
    username
    email
    phoneNumber
    avatar
    hasTempPassword
  }
`;

export const COMPLAINT_FRAGMENT = gql`
  fragment complaintFragment on ComplaintDto {
    id
    uuid
    description
    createdAt
    updatedAt
    createdBy {
      id
      uuid
      firstName
      lastName
    }
    priority
    status
    notes
    actionTaken
    followUp
    picture
    feedbackOnClose
    cost
    complaintType {
      id
      uuid
      name
    }
    profile {
      id
      uuid
      firstName
      lastName
    }
    room {
      id
      uuid
      name
      number
    }
  }
`;

export const FB_MENU_ITEMS_FRAGMENT = gql`
  fragment fbMenuItemsFragment on FbMenuItemsDto {
    id
    uuid
    fbMenuCategoryId
    name
    secondaryName
    description
    secondaryDescription
    imageUrl
    price
    images {
      id
      uuid
      path
      isPrimary
    }
    fbAllergens {
      id
      uuid
      name
    }
  }
`;

export const FB_ORDERS_FRAGMENT = gql`
  fragment fbOrdersFragment on FbOrdersDto {
    id
    uuid
    createdAt
    externalOrderId
    deliveryTime
    time
    date
    type
    orderNumber
    clientName
    notes
    status
    guestRoom
    isSmartOrderServicePaid
    createdBy {
      ...userFragment
    }
    fbCourses {
      id
      fbCoursesMenuItems {
        id
        name
        quantity
        price
      }
    }
    orderSmartServices {
      id
      uuid
      name
      quantity
      price
    }
    fbOutlet {
      ...fbOutletsFragment
    }
  }
  ${USER_FRAGMENT}
  ${FB_OUTLETS_FRAGMENT}
`;

export const PROFILE_FRAGMENT = gql`
  fragment profileFragment on ProfileDto {
    id
    uuid
    fullName
    firstName
    lastName
  }
`;

export const IMAGE_FRAGMENT = gql`
  fragment imageFragment on ImageDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    name
    path
    isPrimary
  }
  ${USER_FRAGMENT}
`;

export const FB_MENU_CATEGORIES_FRAGMENT = gql`
  fragment fbMenuCategoriesFragment on FbMenuCategoriesDto {
    id
    uuid
    name
    order
    fbMenuMacrocategory {
      id
      uuid
      name
    }
    fbMenuItems {
      ...fbMenuItemsFragment
    }
  }
  ${FB_MENU_ITEMS_FRAGMENT}
`;

export const MOODS_FRAGMENT = gql`
  fragment moodsFragment on MoodsDto {
    id
    uuid
    name
    unicode
    description
  }
`;

export const CHECKIN_PREFERENCES_FRAGMENT = gql`
  fragment checkinPreferencesFragment on CheckinPreferencesDto {
    transportationInfo
    checkoutFeedback
    housewife
    reservedLocalAdviser
    checkinNotes
    requestedServices
    complaints
    reasonOfTrip
    localAdvisers
  }
`;

export const ROOM_FRAGMENT = gql`
  fragment roomFragment on RoomDto {
    id
    uuid
    name
    description
    number
  }
`;

export const CHECKIN_FRAGMENT = gql`
  fragment checkinFragment on CheckinDto {
    id
    uuid
    createdAt
    updatedAt
    checkinDate
    checkoutDate
    staffPhoneNumber
    arrangement
    status
    checkinPreferences {
      ...checkinPreferencesFragment
    }
    documentImages {
      ...imageFragment
    }
    room {
      ...roomFragment
    }
    profile {
      id
      uuid
      firstName
      lastName
      email
      phone
    }
  }
  ${IMAGE_FRAGMENT}
  ${ROOM_FRAGMENT}
  ${CHECKIN_PREFERENCES_FRAGMENT}
`;

export const GUEST_PROFILES_FRAGMENT = gql`
  fragment guestProfilesFragment on GuestAppProfileDto {
    id
    uuid
    firstName
    lastName
    email
    phone
    checkins {
      ...checkinFragment
    }
    currentCheckin {
      ...checkinFragment
    }
  }
  ${CHECKIN_FRAGMENT}
`;

export const ORDER_REVIEW_FRAGMENT = gql`
  fragment orderReviewFragment on OrderReviewDto {
    id
    uuid
    description
    rating
    createdBy {
      id
      uuid
      firstName
      lastName
    }
  }
`;

export const TICKET_FRAGMENT = gql`
  fragment ticketFragment on TicketDto {
    id
    uuid
    description
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    priority
    status
    notes
    picture
    ticketType {
      id
      uuid
      name
    }
    building {
      id
      uuid
      number
      name
    }
  }
  ${USER_FRAGMENT}
`;

export const ORDER_SMART_SERVICE_FRAGMENT = gql`
  fragment orderSmartServiceFragment on OrderSmartServiceDto {
    id
    uuid
    createdAt
    updatedAt
    name
    quantity
    price
    smartServiceId
    # smartService {}
  }
`;
