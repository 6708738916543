import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ReactComponent as CartIcon } from '../../../assets/images/header/cart.svg';
import { ServiceCartContext } from '../../../contexts/ServiceCartContext';
import { ROUTE_CART_INFO } from '../../../router/routes';

const Container = styled.div`
  cursor: pointer;
  position: relative;
`;

const Quantity = styled.div`
  border-radius: 50px;
  height: 16px;
  width: 16px;
  background-color: ${({ theme }) => theme.colors.errorColor};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -3px;
  top: -3px;
`;

const Cart = () => {
  const history = useHistory();
  const { serviceCart } = useContext(ServiceCartContext);
  return (
    <Container onClick={() => history.push(ROUTE_CART_INFO)}>
      <CartIcon />
      <Quantity>
        <span style={{ fontSize: 10, color: 'white', fontFamily: 'optima-bold' }}>{serviceCart?.services?.length}</span>
      </Quantity>
    </Container>
  );
};

export default Cart;
