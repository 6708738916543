import React from 'react';
import styled from 'styled-components/macro';
import Section from '../components/home/Section';
import SUIContainer from '../components/shared/Container';
import { ROUTE_COMPLAINTS, ROUTE_RESERVATIONS, ROUTE_TICKETS } from '../router/routes';
import { SECTION_TYPES } from '../constants/genericConstants';

const Container = styled(SUIContainer)`
  height: 100%;
  padding: 50px 0px;
  @media (max-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
`;

const Sections = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${({ theme: { mediaQueries } }) => mediaQueries.mobileBreakpoint}) {
    display: flex;
    flex-direction: column;
  }
`;

const HomeContainer = () => {
  return (
    <Container>
      <Sections>
        <Section type={SECTION_TYPES.RESERVATIONS} routePath={ROUTE_RESERVATIONS} />
        <Section type={SECTION_TYPES.COMPLAINTS} routePath={ROUTE_COMPLAINTS} />
        <Section type={SECTION_TYPES.TICKETS} routePath={ROUTE_TICKETS} />
      </Sections>
    </Container>
  );
};

export default HomeContainer;
