// eslint-disable no-unused-expressions
import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import Swiper from 'react-id-swiper';
import moment from 'moment';
import it from 'moment/locale/it';
import { TranslateTextComponent } from '../../translator';
import { LanguageContext } from '../../contexts/LanguageContext';
import Spinner from './Spinner';
import BoldLabel from './BoldLabel';

const Container = styled.div`
  & > div:last-child:not(:first-child) {
    margin-top: 5px;
  }
`;

const Month = styled(TranslateTextComponent).attrs({ capitalize: true })`
  font-family: optima-medium;
  font-size: 12px;
`;

const DateTimeItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 5px;
  width: 30px;

  & > span:first-child {
    font-size: 10px;
    margin-bottom: 3px;
  }

  & > span:last-child {
    font-family: optima-bold;
    font-size: 15px;
  }

  ${({ active }) => {
    if (active)
      return css`
        font-family: optima-bold;
        color: white;
        background-color: ${({ theme: { colors } }) => colors.primaryColor};
      `;
  }}

  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: default;
        opacity: 0.3;
      `;
    }
  }}
`;

const HorizontalDateTimePicker = ({ type, date, times, message, loading, spaceBetween, onChange }) => {
  const swiperRef = useRef(null);
  const { language } = useContext(LanguageContext);
  const [activeElement, setActiveElement] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [dates, setDates] = useState([]);

  // Set moment locale
  moment.updateLocale(language?.toLowerCase() === 'it' ? 'it' : 'en', [it]);

  const getDates = () => {
    const dates = [];
    for (let i = 0; i < 31; i++) {
      dates.push(moment().add(i, 'days'));
    }
    return dates;
  };

  useEffect(() => {
    const dates = getDates();
    if (type === 'date') {
      setActiveElement(date || dates[0]);
    }
    if (type === 'time' && date) {
      setActiveElement(date);
    }
    setDates(dates);
    setCurrentMonth(dates[0].format('MMMM'));
  }, [date, type]);

  useEffect(() => {
    if (activeElement) {
      if (type === 'date') onChange(moment(activeElement).format('YYYY-MM-DD'));
      // else onChange(activeElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeElement, type]);

  const getPicker = () => {
    if (message && !loading) return <TranslateTextComponent capitalize>{message}</TranslateTextComponent>;
    if (type === 'time') {
      return (
        <>
          <BoldLabel label="available-time-slots" fontSize={25} />
          {loading ? (
            <Spinner size={30} />
          ) : (
            <Swiper key={dates.length} ref={swiperRef} slidesPerView="auto" spaceBetween={spaceBetween}>
              {times?.map((slot, i) => (
                <div key={i} style={{ display: 'flex', width: 'auto' }}>
                  <DateTimeItem
                    active={activeElement?.time === slot?.time && activeElement?.fbServiceUUID === slot?.fbServiceUUID}
                    disabled={!slot.isAvailable}
                    onClick={() => {
                      if (slot.isAvailable) onChange(slot);
                    }}
                  >
                    <span>{slot.time}</span>
                  </DateTimeItem>
                </div>
              ))}
            </Swiper>
          )}
        </>
      );
    }
    return (
      <>
        <Month>{currentMonth}</Month>
        <Swiper
          key={dates.length}
          ref={swiperRef}
          slidesPerView="auto"
          spaceBetween={spaceBetween}
          on={{
            slideChange: () => {
              let activeIndex = swiperRef.current?.swiper?.activeIndex;
              if (activeIndex + 1 < dates.length) activeIndex++;
              setCurrentMonth(dates[activeIndex].format('MMMM'));
            }
          }}
        >
          {dates.map((date, i) => (
            <div key={i} style={{ display: 'flex', width: 'auto' }}>
              <DateTimeItem active={date.isSame(activeElement, 'date')} onClick={() => setActiveElement(date)}>
                <TranslateTextComponent capitalize>{moment(date).format('ddd')}</TranslateTextComponent>
                <span>{moment(date).date()}</span>
              </DateTimeItem>
            </div>
          ))}
        </Swiper>
      </>
    );
  };

  return <Container>{getPicker()}</Container>;
};

HorizontalDateTimePicker.propTypes = {
  type: PropTypes.oneOf(['date', 'time']),
  /** Useful if you use need available slots */
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  times: PropTypes.array,
  message: PropTypes.string,
  spaceBetween: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

HorizontalDateTimePicker.defaultProps = {
  type: 'date',
  date: null,
  times: [],
  message: '',
  spaceBetween: 30
};

export default HorizontalDateTimePicker;
